import React from 'react';
import { useSelector } from 'react-redux';

import { PAST } from 'service/constants';
import SectionHead from '../../../ui-components/SectionHead';
import TaskGroup from './components/task-group/TaskGroup'

import './group-task-list.scss'

const GroupTaskList = ({loading}) => {
    const tasks = useSelector(state => state.admin.tasks)
    
    return (
        <div className='group-task-list'>
            <SectionHead head={"Dashboard"}/>
            
            <div className='group-list'>
                <TaskGroup 
                    type={PAST} 
                    items={tasks}
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default GroupTaskList;