/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:35:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-22 12:25:26
 */

import * as Appointment from "./appointment"
import * as Auth from "./auth"
import * as Calendar from "./calendar"
import * as Client from "./client"
import * as ClientAppointment from "./clientAppointment"
import * as ClientSearch from "./clientSearch"
import * as CounsellorAppointment from "./counsellorAppointment"
import Login from "./login"
import * as Search from "./search"
import store from "./store"

export default {
  Appointment: Appointment.default,
  AppointmentShape: Appointment.Shape,
  Auth: Auth.default,
  AuthShape: Auth.Shape,
  Calendar: Calendar.default,
  CalendarShape: Calendar.Shape,
  Client: Client.default,
  ClientShape: Client.Shape,
  ClientSearch: ClientSearch.default,
  ClientAppointment: ClientAppointment.default,
  ClientAppointmentShape: ClientAppointment.Shape,
  CounsellorAppointment: CounsellorAppointment.default,
  CounsellorAppointmentShape: CounsellorAppointment.Shape,
  Login,
  Search: Search.default,
  store,
}