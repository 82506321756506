import React from 'react';
import {useDispatch} from "react-redux"
import { showRegistrationPopUp } from '../actions';
import {useState} from 'react'

import close from 'assets/img/close.svg'
import EmailMessage from './components/email-message/EmailMessage';
import RegisterForm from './components/register-form/RegisterForm';
import EmailForm from './components/email-form/EmailForm';
import {Tabs, Tab} from 'material-ui';

import './registration.scss'



const ChangePhonePopUp = () => {
    const dispatch = useDispatch()
    const closeHandler = () => dispatch(showRegistrationPopUp())
    const [currentTab, setTab] = useState(0)
    const [email, setEmail] = useState('')
    
    const nextTab = () => setTab(prev => prev + 1)
      
    return (
        <div className='registration'>
            <div className="registration-wrapper">
                <button 
                    className="icon-button close-button"
                    onClick={closeHandler}
                    type="button"
                >
                    <img className="close-button" src={close} alt="close-button"/>
                </button>
                <div className="tabs">
                    <Tabs value={currentTab}>
                        <Tab label="Step One" value={0} disabled/>
                        <Tab label="Step Two" value={1} disabled/>
                        <Tab label="Step Three" value={2} disabled/>
                    </Tabs>
                    {
                        currentTab === 0 && 
                            <EmailForm 
                                setTab={nextTab}
                                setEmail={setEmail}
                            />
                    }
                    {
                        currentTab === 1 && 
                            <EmailMessage
                                setTab={nextTab}
                            />
                    }
                    {
                        currentTab === 2 && 
                            <RegisterForm
                                setTab={nextTab}
                                closeHandler={closeHandler}
                                email={email}
                            />
                    }
                </div>
                
            </div>
        </div>
    );
};

export default ChangePhonePopUp;