import React, {useEffect, useState} from 'react';
import { fetchAdminCounsellors, fetchTaskList } from 'modules/admin/redux/actions';
import { useDispatch } from 'react-redux';

import FullWidthRow from "../layout/components/FullWidthRow"
import Container from "../layout/components/Container"
import {Paper} from "material-ui"
import GroupTaskList from "../admin/group-task-list/GroupTaskList"
import Counsellors from 'modules/admin/counsellors/Counsellors';
import BadFit from 'modules/admin/bad-fit/BadFit';
import AdminSpinner from 'ui-components/AdminSpinner';
import Loading from 'ui-components/Loading';

const AdminHomepage = () => {
    const dispatch = useDispatch()
    const [taskLoading, setTaskLoading] = useState(true)
    const [counsellorsLoading, setCounsellorsLoading] = useState(true)

    useEffect(async () => {
        await dispatch(fetchAdminCounsellors())
        setCounsellorsLoading(false)

        await dispatch(fetchTaskList())
        setTaskLoading(false)

    }, [dispatch])

    return (
       <FullWidthRow>
           <Container>
                <Paper elevation={4} className="admin-homepage">
                    <GroupTaskList
                        loading={taskLoading}
                    />
                    <BadFit/>
                    { !counsellorsLoading && <Counsellors/>}
                        
                    { counsellorsLoading && <AdminSpinner/> }
            </Paper>
           </Container>
       </FullWidthRow>
    );
};

export default AdminHomepage;