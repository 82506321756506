import axios from "axios";
import { INVOICE, RECEIPT } from "./constants";
import { $authHost } from "./indexApi";
import { UPDATE_CALENDAR, UPDATE_CLIENT_INFO, UPDATE_INVOICE_NUMBER, UPDATE_TOTAL_BILLED, UPDATE_EMAIL_TEXT, UPDTATE_COUNSELLOR_PHONE, UPDATE_BAD_FIT_ITEM, DELETE_TASK,  REQUEST_TO_ACTIVATE, DELETE_BAD_FIT, UPDATE_CLIENT_RATE } from "./mutations";
import { GET_INVOICES_NUMBERS, GET_INVOICE_NUMBER, GET_TOTAL_BILLED, GET_EMAIL_TEXT, GET_COUNSELLOR_INVOICE, GET_COUNSELLOR_OUTSTANDING, GET_TASK_LIST, GET_BAD_FIT, COUSNELLORS_WITH_ALERTS, COUNSELLOR_CLIENTS, GET_COUSNELLOR_WITH_CLIENTS, GET_CLIENT_RATES } from "./query";
import { getHomepageMinDate } from "utils/utils";

export const exportToPdf = async (dataForExport, type = INVOICE) => await $authHost.post(`/pdf/?pdf_type=${type}`, dataForExport, {
    responseType: 'blob'
})

export const exportToZip = async (dataForExport) => await $authHost.post('/pdf/zip', dataForExport, {
    responseType: 'blob'
})

export const getInvoiceNumber = async () => {
    const {data: {data: {invoiceNumber}}} = await $authHost.post('/graphql', {
        query: GET_INVOICE_NUMBER,
    })
    
    return invoiceNumber
}

export const getTotalBilled = async (acuityID) => {
    const {data: {data: {appointments: [clientAppointment]}}} = await $authHost.post('/graphql', {
        query: GET_TOTAL_BILLED,
        variables: {params: {acuityID}}
    })

    return clientAppointment.totalBilled
}
    

export const updateInvoiceNumber = async (number) => {
    const {data: {data: {invoiceNumber: {invoiceNumber}}}} = await $authHost.post('/graphql', {
        query: UPDATE_INVOICE_NUMBER,
        variables: {invoiceNumber: number}
    })
    
    return invoiceNumber
}

export const updateTotalBilled = async (number, id) => {
    const variables = {
        mutateParams: {
            id,
            totalBilled: number
        }
    }

    const {data: {data: {clientAdminsRole: {totalBilled}}}} = await $authHost.post('/graphql', {
        query: UPDATE_TOTAL_BILLED,
        variables
    })
    
    return totalBilled
}

export const getInvoicesNumbers = async (operationId) => {
    const {data: {data: {extractedInvoiceNumbers}}} = await $authHost.post('/graphql', {
        query: GET_INVOICES_NUMBERS,
        variables: {operationId}
    })
    
    return extractedInvoiceNumbers
}

export const updateCounsellorInfo = async (mutateParams = {}, requestParams = {}) => {
    const {data: { data: {counsellor: [counsellorItem]}}} = await $authHost.post('/graphql', {
        query: UPDATE_CALENDAR,
        variables: {
            mutateParams,
            requestParams
        }
    })
    
    return counsellorItem
}

export const updateClientInfo = async (mutateParams) => {
    const {data: {data: {client}}} = await $authHost.post('/graphql', {
        query: UPDATE_CLIENT_INFO,
        variables: {mutateParams}
    })
    
    return client
}

export const updateCounsellorPassword = async (newPassword, oldPassword) => {
    const formData = new FormData()
    formData.append("old_password", oldPassword)
    formData.append("new_password", newPassword)
    await $authHost.post('/auth/password/set', formData)
}

export const signIn = async (username, password) => {
    const formData = new FormData()
    formData.append("username", username)
    formData.append("password", password)
    
    const {data: {access_token: token}} = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, formData)

    return token
}

export const verifyToken = async () => {
    const {data} = await $authHost.get('/auth/authenticate')

    return data
}

export const emailPassword = async (email, isRegistered) => await $authHost.post(`/auth/password/generate?email=${email}&is_register=${isRegistered}`)

export const getEmailText  = async () => {
    const {data: { data: {emailText: {text}}}} = await $authHost.post('/graphql', {
        query: GET_EMAIL_TEXT
    })

    return text
}

export const updateEmaiText  = async (emailText) => {
    const {data: { data: {emailText: {text}}}} = await $authHost.post('/graphql', {
        query: UPDATE_EMAIL_TEXT,
        variables: {
            text: emailText
        }
    })

    return text
}

export const sendEmailInvoice = async ({email, text, title, file, fileName, invoice_app_ids }) => {
    const formData = new FormData()
    formData.append("email", email)
    formData.append("text", text)
    formData.append("title", title)
    formData.append("file", file)
    formData.append("filename", fileName)
    formData.append("invoice_app_ids", JSON.stringify(invoice_app_ids))

    await $authHost.post('/send_email/invoice', formData)
}

export const updateCounsellorPhone = async (phone) => {
    await $authHost.post('/graphql', {
        query: UPDTATE_COUNSELLOR_PHONE,
        variables: {
            mutateParams: {
                phone
            }
        }
    })
}

export const getBadFitItems = async () => {
    const {data: { data: {badFit}}} = await $authHost.post('/graphql', {
        query: GET_BAD_FIT
    })

    return badFit
}

export const getTaskList = async () => {
    const {data: { data: {tasks}}} = await $authHost.post('/graphql', {
        query: GET_TASK_LIST
    })

    return tasks
}

export const updateBadFitItem = async (badFitId, params) => {
    const {data: { data: {badFit}}} = await $authHost.post('/graphql', {
        query: UPDATE_BAD_FIT_ITEM,
        variables: {
            badFitId,
            params: {
                goodForMonth: null,
                ...params
            }
        }
    })

    return badFit
}

export const deleteBadFitItemRequest = async (badFitId) => {
    const {data: { data: {badFit}}} = await $authHost.post('/graphql', {
        query: DELETE_BAD_FIT,
        variables: {
            badFitId
        }
    })

    return badFit
}

export const deleteTask = async (taskId) => {
    await $authHost.post('/graphql', {
        query: DELETE_TASK,
        variables: {
            taskId
        }
    })
}

export const requestToActivate = async (mutationRequest, id) => {
    const {data: { data: {clientBaseRole}}} = await $authHost.post('/graphql', {
        query: REQUEST_TO_ACTIVATE,
        variables: {
            mutateParams :{ 
                id,
                mutationRequest
            }
        }
    })

    return clientBaseRole
}

export const getCounsellor = async (counsellorID) => {
    const minDate = getHomepageMinDate()

    const { data: { data: { counsellorAlerts } } } = await $authHost.post('/graphql', {
        query: COUSNELLORS_WITH_ALERTS,
        variables: {
            params: {
                minDate,
                counsellorID
            }
        }
    })
    
    return counsellorAlerts
}

export const getAdminPageCounsellors = async () => {
    const minDate = getHomepageMinDate()

    const { data: { data: { counsellorAlerts } } } = await $authHost.post('/graphql', {
        query: COUSNELLORS_WITH_ALERTS,
        variables: {
            params: {
                minDate
            }
        }
    })
    
    return counsellorAlerts
}

export const getCounsellorClients = async (counsellorID) => {
    const minDate = getHomepageMinDate()

    const { data: { data: { homePage: {clients} } } } = await $authHost.post('/graphql', {
        query: COUNSELLOR_CLIENTS,
        variables: {
            params: {
                minDate,
                counsellorID
            }
        }
    })
    
    return clients
}

export const sendEmailReceipt = async (data, email) => {
    await $authHost.post(`/send_email/receipt`, data ,{
        params : {email, pdf_type: RECEIPT}
    })
}

export const confirmRegistration = async (username, password, phone) => {
    const formData = new FormData()
    formData.append("username", username)
    formData.append("password", password)
    
    const {data: {access_token: token}} = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/confirm_registration`,formData, {
        params: {
            phone
        }
    })

    return token
}

export const getCounsellorInvoice = async (counsellorID, maxDate, minDate) => {
    const {data: {data: {counsellorAppointments}}} = await $authHost.post('/graphql', {
        query: GET_COUNSELLOR_INVOICE,
        variables: {
            params: {
                counsellorID,
                maxDate, 
                minDate,
                canceled: false
            }
        }
    })

    return counsellorAppointments
}

export const getCounsellorOutstanding = async (counsellorID) => {
    const {data: {data: {counsellorAppointments}}} = await $authHost.post('/graphql', {
        query: GET_COUNSELLOR_OUTSTANDING,
        variables: {
            params: {
                counsellorID,
                canceled: false
            }
        }
    })

    return counsellorAppointments
}

export const getCounsellorWithClients = async (counsellorID = null) => {
    const minDate = getHomepageMinDate()

    const { data: { data: { homePage: {clients}, counsellorAlerts } } } = await $authHost.post('/graphql', {
        query: GET_COUSNELLOR_WITH_CLIENTS,
        variables: {
            params: {
                minDate,
                counsellorID
            }
        }
    })
    
    return {clients, counsellorAlerts}
}

export const getPaymentSources = async () => {
    const {data: {data: {clientRates}}} = await $authHost.post('/graphql', {
        query: GET_CLIENT_RATES,
    })
    
    clientRates.sort((a, b) => a.paymentSource.orderNumber - b.paymentSource.orderNumber)
    return clientRates
}

export const updatePaymentSource = async (value, paymentSource, valueForCouples, profile, isActive, hasCouple, noChangeFlag) => {
    const {data: {data: {clientRate, paymentSource: source}}} = await $authHost.post('/graphql', {
        query: UPDATE_CLIENT_RATE,
        variables: {
            rateParams: {
                value,
                valueForCouples,
                paymentSource,
            },
            sourceParams: {
                name: paymentSource,
                profile,
                isActive,
                hasCouple,
                noChangeFlag
            }
        }
    })

    return {
        ...clientRate,
        paymentSource: source
    }
}