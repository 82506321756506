/*
 * @Author: Mike Cottingham 
 * @Date: 2018-05-14 20:12:00 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 13:22:50
 */

 
import React, { Component } from "react"
import PropTypes from "prop-types"

import { Button } from "material-ui"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/fontawesome-free-solid"

class DeleteButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      waiting: false 
    }
    this.onConfirm = this.onConfirm.bind(this)
    this.onQuery = this.onQuery.bind(this)
  }

  onConfirm(e) {
    const { onClick } = this.props
    e.preventDefault()
    this.setState({waiting: false}, onClick)
  }
  
  onQuery(e) {
    e.preventDefault()
    this.setState({waiting: true})
    
    setTimeout(() => {
      this.setState({waiting: false})
    }, 1000)
  }
  
  render() {
    const { color } = this.props
    return (
      this.state.waiting ? 
        <Button onClick={this.onConfirm} color="secondary" variant="raised"><FontAwesomeIcon icon={faTrash} />?</Button> :
        <Button onClick={this.onQuery} color={color} variant="raised"><FontAwesomeIcon icon={faTrash} /></Button>
    )
  }
}

DeleteButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func
}

export default DeleteButton