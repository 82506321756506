/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:17 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-22 12:23:57
 */

import core from "../core"
import messages from "../messages"
import model from "../../model"
import Service from "./service"

import * as actionTypes from "./actionTypes"
import * as errorTypes from "./errorTypes"

import _ from "lodash"

export const search = (search = model.Search(), authToken = undefined, cancelToken = undefined, service = new Service()) => (dispatch) => {
  const params = model.Search(search) //Scrub params
  return service.search(params, authToken, cancelToken)
    .then(
      (data) => {
        dispatch(onSearchSuccess(data))},
      (error) => {
        if(error && !service.isCancelled(error)) {
          dispatch(messages.actions.showMessage(core.utils.handleError(error, errorTypes.search)))
        }
      }
    )
}

export const reset = () => ({
  type: actionTypes.reset
})

export const select = (client = model.Client()) => ({
  type: actionTypes.select,
  client
})

export const setIcbcClaimNumber = (claimNumber) => ({
  type: actionTypes.setIcbcClaimNumber,
  claimNumber
})


export const onSearchSuccess = (clients = model.store()) => ({
  type: actionTypes.search,
  clients
})