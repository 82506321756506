/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:41:59 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-14 21:53:15
 */

import PropTypes from "prop-types"
import _ from "lodash"

export default ({
  acuityId = undefined,
  name = undefined,
  fee = undefined,
  share = undefined,
  external = undefined,
  appointments = undefined,
  GSTEnabled = undefined,
  email = undefined
} = {}) => {
  name = name || " "
  const firstLastName = _.take(name.split(" "), 2)
  const initials = `${_.get(firstLastName, 0, " ")[0]}${_.get(firstLastName, 1, " ")[0]}`

  return {
    acuityId,
    name,
    initials,
    fee,
    share,
    external,
    appointments,
    GSTEnabled,
    email
  }
}

export const Shape = PropTypes.shape({
  email: PropTypes.string,
  acuityId: PropTypes.number,
  initials: PropTypes.string,
  name: PropTypes.string,
  replyTo: PropTypes.string
})