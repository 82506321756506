/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 23:36:31 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 15:16:02
 */

import React, { Component } from "react"
import { Button } from "material-ui"
import ClientSearch from "./ClientSearch"
import PropTypes from "prop-types"
import core from "../../core"
import { MuiPickersUtilsProvider, KeyboardDatePicker  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'
import { MAX_DATE, MIN_DATE } from "service/constants"
import { connect } from "react-redux"

class ClientAppointmentSearch extends Component {
  constructor(props) {
    super(props)

    this.state = this.generateState()
    this.formRef = React.createRef()

    this.onChange = core.utils.onChange.bind(this)
    this.validate = core.utils.validate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onClientChange = this.onClientChange.bind(this)
    this.onMinDateChange = this.onMinDateChange.bind(this)
    this.onMaxDateChange = this.onMaxDateChange.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()
    this.setState((prevState, props) => {
      props.onSubmit(prevState)
      return prevState
    })
  }

  onClientChange(search, client) {
    if(client) {
      this.setState({
        firstName: client.firstName,
        lastName: client.lastName,
        search: `${client.firstName} ${client.lastName}`
      })
    } else {
      this.setState({search, firstName:search, lastName:""})
    }
  }

  onMinDateChange(date) {
    this.setState(state => {
      return {...state, minDate: date}
    })
  }

  onMaxDateChange(date) {
    this.setState(state => {
      return {...state, maxDate: date}
    })
  }

  generateState() {
    return {
      firstName: "",
      lastName: "",
      minDate: null,
      maxDate: null,
      search: ""
    }
  }

  render() {
    const {loading, isAdmin} = this.props
    return (
      <section>
        <form className={`flex appointment-search ${!isAdmin ? "counsellor-search" : ""}`} onSubmit={this.onSubmit} ref={this.formRef}>
          <ClientSearch className={`flex-37-5 client-input`} onChange={this.onClientChange} value={this.state.search} selected={this.state.lastName !== ""} />
          {
            isAdmin && 
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format="dd.MM.yyyy"
                onChange={this.onMinDateChange}
                value={this.state.minDate}
                className="flex-20 standard-margin search-input"
                maxDate={this.state.maxDate || MAX_DATE}
                placeholder="Minimum Date"
              />
              <KeyboardDatePicker
                format="dd.MM.yyyy"
                onChange={this.onMaxDateChange}
                value={this.state.maxDate}
                className="flex-20 standard-margin search-input"
                minDate={this.state.minDate || MIN_DATE}
                placeholder="Maximum Date"
              />
            </MuiPickersUtilsProvider>
          }
         
          <Button  
            onClick={this.onSubmit} 
            color="primary" 
            variant="raised" 
            type="submit" 
            style={{width: 220, margin: '0 20px'}}
            disabled={isAdmin && (!this.state.maxDate || !this.state.minDate || loading)}
          >
            Search
          </Button >&nbsp;
        </form>
      </section>
    )
  }
}

const stateToProps = (state) => ({
  isAdmin: state.auth.profile.isAdmin
})

ClientAppointmentSearch.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
}

export default connect(stateToProps, null)(ClientAppointmentSearch)