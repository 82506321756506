import appointment from "../modules/appointment"
import {reducer as authReducer} from "../modules/auth/reducer"
import calendar from "../modules/calendar"
import client from "../modules/client"
import invoice from "../modules/invoice"
import messages from "../modules/messages"
import {reducer as outstandingReducer} from "../modules/invoice/outstanding/reducer"
import { reducer as futureReducer } from "../modules/invoice/future-paid/reducer"
import { reducer as adminCounsellorReducer } from "modules/admin/redux/reducer"
import { reducer as counsellorReducer } from "modules/counsellor/redux/reducer"
import { combineReducers } from "redux"
import { logout } from "modules/auth/actionTypes"
import { expansionPanel } from "modules/appointment/reducer"

const appReducer = combineReducers({
    appointment: appointment.reducer,
    auth: authReducer,
    calendar: calendar.reducer,
    client: client.reducer,
    invoice: invoice.reducer,
    messages: messages.reducer,
    exportedInvoices: invoice.exportedInvoices,
    outstandingItems: outstandingReducer,
    futurePaidList: futureReducer,
    admin: adminCounsellorReducer,
    expansionPanel: expansionPanel,
    counsellor: counsellorReducer
});
  
const rootReducer = (state, action) => {
    if (action.type === logout) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;