/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 19:15:52 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 13:36:43
 */

import "./appointment.css"

import * as actions from "./actions"
import * as selectors from "./selectors"

import { reducer } from "./reducer"

import Service from "./service"
import components from "./components"

export default {
  actions,
  components,
  reducer,
  selectors,
  Service
}