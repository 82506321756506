/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-24 20:30:23
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-09 21:41:11
 */

import model from "../../model"
import {GET_CLIENTS} from '../../service/query'
import { $authHost } from "service/indexApi";

export default class Service {
  search(search = model.Client()) {
    return $authHost.post('/graphql', {
      query: GET_CLIENTS,
      variables: search
    }).then(({data: {data: {clients}}}) => clients)
  }
}
