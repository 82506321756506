/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:48 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-22 21:26:08
 */

import { moduleName } from "./constants"

export const search = `${moduleName}.search`
export const toggleSelection = `${moduleName}.toggleSelection`
export const deleteAppointment = `${moduleName}.deleteAppointment`
export const updateAppointmentPrice = `${moduleName}.updateAppointmentPrice`
export const updateAppointmentRate = `${moduleName}.updateAppointmentRate`
export const updateCounsellorRate = `${moduleName}.updateCounsellorRate`
export const addAppointment = `${moduleName}.addAppointment`
export const changeSource = `${moduleName}.changeSource`

export const expand = `${moduleName}.expand`
export const collapse = `${moduleName}.collapse`
