import React from 'react';
import { showSection } from 'utils/utils';
import DirectClients from './direct-cients/DirectClients';
import NonDirectClients from './non-direct-clients/NonDirectClients';


const CounsellorExpanded = ({clients, counsellorId}) => {
    const {direct, nonDirect} = clients

    return (
        <>
            {
                showSection(direct)  
                    ? <DirectClients 
                        clients={direct}
                        counsellorId={counsellorId}    
                       />
                    : null
            }
            
            {
                showSection(nonDirect)  
                    ? <NonDirectClients 
                        clients={nonDirect}
                        counsellorId={counsellorId} 
                      />
                    : null
            }
           
        </>
    )
};

export default CounsellorExpanded;
