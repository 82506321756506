import React from 'react';
import {useState} from 'react'
import {useDispatch} from "react-redux"
import { showForgorpasswordPopUp } from '../actions';
import { emailPassword } from 'service/api';
import { showMessage } from 'modules/messages/actions';
import { useForm } from "react-hook-form";

import { failedMessage, invalidEmailMessage, passwordSendMessage} from 'modules/messages/messages';
import { emailRegex } from 'service/constants';
import close from 'assets/img/close.svg'
import {Button} from 'material-ui'
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import FormInput from 'ui-components/FormInput';

import './forgot-password.scss'


const ChangePhonePopUp = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        watch
    } = useForm({
        mode: "onSubmit",
        reValidateMode: 'onChange'
    });
    
    const closeHandler = () => dispatch(showForgorpasswordPopUp())
    
    const onSubmit = async ({email}) => {
        try {
            setLoading(true)
            await emailPassword(email, false)
            dispatch(showMessage(passwordSendMessage))
            reset()
            setTimeout(() => closeHandler(), 100) 
        } catch(e) {
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
    }

    const email = watch("email")
    const isBtnDisabled = !email || loading || errors.email
    return (
        <div className='forgot-password'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <button 
                    className="icon-button close-button"
                    onClick={closeHandler}
                    type="button"
                >
                    <img className="close-button" src={close} alt="close-button"/>
                </button>
                <div className="form-wrapper">
                    <h2 className="form-head">Forgot Password</h2>
                    <FormInput
                        title={"Email"}
                        name={"email"}
                        register={register}
                        errors={errors}
                        invalidMessage={invalidEmailMessage}
                        pattern={emailRegex}
                    /> 
                    <Button
                        color="secondary" 
                        variant="raised" 
                        className="submit-button"
                        type="submit"
                        disabled={isBtnDisabled}
                    >
                        { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
                    </Button>
                </div>
                
            </form>
        </div>
    );
};

export default ChangePhonePopUp;