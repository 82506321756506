/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 16:14:45 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 15:18:16
 */
import { Paper, TextField } from "material-ui"
import PropTypes from "prop-types"
import React  from "react"

import model from "../../../model"
import calendar from "../../calendar"
import { Button } from "material-ui"
import { useState } from "react"
import { updateCounsellorInfo } from "../../../service/api"
import { bindActionCreators } from "redux"
import * as actions from "../../messages/actions"
import {onSearchSuccess} from "../actions"
import { connect } from "react-redux"
import { Switch } from "material-ui"
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { failedMessage } from "modules/messages/messages"
import { MAX_PERCENT, MIN_PERCENT } from "service/constants"

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    updateCounsellors: onSearchSuccess
  }, dispatch)
)

const mapStateToProps = (state) => ({
  calendars: calendar.selectors.getCalendars(state)
})

const ListItem = ({calendar, onClick, showMessage, calendars, maxDate, minDate, updateCounsellors}) => {
  const {share: calShare, fee: calFee, external: calExternal, GSTEnabled: calGST, acuityID} = calendar
  const [share, setShare] = useState(calShare)
  const [fee, setFee] = useState(calFee)
  const [external, setExternal] = useState(calExternal)
  const [GSTEnabled, setGST] = useState(calGST)
  const [loading, setLoading] = useState(false)

  const saveInfo = async (e, GSTEnabled = null, share = null, fee = null, external = null) => {
    e.stopPropagation()

    const updatedInfo = {
      share,
      fee,
      external,
      GSTEnabled
    }
    
    const requestParams = {
      maxDate,
      minDate,
      counsellorID: acuityID,
      canceled: false
    }
  
    try { 
      setLoading(true)
      const updatedCounsellor = await updateCounsellorInfo(updatedInfo, requestParams)
  
      const newCalendars = [...calendars]
      const calendarIndex = calendars.findIndex(cal => cal.acuityID === acuityID)
      
      newCalendars.splice(calendarIndex, 1, {...updatedCounsellor})
      
      updateCounsellors([...newCalendars])
    } catch(e) {
      showMessage(failedMessage)
    } finally {
      setLoading(false)
    }
  }

  const onChange = (value, setState) => {
    if(!isNaN(value) && value <= MAX_PERCENT && value >= MIN_PERCENT ) {
      setState(value)
    }
  }
  
  const onExternalChange = (e) => {
    const value = e.target.value
    
    if(!isNaN(value) && value >= MIN_PERCENT) {
      setExternal(value)
    }
  }

  const elemetClick = e => e.stopPropagation()
  
  const buttonHandler = (e) => saveInfo(e, GSTEnabled, +share, +fee, +external)
  const toggleHandler = (e) => setGST(e.target.checked)

  const disabled = loading 
    || (+share === calShare && +fee === calFee && +external === calExternal && calGST === GSTEnabled) 
    || share === "" 
    || fee === "" 
    || external === ""

  return (
    <Paper elevation={4} className="small-margin stretch counsellor">
      <article className="calendar__list-item">
        <div 
          className="calendar__list-item" 
          style={{flex: 1}}
        >
          <h3 
            onClick={onClick} 
            className="counsellor-name"  
          >
            {calendar.name}
          </h3>
        </div>
        <div className="calendar__list-item" style={{flex: 0.5}}>
          <span>Share %</span>
          <TextField 
            onClick={elemetClick}  
            className="counsellor-input" 
            value={share}
            onChange={(e) => onChange(e.target.value, setShare)}
          />
        </div>
        <div className="calendar__list-item" style={{flex: 0.5}}>
          <span>External $</span>
          <TextField 
            onClick={elemetClick} 
            className="counsellor-input" 
            value={external}
            onChange={onExternalChange}
          />
        </div>
        <div className="calendar__list-item" style={{flex: 0.8}}>
          <span>Service fee %</span>
          <TextField 
            onClick={elemetClick} 
            className="counsellor-input" 
            value={fee}
            onChange={(e) => onChange(e.target.value, setFee)}
          />
        </div>
        <div className="calendar__list-item" style={{flex: 0.5}}>
          <span>GST</span>
          <Switch 
            onClick={elemetClick}
            color="primary"
            onChange={toggleHandler}
            checked={GSTEnabled}
            disabled={loading}
          />
        </div>
        <Button 
          disabled={disabled}
          color="secondary" 
          variant="raised" 
          className="counsellor-button"
          onClick={buttonHandler}
        >
          {
            loading 
              ? <FontAwesomeIcon icon={faSpinner} spin />
              : "Save"
          }
        </Button>
      </article>
    </Paper>
    )
  }

ListItem.propTypes = {
  calendar: model.CalendarShape,
  onChange: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ListItem)