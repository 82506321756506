import React from 'react';

import {differentPasswordsMessage, requiredMessage } from 'modules/messages/messages';
import InvalidMessage from './InvalidMessage';
import { CSSTransition } from 'react-transition-group';

import './ui-components.scss'

const FormInput = ({title, name, errors, register, pattern, invalidMessage, isPassword = false, password = null, isPhone, label = null}) => {
    const error = errors[name]?.type
    
    const getInvalidMessage = (error) => {
        if (error === "pattern" ) {
            return invalidMessage
        } else if (error === "required") {
            return requiredMessage
        }   else if (error === "validate") {
            return differentPasswordsMessage
        }
    }

    const onPhoneChange = (e) => {
        const value = e.target.value

        const isNumber = !isNaN(+value.slice(1, value.length))
        const isNotSecondElOne = value.length > 1 && value[1] !== "1"
        const isFirstElPlus = value[0] === "+"

        if (!isFirstElPlus || isNotSecondElOne || !isNumber) {
            e.target.value = value.slice(0, value.length - 1)
        }
    }

    const message = getInvalidMessage(error)

    const isSmallMessage = !isPassword || message === requiredMessage || message === differentPasswordsMessage
    return (
        <div className="form-input-wrapper">
            <span className='input-label'>{title}</span>
            <input 
                {
                    ...register(name, {
                        pattern,
                        required: true,
                        validate: {
                            validate: value => password && value === password
                        },
                        onChange: isPhone ? onPhoneChange : null
                    })
                }
                className={`${message ? "invalid" : ""}`}
                type={isPassword ? "password" : "text"}  
                maxLength={isPhone ? 12 : null}
            />
            {
                label && 
                    <span className="description">
                        {label}
                    </span>
            }
            
            <CSSTransition
                in={!!message}
                timeout={700}
                classNames="alert"
                unmountOnExit
            >
                <InvalidMessage
                    isSmallMessage={isSmallMessage}    
                    message={message}
                />
            </CSSTransition>
                
                    
            
        </div>
    );
};

export default FormInput;