import React from 'react';
import FuturePaidList from './FuturePaidList';
import OutstandingItems from './OutstandingItems';

const FooterSection = ({outstandingItems, futurePaidItems, addFutureMiscItem = null, homepage = null}) => {
    return (
        <div>
            { 
                outstandingItems.length ? 
                    <OutstandingItems
                        outstandingItems={outstandingItems}
                        homepage={homepage}
                    />  
                    : null
            }
            { 
                futurePaidItems.length ?
                    <FuturePaidList
                        homepage={homepage}
                        futurePaidItems={futurePaidItems}
                        addFutureMiscItem={addFutureMiscItem}
                    /> 
                    : null
            }
        </div>
    );
};

export default FooterSection;