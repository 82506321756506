/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:17 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-05-17 13:35:47
 */

// import core from "../core"
// import messages from "../messages"
// import model from "../../model"
// import Service from "./service"

import * as actionTypes from "./actionTypes"
// import * as errorTypes from "./errorTypes"

export const generateInvoice = (invoice) => ({
  type: actionTypes.generateInvoice,
  invoice
})

export const updateAppointmentPrice = (id, price) => ({
  type: actionTypes.updateAppointmentPrice,
  data: {id, price}
})

export const deleteAppointment = (id) => ({
  type: actionTypes.deleteAppointment,
  id
})


export const exportInvoices = (exportedInvoices) =>{
  return  ({
  type: actionTypes.exportInvoices,
  data: [...exportedInvoices]
})}

export const dropItemsfromExported = (invoices) => ({
  type: actionTypes.dropItemsfromExported,
  data: invoices
})

export const setInvoiceNumber =  () => ({
  type: actionTypes.setInvoiceNumber
})

export const changeClaimNumber =  (claimNumber) => ({
  type: actionTypes.changeClaimNumber,
  claimNumber
})