/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-07-27 14:31:49
 */

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import core from "../../core"
import * as actions from "../actions"
import moment from "moment"
import { exportToPdf, getInvoiceNumber, getTotalBilled, updateInvoiceNumber, updateTotalBilled } from "../../../service/api"

import { faEdit, faFileImage } from "@fortawesome/fontawesome-free-solid"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { Button, Paper, TextField } from "material-ui"
import PropTypes from "prop-types"
import Logo from "../../../assets/img/logo.png"
import model from "../../../model"
import auth from "../../auth"
import client from "../../client"
import layout from "../../layout"
import messages from "../../messages"
import LineItem from "./LineItem"
import Meta from "./Meta"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { failedMessage } from "modules/messages/messages"
import { ICBC, INVOICE, RECEIPT } from "service/constants"
import { generateAppDate, getBillTo, getClientName, getIdByDate, getInitials } from "utils/utils"
import ReceiptEmailPopUp from "../receipt-email-pop-up/ReceiptEmailPopUp"
import { CSSTransition } from "react-transition-group"
import { icbcAccountNumber } from "modules/core/constants"

class ClientPreview extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      billTo: getBillTo(props.invoice?.client?.firstName, props.invoice?.client?.lastName, props.invoice?.client?.paymentSource),
      invoiceNumber: 0,
      newInvoiceNumber: 0,
      totalBilled: 0,
      newTotalBilled: 0,
      invoiceDate: generateAppDate(new Date()),
      therapistName: props.invoice.counsellors.join(', '),
      asReceipt: !props.isAdmin,
      loading: false,
      invoiceNumberLoad: false,
      totalBilledLoad: false,
      clientName: getClientName(props.invoice?.client?.firstName, props.invoice?.client?.lastName, props.invoice?.client?.paymentSource),
      clientBillTo: props.invoice?.client?.fullName,
      receiptClientName: props.invoice?.client?.fullName,
      showPopUp: false
    }

    this.invoiceRef = React.createRef()
    this.onEditLocal = this.onEditLocal.bind(this)
    this.exportClick = this.exportClick.bind(this)
    this.onEditClaimNumber = this.onEditClaimNumber.bind(this)
    this.toggleToReceipt = this.toggleToReceipt.bind(this)
    this.updateInvoiceNumber = this.updateInvoiceNumber.bind(this)
    this.updateTotalBilled = this.updateTotalBilled.bind(this)
    this.popUpHandler = this.popUpHandler.bind(this)
  }

  async componentDidMount() {
    const { invoice: { appointments } } = this.props;

    try {
      this.setState({loading: true})

      const clientAppointmentId = appointments[0].acuityID
      const totalBilled = await getTotalBilled(clientAppointmentId)
      const invoiceNumber = await getInvoiceNumber()
      
      this.setState({totalBilled})
      this.setState({newTotalBilled: totalBilled})
      this.setState({invoiceNumber})
      this.setState({newInvoiceNumber: invoiceNumber})
    } catch(e) {
      console.log(e)
    } finally {
      this.setState({loading: false})
    }
    
  }

  popUpHandler() {
    this.setState({showPopUp: !this.state.showPopUp})
  }

  onEditLocal({ target: { name, value } }) {
    this.setState({ [name]: value }, () => {
      if (name === 'invoiceNumber') {
        localStorage.setItem("invoiceNumber", +value)
      }
    })
  }

  onAppointmentChange(appointment, { target: { name, value } }) {
    const { updateAppointmentPrice } = this.props
    updateAppointmentPrice(appointment.id, +value)
  }

  toggleToReceipt() {
    this.setState({ asReceipt: !this.state.asReceipt})
  }

  onEditClaimNumber({ target: { value } }) {
    const { changeClaimNumber } = this.props
    changeClaimNumber(value)
  }

  downloadFile(file, fileName) {
    const url = window.URL.createObjectURL(new Blob([file.data]));
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
  }

  async updateInvoiceNumber() {
    try {
      this.setState({invoiceNumberLoad: true})

      const invoiceNumber = await updateInvoiceNumber(+this.state.newInvoiceNumber)
      this.setState({invoiceNumber})
      this.setState({newInvoiceNumber: invoiceNumber})
    } catch(e) {
      console.log(e)
    } finally {
      this.setState({invoiceNumberLoad: false})
    }
  }

  async updateTotalBilled() {
    const { invoice: { client: {clientId}}} = this.props;

    try {
      this.setState({totalBilledLoad: true})

      const totalBilled = await updateTotalBilled(+this.state.newTotalBilled, clientId)
      this.setState({newTotalBilled: totalBilled, totalBilled})
    } catch(e) {
      console.log(e)
    } finally {
      this.setState({totalBilledLoad: false})
    }
  }

  async exportClick(e) {
    const { invoice: { client: appointmentClient, appointments, paymentSource}, exportInvoices, showMessage } = this.props;
    const fileName = !this.state.asReceipt 
      ? appointmentClient.claimNumber 
        ? `${this.state.invoiceNumber}-${appointmentClient.claimNumber}-${this.state.clientName}.pdf`
        : `${this.state.invoiceNumber}-${this.state.clientName}.pdf`
      : `Receipt ${getInitials(appointmentClient.firstName, appointmentClient.lastName, true)} ${generateAppDate(new Date(), true)}.pdf`

    const exportAppointments = []
    let totalTax = 0
    let total = 0
    let totalRate = 0
    let initialreportId = undefined

    appointments.sort((a, b) => getIdByDate(b.date) - getIdByDate(a.date)).forEach((element, i) => {
      const {paymentSource, type, GST, date, clientRate} = element

      if (paymentSource.name === "ICBC" && type.toLowerCase().includes('initial report')) {
        initialreportId = i
      } else if (!(i === (initialreportId + 1))) {
        const price = Number(clientRate) || 0
        const tax = GST
        const linePrice = price + tax
        
        const appointmentsForExport = {
            description: type,
            date: moment(date).format("MMM DD, YYYY"),
            rate: price,
            GST: +tax.toFixed(2),
            total: +linePrice.toFixed(2)
        }
        exportAppointments.push(appointmentsForExport)
        
        total += linePrice
        totalTax += tax
        totalRate += clientRate
      }
    })
    
    const pdfType = this.state.asReceipt ? RECEIPT : INVOICE
    
    const dataForExport = [
      {
        id: +appointments[0].id,
        bill_to: this.state.asReceipt ? this.state.clientBillTo : this.state.billTo,
        therapist_name: this.state.therapistName,
        account_number: paymentSource.name.toLowerCase() === ICBC ? icbcAccountNumber : "",
        date: this.state.invoiceDate,
        claim_number: appointmentClient.claimNumber || "",
        name_in_print: this.state.asReceipt ? this.state.receiptClientName : this.state.clientName,
        first_name: appointmentClient.firstName,
        last_name: appointmentClient.lastName,
        appointments: exportAppointments.sort((a, b) => getIdByDate(a.date) - getIdByDate(b.date)),
        result_rates: +totalRate.toFixed(2),
        result_GST: +totalTax.toFixed(2),
        total_result: +total.toFixed(2),
        type: pdfType
      }
    ]

    const exportedInvoice = [{
      ...this.state,
      invoice: {
        ...this.props.invoice, 
        icbcClaimNumber: appointmentClient.claimNumber, 
        numberOfAppointments: exportAppointments.length, 
        total
      }
    }]

    try {
      this.setState({loading: true})
      const response = await exportToPdf(dataForExport, pdfType)
      const {headers: {current_invoice_number, totalbilled}} = response

      this.setState({invoiceNumber: current_invoice_number, totalBilled: totalbilled, newTotalBilled: totalbilled, newInvoiceNumber: current_invoice_number})
      this.downloadFile(response, fileName)
      exportInvoices(exportedInvoice)
    } catch(e) {
      showMessage(failedMessage)
    } finally {
      this.setState({loading: false})
    }
  }

  render() {
    const { invoice: { appointments, client: appointmentClient, total, totalRate, totalTax, paymentSource }, deleteAppointment, isAdmin } = this.props
    const appointment = appointments[0]
    const lineItems = appointments.map((appointment, i) => <LineItem
      appointment={appointment}
      key={`appointment-${appointment.id}`}
      onChange={this.onAppointmentChange.bind(this, appointment)}
      deleteAppointment={deleteAppointment}
    />)
    
    return (
      <layout.components.FullWidthRow>
        <layout.components.Container>
          <div className="invoice__toolbar">
            {
              isAdmin &&
              <>
                <Button 
                  onClick={this.toggleToReceipt} 
                  color="secondary" 
                  variant="raised" 
                  style={{width: 140}}
                >
                  {this.state.asReceipt ? 'As Invoice' : 'As Receipt'}
                </Button>
                <Button 
                    onClick={this.exportClick} 
                    color="secondary" 
                    variant="raised" 
                    style={{width: 140}}
                    disabled={this.state.loading || !appointments.length}
                >
                  <FontAwesomeIcon icon={faFileImage} />&nbsp; Export
                </Button>
              </>
            }
            {
              !isAdmin || this.state.asReceipt   
                ? <Button 
                    onClick={this.popUpHandler} 
                    color="secondary" 
                    variant="raised" 
                    style={{width: 140}}
                    disabled={!appointments.length}
                  >
                    Email
                  </Button>
                : null
            }
            
          </div>
          <div ref={this.invoiceRef}>
            <Paper elevation={4} className="invoice__container" >
              <div>
                <div className="invoice__header">
                  <img src={Logo} alt={core.constants.imgAlt} style={{ maxWidth: '200px' }} />
                  <h1>{this.state.asReceipt ? 'Receipt' : 'Invoice'}</h1>
                </div>
                <section className="invoice__meta-container">
                  <Meta 
                    title={"Bill To"} 
                    data={this.state.asReceipt ? this.state.clientBillTo : this.state.billTo} 
                    editable 
                    name={this.state.asReceipt ? "clientBillTo" : "billTo"} 
                    placeholder="Bill To" 
                    onChange={this.onEditLocal} 
                  />
                  
                  {
                    !this.state.asReceipt 
                      &&  <Meta 
                            title={"Account Number"} 
                            data={paymentSource?.name.toLowerCase().includes(ICBC) ? core.constants.icbcAccountNumber : ""} 
                          />
                  }
                  <Meta title={"GST Number"} data={"738726926 RT0001"} />
                  <Meta title={"Supplier Name"} data={"No Fear Counselling Corp"} />
                  <Meta title={"Supplier Address"} data={"211-7885 6th St, Burnaby, BC, V3N 3N4"} />
                  <Meta title={"Phone # / Fax #"} data={"778-288-8361 604-357-5182"} />
                </section>
                <section className="invoice__meta-container">
                  <Meta 
                    title={"Therapist Name"} 
                    data={this.state.therapistName} 
                    editable 
                    name="therapistName" 
                    placeholder="Therapist Name" 
                    onChange={this.onEditLocal} 
                  />
                  <Meta 
                    title={this.state.asReceipt ? "Receipt Date" : "Invoice Date"} 
                    data={this.state.invoiceDate} 
                    editable 
                    name="invoiceDate" 
                    placeholder="Invoice Date" 
                    onChange={this.onEditLocal} 
                  />
                  {!this.state.asReceipt && 
                    <Meta title={"Invoice Number"} 
                      data={(this.state.loading || this.state.invoiceNumberLoad) ? <FontAwesomeIcon icon={faSpinner} spin /> : this.state.invoiceNumber}
                      dataForEdit={this.state.newInvoiceNumber} 
                      editable 
                      name="newInvoiceNumber" 
                      placeholder="Invoice Number" 
                      onChange={this.onEditLocal} 
                      onClick={this.updateInvoiceNumber}
                      loading={this.state.invoiceNumberLoad}
                    />
                  }
                  {!this.state.asReceipt && 
                    <Meta 
                      title={"Total Billed"} 
                      data={this.state.loading ? <FontAwesomeIcon icon={faSpinner} spin /> : this.state.totalBilled} 
                      dataForEdit={this.state.newTotalBilled} 
                      editable 
                      name="newTotalBilled" 
                      placeholder="Total billed" 
                      onChange={this.onEditLocal} 
                      onClick={this.updateTotalBilled}
                      loading={this.state.totalBilledLoad}
                    />
                  }
                  <Meta 
                    title={"Client Name"}
                    data={this.state.asReceipt ? this.state.receiptClientName : this.state.clientName} 
                    editable
                    placeholder="Client Name"
                    name={this.state.asReceipt ? "receiptClientName" : "clientName"}
                    onChange={this.onEditLocal}
                  />
                  {
                    !this.state.asReceipt &&
                    <article className={`invoice-meta ${paymentSource.name === "ICBC" && !appointmentClient.claimNumber && 'empty'}`}>
                      <b>Claim Number</b><br />
                      <span>{appointmentClient.claimNumber}</span>
                      <div className="hover__menu">
                        <b><FontAwesomeIcon icon={faEdit} /> Claim Number</b>
                        <ul>
                          <li>
                            <TextField 
                              type="text" 
                              placeholder="Claim Number" 
                              name="icbcClaimNumber" 
                              value={appointmentClient.claimNumber} 
                              onChange={this.onEditClaimNumber} 
                            />
                          </li>
                        </ul>
                      </div>
                    </article>
                  }
                </section>
                <section className="invoice__line-items">
                  <section>
                    <article className="invoice-line-item header">
                      <span className="flex-50 client-description">Description</span>
                      <span className="flex-12-5">Date</span>
                      <span className="flex-12-5 text-right">Rate</span>
                      <span className="flex-12-5 text-right">GST</span>
                      <span className="flex-12-5 text-right">Total</span>
                    </article>
                  </section>
                  {lineItems}
                  <section>
                    <article className={'invoice-line-item footer'}>
                      <span className="flex-50 client-description"></span>
                      <span className="flex-12-5">{lineItems.length} Sessions</span>
                      <span className="flex-12-5 text-right">${totalRate.toFixed(2)}</span>
                      <span className="flex-12-5 text-right">${totalTax.toFixed(2)}</span>
                      <span className="flex-12-5 text-right">${total.toFixed(2)}</span>
                    </article>
                  </section>
                </section>
                {
                  <CSSTransition
                    in={this.state.showPopUp}
                    timeout={500}
                    classNames="alert"
                    unmountOnExit
                  >
                    <ReceiptEmailPopUp
                      popUpHandler={this.popUpHandler}
                      client={appointment}
                      appointments={appointments}
                      therapists={this.state.therapistName}
                      nameForPrint={this.state.receiptClientName}
                      billTo={this.state.clientBillTo}
                      date={this.state.invoiceDate}
                    />
                  </CSSTransition>      
                }
              </div>
            </Paper>
          </div>
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

ClientPreview.propTypes = {
  invoice: model.ClientAppointmentShape,
  client: PropTypes.oneOfType([model.ClientShape, PropTypes.arrayOf(model.ClientShape)]),
  updateAppointmentPrice: PropTypes.func
}

const stateToProps = (state) => ({
  client: client.selectors.getClients(state),
  authToken: auth.selectors.getAuthToken(state),
  auth: auth.selectors.getAuth(state),
  isAdmin: state.auth.profile.isAdmin
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    ...client.actions,
    ...messages.actions
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(ClientPreview)