import React from 'react';
import SectionHead from 'ui-components/SectionHead';
import CounsellorList from './counsellor-list/CounsellorList';

import "./counsellors.scss"
import Legend from './legend/Legend';

const Counsellors = () => {
    return (
        <div className='counsellors'>
            <div className='counsellor-header'>
                <SectionHead head={"Counsellors"}/>
                <Legend/>
            </div>
            <CounsellorList/>
        </div>
    );
};

export default Counsellors;