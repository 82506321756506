/*
 * @Author: Mike Cottingham 
 * @Date: 2018-05-18 11:10:21 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-18 13:23:08
 */

import React from "react"
import succeesImg from '../../../assets/img/success.svg'
import circle from '../../../assets/img/circle.svg'
import polig from '../../../assets/img/polig.svg'

const InvoiceGroup = ({heading, containerClass, styles, children}) => {
  return (
    <section style={styles}>
      <div className="misc-header">
        <h1 className="text-left">{heading}</h1>
        {
          heading === "Misc/Resolved" && 
          <div className="mics-legend" >
            <h1>
              Legend: 
              <img src={succeesImg} alt="" width={12} />
              Resolved, outstanding item
            </h1>
          </div>
        }
        {
          heading === "" && 
          <div className="future-paid-legend" style={{margin: "10px 1%"}}>
            <h1 style={{display: 'flex'}}>
              Legend: 
              <img src={circle} alt="" width={14} />
              No approval
              <img src={polig} alt="" width={14} />
              No payment
            </h1>
          </div>
        }
      </div>
      
      <article className={containerClass} >
        {children}
      </article>
    </section>
  )
} 

export default InvoiceGroup