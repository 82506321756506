import React from 'react';
import Spinner from './Spinner';

const Loading = () => {
    return (
        <div className="loading">
            <Spinner/>
        </div>
    );
};

export default Loading;