/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 19:22:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-30 20:54:36
 */


export const moduleName = "appointment"

export const pages = {
}

export const appointmentPluralize = {
  none: "No Appts",
  one: "Appt",
  many: "Appts"
}

export const endpoints = {
  appointments: "/appointments"
}