/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:37:40 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-24 00:00:28
 */

import React from 'react'
import components from './components'

const Services = (props) => {
  return (
    <components.FullWidthRow>
      <components.Container>
        <div className='layout__services'>
          {props.children} 
        </div>
      </components.Container>
    </components.FullWidthRow>
  )
}
export default Services