/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-10-05 14:47:09
 */


import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"

import core from "../../core"
import layout from "../../layout"
import model from "../../../model"

import ListItem from "./ListItem"
import { moduleName } from "../constants"

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  onClick(client, e) {
    const { onClick } = this.props
    onClick(client)
  }

  render() {
    const { clients } = this.props
    const listItems = _.map(clients, (client, i) => (
      <ListItem 
        client={client} 
        key={`${moduleName}-${client.firstName}-${client.lastName}`} 
        onClick={this.onClick.bind(this, client)} 
      />
    ))

    return(
      <layout.components.FullWidthRow>
        <layout.components.Container>
          {listItems.length ? listItems : <core.components.NoItems />}
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

List.propTypes = {
  clients: PropTypes.arrayOf(model.ClientShape),
  onClick: PropTypes.func
}

const stateToProps = (state) => ({
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(List)