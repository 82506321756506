import FooterSection from 'modules/invoice/components/FooterSection';
import InvoiceGroup from 'modules/invoice/components/InvoiceGroup';
import React from 'react';
import { useSelector } from 'react-redux';

import './outstanding.scss'

const Outstanding = () => {
    const outstandingItems = useSelector(state => state.counsellor.outstandingItems)
    const futurePaidItems = useSelector(state => state.counsellor.futurePaidItems)

    const style = {
        border: "1px solid #cdcdcd",
        padding: "0.5rem",
        marginBottom: "1rem"
    }

    return (
        <>
            {   
                outstandingItems.length || futurePaidItems.length 
                    ? <InvoiceGroup heading="" styles={style}>
                        <FooterSection 
                            homepage={true}
                            outstandingItems={outstandingItems}
                            futurePaidItems={futurePaidItems}  
                        />
                      </InvoiceGroup>
                    : null
            }
            
        </>

    );
};

export default Outstanding;