/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:19:36 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-26 08:01:43
 */

import React from "react" //eslint-disable-line

const Container = (props) => (
  <div className={`layout__container ${props.className}`}>
    {props.children}
  </div>
)

export default Container