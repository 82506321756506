
import React, { useState } from 'react';
import { showTable, sortClients } from 'utils/utils';

import ClientsTable from '../clients-table/ClientsTable';
import SectionHead from 'ui-components/SectionHead';
import { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from 'material-ui';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/fontawesome-free-solid"

import './non-direct-clients.scss';

const NonDirectClients = ({clients, counsellorPage = false, counsellorId}) => {
    const [expanded, setExpanded] = useState(false)
    const expandHandler = () => setExpanded(!expanded)

    return (
        <div className="non-direct-clients">
            <SectionHead head={"Non Direct"}/>
            {
                showTable(clients.active) &&
                    <ClientsTable 
                        clientsData={sortClients(clients.active)} 
                        tableHead={"Active Clients"}
                        counsellorPage={counsellorPage}
                        counsellorId={counsellorId} 
                    />
            }
            {
                showTable(clients.deactivated) &&
                <ExpansionPanel 
                    expanded={expanded} 
                    onChange={expandHandler}
                    className="deactivated-table"
                >
                    <ExpansionPanelSummary 
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} className="expand-inmg"/> }
                        className="deactivated-summary"
                    >
                        <SectionHead 
                            head={"Deactivated clients"} 
                            className={"table-head"}
                        />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="deactivated-details">
                        <ClientsTable 
                            clientsData={sortClients(clients.deactivated)} 
                            tableHead={"Deactivated clients"} 
                            isDeactivated={true}
                            counsellorPage={counsellorPage}
                            counsellorId={counsellorId} 
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>      
            }
        </div>
    );
};

export default NonDirectClients;