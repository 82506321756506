/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 18:31:40 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-26 08:02:09
 */

import React from "react"
import layout from "../../layout"

const Footer = () => (
  <layout.components.FullWidthRow>
    <layout.components.Container>
      <footer className="layout__footer">
        <p className="text-center">&copy; No Fear Counselling { new Date().getFullYear() }</p>
      </footer>
    </layout.components.Container>
  </layout.components.FullWidthRow>
)

export default Footer