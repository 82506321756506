/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:41:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-22 21:24:24
 */

import * as actionTypes from "./actionTypes"

import core from "../core"
import model from "../../model"

export const reducer = (state = model.store(), action) => {
  switch(action.type) {

  case actionTypes.search:
    return action.appointments

  case actionTypes.toggleSelection:
    var appointment = state.find(a => a.id === action.id)
    var newAppointment = model.Appointment(appointment)
    newAppointment.selected = !appointment.selected
    return core.utils.updateCollection(state, o => o.id === action.id, newAppointment)

  case actionTypes.deleteAppointment: {
    return [...state.filter(a => a.id !== action.id)]
  }
    

  case actionTypes.updateAppointmentRate: {
    const appointmentIndex = state.findIndex(a => a.id === action.data.id)
    const appointmentFind = state.find(a => a.id === action.data.id) 

    appointmentFind.clientRate = action.data.rate
    appointmentFind.counsellorRate = action.data.counsellorRate
    appointmentFind.GST = action.data.GST

    const newAppointments = [...state]
    newAppointments.splice(appointmentIndex, 1, appointmentFind)

    return [...newAppointments]
  }
   
  case actionTypes.updateCounsellorRate: {
    const appointmentIndex = state.findIndex(a => a.id === action.data.id)
    const appointmentFind = state.find(a => a.id === action.data.id)

    appointmentFind.counsellorRate = action.data.counsellorRate
    appointmentFind.clientRate = action.data.clientRate
    appointmentFind.GST = action.data.GST

    const newAppointments = [...state]
    newAppointments.splice(appointmentIndex, 1, appointmentFind)

    return [...newAppointments]
  }
  
  case actionTypes.addAppointment: {
    return [...state, action.appointment]
  }

  case actionTypes.changeSource: {
    const updatedAppointments = action.updatedAppointments 
    
    const appointments = state.map(appointment => {
      updatedAppointments.forEach(a => {
        if (a.id === appointment.id) {
          appointment.client.paymentSource.name = action.source
        }
      });

      return appointment
    })

    return [...appointments]
  }
  default:
    return state
  }
}

export const expansionPanel = (state = {}, action) => {
  switch(action.type) {
    case actionTypes.expand:
      return {...state, [action.meta.id]: true }
    case actionTypes.collapse:
      return {...state, [action.meta.id]: false }
    default:
      return state
  }
}
