/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 19:22:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 13:00:08
 */


export const moduleName = "auth"
export const tokenUpdate = 30*1000

export const pages = {
  login: "/login"
}

export const endpoints = {
  login: "/login",
  verifyToken: "/verifyToken"
}