/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-30 20:21:49 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 14:56:37
 */


import * as Appointment from "./appointment"
import * as Client from "./client"

import PropTypes from "prop-types"
import _ from "lodash"
import core from "../modules/core"
const calcTotal = (appointments) => (
  appointments.reduce((last, current) => (last + (Number(current.counsellorRate) * (1 + core.constants.taxRate))), 0)
)

const calcTotalTax = (appointments) => (
  appointments.reduce((last, current) => (last + current.GST), 0)
)

const calcRateTotal = (appointments) => (
  appointments.reduce((last, current) => (last + Number(current.counsellorRate)), 0)
)

export default (appointments) => {
  const icbcClaimNumber = _.get(_.first(appointments), "icbcClaimNumber")
  const paymentSource = _.get(_.first(appointments), "client.paymentSource")
  const isIcbc = icbcClaimNumber !== ""
  const isNfc = _.get(_.first(appointments), "type").indexOf("(NFC)") > -1
  const rates = _.uniq(_.map(appointments, o => o.counsellorRate.toFixed(2))).join(", $")
  
  return {
    appointments,
    client: Client.default(_.first(appointments)),
    icbcClaimNumber,
    isIcbc,
    isNfc,
    rates,
    paymentSource,
    totalTax: calcTotalTax(appointments),
    totalRate: calcRateTotal(appointments),
    total: calcTotal(appointments)
  }
}

export const Shape = PropTypes.shape({
  client: Client.Shape,
  icbcClaimNumber: PropTypes.string,
  appointments: PropTypes.arrayOf(Appointment.Shape),
  total: PropTypes.number,
  totalRate: PropTypes.number,
  totalTax: PropTypes.number
})