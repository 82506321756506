/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-04-08 23:49:01
 */


import { Button , TextField, Paper } from "material-ui"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Registration from '../registration/Registration'
import core from "../../core"
import layout from "../../layout"
import * as actions from '../actions'
import { useHistory } from "react-router-dom";

import ForgotPassword from "../forgot-password/ForgotPassword"
import Logo from "../../../assets/img/logo.png"
import { useState } from "react"
import { showRegistrationPopUp, showForgorpasswordPopUp } from "../actions"
import { CSSTransition } from "react-transition-group"
import { ADMIN_ROUTE, COUNSELLOR_PAGE_ROURE } from "service/constants"
import { fetchPaymentSources } from "modules/admin/redux/actions"

const Login = () => {
  const [formState, setFormState] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()

  const showPopUp = useSelector(state => state.auth.showRegistrationPopUp)
  const showForgorpassword = useSelector(state => state.auth.showForgorpasswordPopUp)
  
  const loginRouterHandler = (isAdmin) => {
    if (isAdmin) {
      history.push(ADMIN_ROUTE)
    } else {
      history.push(COUNSELLOR_PAGE_ROURE)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    if (form.querySelectorAll('[data-valid="false"]').length === 0) {
      await dispatch(actions.login(formState, false, loginRouterHandler))
      await dispatch(fetchPaymentSources())      
    }
  }

  const onChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value.trim()
    })
  }

  const registrationHandler = () => dispatch(showRegistrationPopUp())
  
  const forgotPasswordHandler = () => dispatch(showForgorpasswordPopUp())

  return(
    <layout.components.FullWidthRow>
      <layout.components.Container>
        <Paper elevation={4} className="container__login">
          <img src={Logo} alt={core.constants.imageAlt} style={{width: '205px'}}/>
          <h1>Login</h1>
          <form className="form__login" onSubmit={onSubmit}>
            <TextField
              className="flex-37-5"
              id="username"
              label="Username"
              type="email"
              name="username"
              margin="normal"
              required={true}
              onChange={onChange}
              />  
            <TextField
              className="flex-37-5"
              id="password"
              label="Password"
              type="password"
              name="password"
              margin="normal"
              required={true}
              onChange={onChange}
              />
            <Button  type="submit" className="flex-50">Login</Button>  
            <Button  type="button" className="flex-50" onClick={registrationHandler}>Registration</Button>  
            <button  type="button" className="forgot-btn" onClick={forgotPasswordHandler}>Forgot password</button> 
            </form>
            <CSSTransition
              in={showPopUp}
              timeout={500}
              classNames="alert"
              unmountOnExit
            >
              <Registration/>
            </CSSTransition>
            <CSSTransition
              in={showForgorpassword}
              timeout={500}
              classNames="alert"
              unmountOnExit
            >
              <ForgotPassword/>
            </CSSTransition>
          </Paper>
        </layout.components.Container>
      </layout.components.FullWidthRow>
  );
}

export default Login