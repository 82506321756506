import React from 'react';
import { Button } from 'material-ui';
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { alertTypes } from 'service/constants';

import './client-buttons.scss'

const ClientButtons = ({isDeactivated, alertHandler, alertLoading, disableSaveBtn, saveLoading, saveHandler, activateLoading, activateHandler, mutationRequest, client}) => {
    const {alertsEnabled, alert} = client

    return (
        <div className='table-buttons client-buttons'>
            <Button 
                color="secondary" 
                variant="raised" 
                className="save-btn"
                disabled={disableSaveBtn}
                onClick={saveHandler}
            >
                {
                    saveLoading 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : "save"
                }      
            </Button>
            <Button 
                color="default" 
                variant="raised" 
                className={`deactivate-btn ${mutationRequest ? "request" : ""}`}
                onClick={activateHandler}
            >
                {
                    activateLoading 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : isDeactivated ? "activate" : "deactivate" 
                }
            </Button>
            <Button 
                color="default" 
                variant="raised" 
                className={`turn-alerts-btn ${!alertsEnabled ? "black" : ""}`}
                onClick={alertHandler}
            >
                {
                    alertLoading 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : alertsEnabled ? "Turn off alerts" : "Turn on alerts" 
                }
            </Button>
        </div>
    );
};

export default ClientButtons;