import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Button } from 'material-ui';
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import './request-button.scss'

const RequestButton = ({isDeactivated, loading, buttonHandler, mutationRequest}) => {
    return (
        <div className='table-buttons client-buttons request-button-wrapper '>
            <Button 
                color="default" 
                variant="raised" 
                className={`request-button ${mutationRequest ? "active": ""}`}
                onClick={buttonHandler}
            >
                {
                    loading 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : `Request to ${isDeactivated ? "activate" : "deactivate"}`
                }
            </Button>
        </div>
    );
};

export default RequestButton;