import React, { useRef } from 'react';
import {useState} from 'react'
import { useSelector } from 'react-redux';

import { BAD_ITEMS_TO_SHOW } from 'service/constants';
import ShowMoreButton from 'ui-components/ShowMoreButton';
import UpButton from 'ui-components/UpButton';
import GroupedItems from '../grouped-items/GroupedItems'

import './bad-fit-table.scss'

const BadFitTable = () => {
    const [itemsToShow, setItems] = useState(BAD_ITEMS_TO_SHOW)
    const badFitItems = useSelector(state => state.admin.badFitItems)
    const badFitTable = useRef(null)

    const buttonHandler = () => {
        setItems(badFitItems.length)
    }

    const collapseHandler = () => {
        setItems(BAD_ITEMS_TO_SHOW)

        window.scrollTo({top: badFitTable.current.offsetTop + 1000, left: 0, behavior: 'smooth'})
    }

    const badFitList = badFitItems?.slice(0, itemsToShow) || []
    return (
        <div className='bad-fit-table' ref={badFitTable}>
            <section className="invoice-line-item-container" > 
                <article className="invoice-line-item header bad-fit-head">
                    <span className="flex-20" style={{flex: "10% 0.5"}}>Client Name</span>
                    <span className="flex-20" style={{flex: "10% 0.5"}}>Counsellor Name</span>
                    <span className="flex-20" style={{flex: "12% 2"}}>Notes</span>
                </article>
                {
                    badFitList.map(group => {
                        if (group.items.length) {
                            return (
                                <GroupedItems
                                    key={group.date}
                                    groupId={group.id}
                                    items={group.items}
                                    date={group.date}
                                />
                            )
                        }
                    })
                }
            </section>
            <ShowMoreButton 
                onClick={buttonHandler}
                disabled={badFitItems?.length === itemsToShow}
            />
            {
                itemsToShow > BAD_ITEMS_TO_SHOW  && 
                    <UpButton
                        onClick={collapseHandler}
                    />
            }   
        </div>
    );
};

export default BadFitTable;