import React, { useEffect, useState } from 'react';
import FullWidthRow from "../layout/components/FullWidthRow"
import Container from "../layout/components/Container"
import {Paper} from "material-ui"
import { useDispatch } from 'react-redux';
import { fetchCounsellor } from 'modules/counsellor/redux/actions';
import Dashboard from 'modules/counsellor/dashboard/Dashboard';
import Clients from 'modules/counsellor/clients/Clients';
import Loading from 'ui-components/Loading';
import Outstanding from 'modules/counsellor/outstanding/Outstanding';

const CounsellorHomePage = () => {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(async () => {
        await dispatch(fetchCounsellor())
        setLoading(false)
    }, [dispatch])
    
    return (
        <FullWidthRow>
           <Container>
                <Paper elevation={4} className="counsellor-homepage">
                    {
                        loading ? 
                        <Loading/>
                        : 
                        <>
                            <Dashboard/>
                            <Clients/>
                            <Outstanding/>
                        </>
                    }
                
            </Paper>
           </Container>
       </FullWidthRow>
    );
};

export default CounsellorHomePage;