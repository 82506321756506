/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:19:36 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-24 00:59:42
 */

import React from 'react'

const FullWidthRow = (props) => (
  <div className={`layout__full-width-row ${props.className}`}>
    {props.children}
  </div>
)

export default FullWidthRow