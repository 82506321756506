import React from 'react';
import { useSelector } from 'react-redux';
import SectionHead from 'ui-components/SectionHead';
import AlertList from './alert-list/AlertList';

import './dashboard.scss'

const Dashboard = () => {
    return (
        <div className="counsellor-dashboard">
            <SectionHead head={"Dashboard"}/>
            <AlertList />
        </div>
    );
};

export default Dashboard;