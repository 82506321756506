/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:48 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 13:38:35
 */

import { moduleName } from "./constants"

export const showMessage = `${moduleName}.showMessage`
export const hideMessage = `${moduleName}.hideMessage`
