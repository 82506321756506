/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 11:41:26 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-14 19:46:54
 */

export default ({
  calendarID = undefined,
  date = undefined,
  email = undefined,
  firstName = undefined,
  id = undefined,
  lastName = undefined,
  max = undefined,
  phone = undefined,
  search = undefined,
  time = undefined,
  type = undefined,
} = {}) => ({
  calendarID,
  date,
  email,
  firstName,
  id,
  lastName,
  max,
  phone,
  search,
  time,
  type
})