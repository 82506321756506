/*
 * @Author: Mike Cottingham 
 * @Date: 2019-01-22 21:15:10 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-22 21:41:27
 */

import React from "react"
import * as appActions from '../actions'
import * as actions from '../../invoice/actions'
import { getExpansionPanel } from '../selectors'
import { showMessage } from 'modules/messages/actions'
import _ from "lodash"
import core from "../../core";
import moment from "moment"
import { exportToPdf, exportToZip, getInvoicesNumbers } from "../../../service/api";
import { useState } from 'react'
import { Typography } from "material-ui"
import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'

import { Button } from 'material-ui'
import { faChevronDown } from "@fortawesome/fontawesome-free-solid"
import styled from 'styled-components'
import ClientAppointmentListItem from "./ClientAppointmentListItem"
import layout from "../../layout"
import { failedMessage, zipSuccessMessage } from 'modules/messages/messages'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { Checkbox, ExpansionPanel } from "material-ui"
import { ExpansionPanelDetails } from "material-ui"
import { ExpansionPanelSummary } from "material-ui"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { CLINICAL_RECORDS, ICBC, ITEMS_TO_EMAIL_ZIP, PROGRESS_REPORT } from "service/constants"
import { getBillTo, getClientName, getIdByDate } from "utils/utils"
import { icbcAccountNumber } from "modules/core/constants"

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    ...appActions
  }, dispatch)
)

const stateToProps = (state, props) => {
  return {
    expanded: state.expansionPanel[props.id] || false
  }
}

const Details = styled(ExpansionPanelDetails)`
  padding: 0 0 1rem 0;
`

const Summary = styled(ExpansionPanelSummary)`
  padding: 0 1rem;
  margin: 0 !important;
`

const FundingExpansionPanel = ({source, collapse, expand, id, title, onClick, isDirectBill, appointments, exportInvoices, expanded, isActive}) => {
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const isAdmin = useSelector(state => state.auth.profile.isAdmin)
  
  const checkboxHandler = (e) => {
    e.stopPropagation()
    if (selected.length) {
      setSelected([])
    } else {
      setSelected([...appointments])
      expand(id)
    }
  }

  
  const singleExport = async  (invoice) => {
    const {client, counsellors, appointments} = invoice

    const exportAppointments = []
    let totalTax = 0
    let total = 0
    let totalRate = 0
    let initialreportId = undefined

    appointments.sort((a, b) => getIdByDate(b.date) - getIdByDate(a.date)).forEach((element, i) => {
      const {paymentSource, type, GST, date, clientRate} = element
      
      if (paymentSource === "ICBC" && type.toLowerCase().includes('initial report')) {
        initialreportId = i
        
      } else if (!(i === (initialreportId + 1))) {
        const price = Number(clientRate) || 0
        const tax = GST
        const linePrice = price + tax

        const appointmentsForExport = {
            description: type,
            date: moment(date).format("MMM DD, YYYY"),
            rate: price,
            GST: +tax.toFixed(2),
            total: +linePrice.toFixed(2)
        }
        
        exportAppointments.push(appointmentsForExport)

        total += linePrice
        totalTax += tax
        totalRate += price
      }
    })

    const dataForExport = [
      {
        id: +appointments[0].id,
        bill_to: getBillTo(client.firstName, client.lastName, client.paymentSource),
        account_number: client.paymentSource.name.toLowerCase() === ICBC ? icbcAccountNumber : "",
        therapist_name: counsellors[0],
        date: core.utils.formatDate(new Date()),
        claim_number: client.claimNumber || "",
        name_in_print: getClientName(client.firstName, client.lastName, client.paymentSource),
        first_name: client.firstName,
        last_name: client.lastName,
        appointments: exportAppointments.sort((a, b) => getIdByDate(a.date) - getIdByDate(b.date)),
        result_rates: totalRate.toFixed(2),
        result_GST: totalTax.toFixed(2),
        total_result: total.toFixed(2),
      }
    ]

    const exportedInvoice = [{
      billTo: client.paymentSource,
      invoiceNumber: String(Date.now()).slice(-3),
      invoiceDate: moment(invoice.date).subtract(10, 'days').calendar(),
      therapistName: counsellors[0],
      asReceipt: false,
      isExportDisabled: false,
      invoice: {
        ...invoice, 
        icbcClaimNumber: client.claimNumber, 
        numberOfAppointments: exportAppointments.length, 
        total
      }
    }]
    
    try {
      setLoading(true)
      const response = await exportToPdf(dataForExport)
      
      const invoiceNumber = response.headers.invoice_number 
      const fileName = client.claimNumber 
        ? `${invoiceNumber}-${client.claimNumber}-${client.firstName} ${client.lastName}.pdf`
        : `${invoiceNumber}-${client.firstName} ${client.lastName}.pdf`


      exportedInvoice[0].invoiceNumber = invoiceNumber
      downloadFile(response, fileName)
      exportInvoices(exportedInvoice)
    } catch(e) {
      dispatch(showMessage(failedMessage))
    } finally {
      setLoading(false)
    }
        
  }

  const downloadFile = (file, fileName) => {
    const url = window.URL.createObjectURL(new Blob([file.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click((e) => e.preventDefault());
  }

  const exportHandler = async (e) => {
    e.stopPropagation()

    if(selected.length < 5) {  
      for (const element of selected) {
        await singleExport(element)

        setSelected(prev => {
            const updatedSelect = prev.filter(el => el.id !== element.id)
            return [...updatedSelect]
          }
        )
      }   
    } else {
      let data = []
      const exportedInvoicesList = []
      const fileName = `${selected[0].paymentSource.name} - ${selected.length} appointments.zip`
      
      selected.forEach((element, i) => {
        const {client, counsellors, appointments} = element
        
        const exportAppointments = []
        let totalTax = 0
        let total = 0
        let totalRate = 0
        let initialreportId = undefined

        appointments.sort((a, b) => getIdByDate(b.date) - getIdByDate(a.date)).forEach((element, i) => {
          const {paymentSource, type, GST, date, clientRate} = element

          if(paymentSource === "ICBC" && type.toLowerCase().includes('initial report')) {
            initialreportId = i
          } else if (!(i === (initialreportId + 1))) {
            const price = Number(clientRate) || 0
            const tax = GST
            const linePrice = price + tax

            const appointmentsForExport = {
                description: type,
                date: moment(date).format("MMM DD, YYYY"),
                rate: price,
                GST: +tax.toFixed(2),
                total: +linePrice.toFixed(2)
            }
            exportAppointments.push(appointmentsForExport)

            total += linePrice
            totalTax += tax
            totalRate += price
          }
        })
        
        const dataForExport = {
          id: +appointments[0].id,
          therapist_name: counsellors[0],
          bill_to: getBillTo(client.firstName, client.lastName, client.paymentSource),
          account_number: client.paymentSource.name.toLowerCase() === ICBC ? icbcAccountNumber : "",
          date: core.utils.formatDate(new Date()),
          claim_number: client.claimNumber || "",
          name_in_print: getClientName(client.firstName, client.lastName, client.paymentSource),
          first_name: client.firstName,
          last_name: client.lastName,
          appointments: exportAppointments.sort((a, b) => getIdByDate(a.date) - getIdByDate(b.date)),
          result_rates: totalRate.toFixed(2),
          result_GST: totalTax.toFixed(2),
          total_result: total.toFixed(2),
        }

        data.push(dataForExport)

        const exportedInvoice = {
          id: +appointments[0].id,
          billTo: client.paymentSource.name,
          invoiceNumber: null,
          invoiceDate: moment(element.date).format("MMM DD, YYYY"),
          therapistName: counsellors[0],
          asReceipt: false,
          isExportDisabled: false,
          invoice: {
            ...element, 
            icbcClaimNumber: client.claimNumber, 
            numberOfAppointments: exportAppointments.length, 
            total
          }

        }

        exportedInvoicesList.push(exportedInvoice)
      });    
      
      try {
        setLoading(true)
        const dataLength = data.length
        const response = await exportToZip(data)
        const numbersList = await getInvoicesNumbers(+response.headers.operation_id)
        
        numbersList.forEach(({invoiceNumber, invoiceId}) => {
          exportedInvoicesList.forEach(invoice => {
            if (invoice.id === invoiceId) {
              invoice.invoiceNumber = invoiceNumber
            }
          }) 
        })

        if (dataLength <= ITEMS_TO_EMAIL_ZIP) {
          downloadFile(response, fileName)
        }
        exportInvoices(exportedInvoicesList)
        
        if (dataLength > ITEMS_TO_EMAIL_ZIP) {
          dispatch(showMessage(zipSuccessMessage))
        }
      } catch(e) {
        dispatch(showMessage(failedMessage))
      } finally {
        setLoading(false)
        setSelected([])
      }
    }
  }

  const onChange = (e, appointment, checked) => {
    e.stopPropagation()
    
    if (checked) {
      const newSelected = selected.filter(el => el.id !== appointment.id)
      setSelected([...newSelected])
    } else {
      setSelected([...selected, appointment])
    }
    
  }

  const expandHandler = (e, exp) => {
    exp ? expand(id) : collapse(id)
  }

  const listItems = appointments.map((clientAppointment, i) => (
    <ClientAppointmentListItem 
      clientAppointment={clientAppointment} 
      selectedItems={selected}
      key={`${clientAppointment.id}-${i}`} 
      onClick={onClick(clientAppointment)}
      onChange={onChange}
      singleExport={singleExport}
    />
  ))
  
  const showExportElements = isDirectBill && isAdmin
  return (
    <ExpansionPanel expanded={expanded} onChange={expandHandler}>
      <Summary expandIcon={<FontAwesomeIcon icon={faChevronDown} /> } style={{paddingLeft: !showExportElements ? 53 : 16}}>
        <Typography component={'span'}>
          {
            showExportElements && 
              <Checkbox 
              onClick={checkboxHandler}
              className="select-all-checkbox"
              checked={selected.length}  
              />
          }
          
          {title}
        </Typography>
        {
          !isActive && source !== PROGRESS_REPORT && source !== CLINICAL_RECORDS && 
          <div className="deactivated-indicator">Deactivated</div>
        }
        {
          showExportElements && 
            <Button 
              color="secondary" 
              variant="raised" 
              className="export-all-btn" 
              disabled={selected.length <= 1 || loading} 
              onClick={exportHandler}
            >
              {
                loading 
                  ? <FontAwesomeIcon icon={faSpinner} spin />
                  : "Export All"
              }
            </Button>
        }
      </Summary>
      <Details>
        <layout.components.Container>
          {listItems}
        </layout.components.Container>
      </Details>
    </ExpansionPanel>
  )
}

export default connect(stateToProps,dispatchToProps)(FundingExpansionPanel)