/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-30 20:21:49
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-15 19:28:34
 */

import * as Appointment from "./appointment";
import * as Client from "./client";

import PropTypes from "prop-types";
import _ from "lodash";

const calcTotal = (appointments) => appointments.reduce((last, current) => last + (Number(current.clientRate) + Number(current.GST)), 0);

const calcTotalTax = (appointments) => appointments.reduce((last, current) => last + Number(current.GST), 0);

const calcRateTotal = (appointments) => appointments.reduce((last, current) => last + Number(current.clientRate), 0);

const getTotalPaid = (appointments) => appointments.filter((a) => a.paid === "yes" && (a.price <= a.amountPaid ||  a.amountPaid === 0)).length;

const getTotalAmountPaid = (appointments) => appointments.reduce((amountPaid, a) => (amountPaid += Number(a.amountPaid) || 0), 0);

const getId = () => Date.now()

export default (appointments) => ({
  id: appointments[0]?.id || 0,
  client: Client.default(_.first(appointments)),
  counsellors: _.uniq(_.flatMap(appointments, "calendar")),
  icbcClaimNumber: _.get(_.first(appointments), "claimNumber"),
  paymentSource: _.get(_.first(appointments), "paymentSource"),
  locations: _.uniq(_.flatMap(appointments, "type")),
  appointments,
  totalPaid: getTotalPaid(appointments),
  amountPaid: getTotalAmountPaid(appointments),
  totalTax: calcTotalTax(appointments),
  totalRate: calcRateTotal(appointments),
  total: calcTotal(appointments),
})

export const Shape = PropTypes.shape({
  id: setTimeout(getId(),0),
  client: Client.Shape,
  counsellors: PropTypes.arrayOf(PropTypes.string),
  icbcClaimNumber: PropTypes.string,
  paymentSource: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.string),
  appointments: PropTypes.arrayOf(Appointment.Shape),
  total: PropTypes.number,
  totalRate: PropTypes.number,
  totalTax: PropTypes.number,
});
