
import React, { useState } from 'react';
import { showTable, sortClients } from 'utils/utils';

import SectionHead from 'ui-components/SectionHead';
import ClientsTable from '../clients-table/ClientsTable';
import { ExpansionPanelSummary, ExpansionPanelDetails } from 'material-ui';
import { ExpansionPanel } from 'material-ui';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/fontawesome-free-solid"

import './direct-clients.scss';

const DirectClients = ({clients, counsellorPage, counsellorId}) => {
    const [expanded, setExpanded] = useState(false)
    const [otherExpanded, setOtherExpanded] = useState(false)

    const expandHandler = () => setExpanded(!expanded)
    return (
        <div className="direct-clients">
            <SectionHead head={"Direct"}/> 
            {   
                showTable(clients.activeICBC) &&
                    <ClientsTable 
                        clientsData={sortClients(clients.activeICBC)} 
                        tableHead={"Active Clients -  ICBC"} 
                        isDirect={true} 
                        isICBC={true}
                        counsellorPage={counsellorPage}
                        counsellorId={counsellorId} 
                    />
            }
            {
                showTable(clients.deactivatedICBC) &&
                <ExpansionPanel 
                    expanded={expanded} 
                    onChange={expandHandler}
                    className="deactivated-table"
                >
                    <ExpansionPanelSummary 
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} className="expand-inmg"/> }
                        className="deactivated-summary"
                    >
                        <SectionHead 
                            head={"Deactivated clients -  ICBC"} 
                            className={"table-head"}
                        />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="deactivated-details">
                        <ClientsTable 
                            clientsData={sortClients(clients.deactivatedICBC)} 
                            tableHead={"Deactivated clients -  ICBC"} 
                            isDirect={true} 
                            isICBC={true} 
                            isDeactivated={true}
                            counsellorPage={counsellorPage}
                            counsellorId={counsellorId} 
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
            {
                showTable(clients.activeOther) &&
                    <ClientsTable 
                        clientsData={sortClients(clients.activeOther)} 
                        tableHead={"Active Clients - Other Direct"} 
                        isDirect={true}
                        counsellorPage={counsellorPage}
                        counsellorId={counsellorId} 
                    />
            }
            
            {
                showTable(clients.deactivatedOther) && 
                <ExpansionPanel 
                    expanded={otherExpanded} 
                    onChange={() => setOtherExpanded(!otherExpanded)}
                    className="deactivated-table"
                >
                    <ExpansionPanelSummary 
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} className="expand-inmg"/> }
                        className="deactivated-summary other-clients"
                    >
                        <SectionHead 
                            head={"Deactivated Clients -  Other Direct"} 
                            className={"table-head"}
                        />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="deactivated-details">
                        <ClientsTable 
                            clientsData={sortClients(clients.deactivatedOther)} 
                            tableHead={"Deactivated Clients -  Other Direct"} 
                            isDirect={true} 
                            isDeactivated={true}
                            counsellorPage={counsellorPage}
                            counsellorId={counsellorId} 
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                    
            }
        </div>
    );
};

export default DirectClients;