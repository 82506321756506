
import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import {emailPassword} from '../../../../../service/api'
import { useDispatch } from 'react-redux';
import { showMessage } from '../../../../messages/actions';

import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { emailRegex } from 'service/constants';
import FormInput from 'ui-components/FormInput';
import { Button } from 'material-ui';
import { failedMessage, invalidEmailMessage } from '../../../../messages/messages';

import './email-form.scss'
import { generateRegistrationMessage } from 'utils/utils';

const EmailForm = ({setTab, setEmail}) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const {
        register,
        formState: { errors },
        handleSubmit,
        watch
    } = useForm({
        mode: "onSubmit",
        reValidateMode: 'onChange',
    });

    const onSubmit = async (data) => {
        const {email} = data

        try {
            setLoading(true)
            await emailPassword(email, true)
            setEmail(email)
            setTab()
        } catch(e) {
            const errorMessage = e.response.data?.message
            const message = errorMessage 
                ? generateRegistrationMessage(errorMessage)
                : failedMessage

            dispatch(showMessage(message))
        } finally {
            setLoading(false)
        }
        
    } 

    const email = watch("email")
    const isBtnDisabled = !email || errors.email || loading
    return (
        <form 
            className="email-form" 
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormInput
                title={"Email"}
                register={register}
                errors={errors}
                name={"email"}
                pattern={emailRegex}
                invalidMessage={invalidEmailMessage}

            />
            <Button
                color="secondary" 
                variant="raised" 
                className="submit-button"
                type="submit"
                disabled={isBtnDisabled}
            >
                { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Next"}
            </Button>
        </form>
    );
};

export default EmailForm;