import React from 'react';
import { Paper } from 'material-ui'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/fontawesome-free-solid'

const NoItems = () => {
  return (
    <Paper elevation={4} className="alert__info">
      <h1>
        <FontAwesomeIcon icon={faSearch} />
      </h1>
      <p>There appears to be nothing here.</p>
    </Paper>
  )
}

export default NoItems