import axios from "axios";
import { logout } from "modules/auth/actions";
import { showMessage } from "modules/messages/actions";
import { loginFailedMessage } from "modules/messages/messages";
import { isAuthError } from "utils/utils";
import {store} from '../app/storeConfig'

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

const authInterceptor = config => {
    const token = localStorage.getItem("token")
    if (token) config.headers.authorization = `Bearer ${token}`

    return config
}

const errLogout = () => {
    localStorage.removeItem("token")
    store.dispatch(logout())
}

const onSuccess = (data) => {
    const errors = data.data.errors

    if (errors?.length && !data.data.data) {
        if (isAuthError(errors)) {
            errLogout()
            store.dispatch(showMessage(loginFailedMessage))
        }

        return Promise.reject()
    }
    
    return Promise.resolve(data)
}

const onError = (error) => {
    if (error.response.status === 401) {
        errLogout()
    }
     
    return Promise.reject(error)
}

$authHost.interceptors.request.use(authInterceptor)

$authHost.interceptors.response.use(onSuccess, onError)

export {
    $authHost
}
