import React, { useState } from 'react';
import smileImg from 'assets/img/smile.svg'
import './alert.scss'
import AlertButton from 'ui-components/AlertButton';
import { ALERT_CLIENTS_COUNT } from 'service/constants';
import CollapseAlertButton from 'ui-components/CollapseAlertButton';

const Alert = ({head, headImg, clients}) => {
    const clientsLength = clients.length
    const [itemsToShow, setItems] = useState(ALERT_CLIENTS_COUNT)

    const buttonHandler = () => setItems(prev => prev + ALERT_CLIENTS_COUNT)
    const collapseHandler = () => setItems(ALERT_CLIENTS_COUNT)

    const clientsToShow = clients.slice(0, itemsToShow)
    return (
        <div className="alert">
            <div className="alert-wrapper">
                <div className="alert-head">
                    <img src={headImg} alt="approval"/>
                    {head}
                </div>
                <div className="clients-list">
                {
                    clientsLength  
                        ? clientsToShow.map(client => <h4 className="client-name" key={client.id}>{client.fullName}</h4>)
                        : <img src={smileImg} className="empty-alert" alt="empty"/>
                }
                </div>
                {
                    clientsToShow.length > ALERT_CLIENTS_COUNT
                        ? <CollapseAlertButton onClick={collapseHandler}/>
                        : null
                }  
                {
                    clientsLength 
                        ? <AlertButton 
                                onClick={buttonHandler}
                                disabled={clientsLength <= itemsToShow}
                        /> 
                        : null
                }
            </div>
        </div>
    );
};

export default Alert;