import { notAuthedMessage } from "../modules/messages/messages"
import { INITIAL_REPORT, PROGRESS_REPORT, CLINICAL_RECORDS, alertTypes, LAWYER, DTP, CFSEU, PRIVATE_EXTENDED, LOW_COST, COMPLETED_TASKS_TO_SHOW, nonDirectDillClients, PAST, REQUEST_DATE_FORMAT, BAD_FIT_DATE_FORMAT, APP_DATE_FORMAT, RECEIPT_DATE_FORMAT } from "../service/constants"
import moment from "moment"

export const isReport = appointmentType => {
    const type = appointmentType.toLowerCase()

    if (type.includes(INITIAL_REPORT.toLocaleLowerCase())) {
        return INITIAL_REPORT
    }

    if (type.includes(PROGRESS_REPORT.toLocaleLowerCase())) {
        return PROGRESS_REPORT
    }

    if (type.includes(CLINICAL_RECORDS.toLocaleLowerCase())) {
        return CLINICAL_RECORDS
    }
}

export const isProgressReport = appointmentType => {
    const type = appointmentType.toLowerCase()

    if (type.includes(PROGRESS_REPORT.toLocaleLowerCase())) {
        return true
    }

    return false
}

export const isClinicalRecords = appointmentType => {
    const type = appointmentType.toLowerCase()

    if (type.includes(CLINICAL_RECORDS.toLocaleLowerCase())) {
        return true
    }

    return false
}

export const isInitialReport = appointmentType => {
    const type = appointmentType.toLowerCase()

    if (type.includes(INITIAL_REPORT.toLocaleLowerCase())) {
        return true
    }

    return false
}

export const isPartPaid = (appointment) => appointment.paid === "yes" && appointment.amountPaid < appointment.price

export const generateDate = () => moment(new Date()).format(BAD_FIT_DATE_FORMAT)

export const showTable = (clients) => !!clients.length

export const formatDate = (date) => date.toISOString().slice(0, 10)

export const showClienAppointment = (appointment) => !appointment.canceled

export const isAuthError = (errors) => !!errors.find(err => err.message.toLocaleLowerCase().includes(notAuthedMessage.toLocaleLowerCase()))

export const getDateDifference = (date) => {
    const currentDate = new Date();
    const expDate = new Date(date)

    return Math.ceil((expDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));
}

export const generateAppDate = (date, receipt) => {
    if (date) {
        return moment(date).format(receipt  ? RECEIPT_DATE_FORMAT : APP_DATE_FORMAT)
    } 
     
    return null
}

export const getEmailText = (name, date, text) => {
    const emailHead = ` Hi ${name},<br><br>
    Your ${date} invoice is now available.<br>`
    const separatedText = text.split(/\n/)
    
    return `${emailHead} ${separatedText.join("<br/>")}`
}

export const dateCompare = (maxDate, minDate) => {
    const maxTime = new Date(maxDate).getTime()
    const minTime = new Date(minDate).getTime()

    return maxTime > minTime
}

export const isEmpty = (obj) => {
    for (let key in obj) {
        return false;
    }
    
    return true;
}

export const sortClients = (clients) => clients.sort((a, b) => a.priority - b.priority)

export const getBadFitDate = (item) => moment(item).format(BAD_FIT_DATE_FORMAT)

export const getIdByDate = (date) =>  new Date(date).getTime()

export const showSection = (obj) => {
    for (let key in obj) {
        if (obj[key].length) {
            return true
        }
    }
    
    return false;
}

export const requestDate = (date) => date ? moment(date).format(REQUEST_DATE_FORMAT) : null

export const getTaskBody = (task) => {
    const {appointment} = task
    const appCounsellor = appointment?.counsellor.name

    return `${appointment?.firstName} ${appointment?.lastName} (${appCounsellor}) (${generateAppDate(appointment?.date)})`
}

export const tableWithRedAlert = (clients = []) => {
    for(let client of clients) {
        if (client.alert === alertTypes.red) {
            return true
        } 
    }

    return false
}

export const getInitials = (firstName, lastName, receipt = false) => receipt ? `${firstName.slice(0, 1)}${lastName.slice(0, 1)}` : `${firstName.slice(0, 1)} ${lastName.slice(0, 1)}`

export const getBillTo = (firstName, lastName, paymentSource) => {
    const includes = (source) => paymentSource?.name.toLowerCase().includes(source)

    if (includes(LAWYER) || includes(DTP)) {
        return ""
    }

    if (includes(CFSEU)) {
        return getInitials(firstName, lastName)
    }

    if (includes(PRIVATE_EXTENDED) || includes(LOW_COST)) {
        return `${firstName} ${lastName}`
    }

    return paymentSource.name
}

export const getClientName = (firstName, lastName, paymentSource) => {
    if (paymentSource?.name.toLowerCase().includes(CFSEU)) {
        return getInitials(firstName, lastName)
    }

    return `${firstName} ${lastName}`
}

export const includes = (type, type1, type2) => {
    const elementType =  type.toLowerCase()

    return elementType.includes(type1.toLowerCase()) || elementType.includes(type2.toLowerCase())
}

export const calcTotals = (client, appointments) => {
    let total = 0, 
        totalPaid = 0,
        totalRate = 0,
        totalTax = 0,
        amountPaid = 0

    appointments.map(appointment => {
        const {clientRate, GST, paid, amountPaid: amountPaidApps, price} = appointment

        total += clientRate + GST
        totalRate += clientRate
        totalTax += GST
        amountPaid += Number(amountPaidApps)
        
        if (paid === "yes" && (price <= amountPaidApps ||  amountPaidApps === 0)) {
            totalPaid += 1
        }
    })

    client.total = total
    client.totalPaid = totalPaid
    client.totalRate = totalRate
    client.totalTax = totalTax
    client.amountPaid = amountPaid
}

export const completedToShow = (items = []) => {
    const itemsCount = items.findIndex(item => item.dateOfCompleteTask !== items[0].dateOfCompleteTask)

    return itemsCount !==  -1 && itemsCount < 4 ? itemsCount : COMPLETED_TASKS_TO_SHOW
}

export const getHomepageMinDate = () => moment(new Date()).subtract(6, 'month').format(REQUEST_DATE_FORMAT)

export const generateRegistrationMessage = (message) => ({
    title: "Registration failed",
    message,
    type: "error"
})

export const isChanged = (value, changedValue) => value !== changedValue

export const onEmailChange = (email, setState) => {        
    if (!email.includes(" ") && !email.includes(",")) {
        setState(email)
    }
}