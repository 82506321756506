/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 22:12:42 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 23:16:33
 */

export const generate = ({
  welcome = undefined
} = {}) => ({
  welcome
})