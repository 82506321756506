import {ADD_OUTSTANDING_ITEM, REMOVE_OUTSTANDING_ITEM, ADD_OUTSTANDING_ITEMS} from "./actionTypes"

export const addOutstandingItem = (item) => ({
  type: ADD_OUTSTANDING_ITEM,
  item
})

export const addOutstandingItems = (items) => ({
  type: ADD_OUTSTANDING_ITEMS,
  items
})

export const removeOutstandingItem = (id) => ({
  type: REMOVE_OUTSTANDING_ITEM,
  id
})