import React from "react"
import minus from 'assets/img/minus.svg'
const CounsellorSectionHeader = ({totals, showGST}) => {
  
  return (
    <section className="invoice-line-item-container invoice-total">
      <article className="invoice-line-item header">
        <span className="flex-20">{totals.sessions} Sessions</span>
        <span className="flex-20 text-right">{(showGST || totals.serviceFee) ? "Sub Total" : "Total"}</span>
        <span className="flex-20">${totals.totalRate.toFixed(2)}</span>
      </article>
      { totals.serviceFee ?
        <article className="invoice-line-item header">
         <span className="flex-20"></span>
         <span className="flex-20 text-right">Service fee</span>
         <span className="flex-20"><img src={minus} className="fee-minus"/>${totals.serviceFee.toFixed(2)}</span>
       </article>
       : null
      }
      {
        showGST ?
          <article className="invoice-line-item header">
            <span className="flex-20"></span>
            <span className="flex-20 text-right">Total GST</span>
            <span className="flex-20">${totals.totalTax.toFixed(2)}</span>
          </article>
          : null
      }
      {
        (showGST || totals.serviceFee) ?
          <article className="invoice-line-item header">
            <span className="flex-20"></span>
            <span className="flex-20 text-right">Total</span>
            <span className="flex-20">${totals.total.toFixed(2)}</span>
          </article>
          : null
      }
    </section>
  )
}

export default CounsellorSectionHeader