/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 18:39:02 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 13:54:56
 */

import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import {useDispatch} from 'react-redux'
import { verifyTokenAction } from 'modules/auth/actions';

import auth from "../modules/auth"
import layout from "../modules/layout"
import messages from "../modules/messages"
import pages from "../modules/pages"
import Spinner from "ui-components/Spinner";
import { fetchPaymentSources } from "modules/admin/redux/actions";

const RootRouter = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const token = localStorage.getItem("token")
    
    if (token) {
      setLoading(true)
      await dispatch(verifyTokenAction(setLoading))
      await dispatch(fetchPaymentSources())
      setLoading(false)
    }

  }, [dispatch])

  return (
    <Router>
      { !loading 
          ? <main>
              <layout.components.TopNav />
              <messages.components.Container />
              <auth.components.AuthContainer>
                <Switch>
                  <pages.Routes />
                </Switch>
              </auth.components.AuthContainer>
              <layout.components.Footer />
            </main>
          : <Spinner/>
      
      }
      
    </Router>
  )
}

export default RootRouter