
import React, { useRef, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'
import { dateCompare, generateAppDate, isChanged } from 'utils/utils';
import { requestToActivate, updateClientInfo } from 'service/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUpdatedCounsellor } from 'modules/admin/redux/actions';
import { showMessage } from 'modules/messages/actions';
import moment from "moment"

import RequestButton from '../request-button/RequestButton';
import { Checkbox, TextField } from 'material-ui';
import ClientButtons from '../client-buttons/ClientButtons';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { failedMessage } from 'modules/messages/messages';

import './table-item.scss'

const TableItem = ({ client, isDirect, isICBC, isDeactivated, counsellorPage, counsellorId }) => {
    const {
        firstName,
        lastName,
        approvedSessions,
        usedSessions,
        totalBilled,
        expiryDate,
        alert,
        chargedSessions,
        partiallyPaidIDs,
        isActive,
        mutationRequest,
        id,
        dateOfApprovalCheck,
        paymentSource,
        alertsEnabled
    } = client

    const [changedExpDate, setDate] = useState(expiryDate ? generateAppDate(expiryDate) : null)
    const [approved, setApproved] = useState(approvedSessions)
    const [loading, setLoading] = useState(false)
    const [checkboxDisabled, setCheckboxDisabled] = useState(false)
    const [activateLoading, setActivateLoading] = useState(false)
    const [alertLoading, setAlertLoading] = useState(false)
    const [requestIsActive, setRequest] = useState(mutationRequest)
    const [selectedPaymentSource, setPaymentSource] = useState(paymentSource.name)
    const [changedChargedSessions, setChargedSessions] = useState(chargedSessions)
    const dispatch = useDispatch()
    const paymentSources = useSelector(state => state.admin.sources)
    const chargedInput = useRef(null)
    const approvedInput = useRef(null)

    const inputHandler = (e, setState) => {
        const value = e.target.value

        if (!isNaN(value) && value.length < 20) {
            setState(+value)
        }
    }

    const dateInputHandler = (date) => {
        const updatedDate = generateAppDate(date)

        setDate(updatedDate)
    }

    const requestHandler = async (requestData, setLoading) => {
        try {
            setLoading(true)
            await updateClientInfo(requestData)
            await dispatch(fetchUpdatedCounsellor(counsellorId))
        } catch (e) {
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
    }

    const activateHandler = async () => await requestHandler({ isActive: !isActive, mutationRequest: false, id }, setActivateLoading)

    const alertHandler = async () => await requestHandler({ alertsEnabled: !alertsEnabled, id }, setAlertLoading)

    const checkboxHandler = async (e) => {
        e.stopPropagation()
        await requestHandler({ approvalStatus: !dateOfApprovalCheck, id }, setCheckboxDisabled)
    }

    const saveHandler = async () => {
        const expDate = moment(new Date(changedExpDate)).format("YYYY-MM-DD")

        const requestData = {
            id,
            expiryDate: isChanged(expiryDate, changedExpDate) ? expDate : undefined,
            approvedSessions: isChanged(approvedSessions, approved) ? approved : undefined,
            paymentSource: isChanged(paymentSource.name, selectedPaymentSource) ? selectedPaymentSource : undefined ,
            chargedSessions: isChanged(chargedSessions, changedChargedSessions) ? changedChargedSessions : undefined
        }

        if (dateCompare(changedExpDate, expiryDate) || approvedSessions < approved) {
            requestData.approvalStatus = false
        }

        await requestHandler(requestData, setLoading)
    }

    const requestToActivateHandler = async () => {
        try {
            setLoading(true)
            const { mutationRequest } = await requestToActivate(!requestIsActive, id)
            setRequest(mutationRequest)
        } catch (e) {
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
    }

    const selectHandler = (e) => setPaymentSource(e.target.value)

    const focusClick = () => isDirect ? approvedInput.current.focus() : chargedInput.current.focus()

    const showCheckbox = isICBC || (isDirect && !isDeactivated)
    const isBtnDisabled = (approved === approvedSessions 
        && generateAppDate(expiryDate) === changedExpDate 
        && paymentSource.name === selectedPaymentSource 
        && changedChargedSessions === chargedSessions)
        || loading
    
    const partiallyPaidLength = partiallyPaidIDs?.length
    const availablePaymentSources = paymentSources.filter(el => el.paymentSource.isActive || paymentSource.name === el.paymentSource.name)
    return (
        <section className="invoice-line-item-container">
            <article
                className={`invoice-line-item client-tale-item ${alert || ""}`}
            >
                <span className="flex-20 cell" style={{ flex: "20% 1" }}>{firstName} {lastName}</span>
                <span className="flex-20 cell payment-source" style={{ flex: "14% 1" }}>
                    <TextField
                        select
                        className="payment-source-select"
                        value={selectedPaymentSource}
                        onChange={selectHandler}
                        SelectProps={{
                            native: true,
                        }}
                        disabled={counsellorPage}
                        margin="normal"
                    >
                        {
                            availablePaymentSources.map(source => (
                                <option key={source.paymentSource.name} value={source.paymentSource.name}>
                                    {source.paymentSource.name}
                                </option>
                            ))
                        }

                    </TextField>
                </span>
                <span 
                    className="flex-20 cell focus-field" 
                    style={{ flex: "13% 1" }}
                    onClick={focusClick}
                >
                    <div className='table-input-wrapper'>
                    {
                        isDirect
                            ? <TextField
                                value={approved}
                                onChange={(e) => inputHandler(e, setApproved)}
                                className="table-input"
                                disabled={counsellorPage}
                                inputRef={(ref) => approvedInput.current = ref}
                              />
                            : <TextField
                                value={changedChargedSessions}
                                onChange={(e) => inputHandler(e, setChargedSessions)}
                                className="table-input"
                                style={{width: `${changedChargedSessions?.toString().length * 6.1}px`}}
                                disabled={counsellorPage}
                                inputRef={(ref) => chargedInput.current = ref}
                              />
                    }

                    {
                        !isDirect && partiallyPaidLength
                            ? <span className='part-paid-count'>({partiallyPaidLength})</span>
                            : ''
                    }
                    </div>
                    {
                        showCheckbox &&
                        <>
                            <Checkbox
                                color='default'
                                className='approved-checkbox'
                                checked={!!dateOfApprovalCheck}
                                onClick={checkboxHandler}
                                disabled={checkboxDisabled || counsellorPage}
                            />
                            {
                                dateOfApprovalCheck &&
                                <span className='approval-date'>
                                    ({generateAppDate(dateOfApprovalCheck)})
                                </span>

                            }
                        </>
                    }
                </span>
                <span className="flex-20 cell" style={{ flex: "8.5% 1" }}>{usedSessions}</span>
                {isDirect &&
                    <>
                        {!counsellorPage &&
                            <span className="flex-20 cell" style={{ flex: "5% 1" }}>{totalBilled}</span>
                        }
                        {
                            isICBC &&
                            <span className="flex-20 cell" style={{ flex: "10% 1" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        format="MMM dd, yyyy"
                                        value={changedExpDate}
                                        onChange={dateInputHandler}
                                        className="date-input"
                                        disabled={counsellorPage}
                                    />
                                </MuiPickersUtilsProvider>

                            </span>
                        }
                    </>
                }
                <span
                    className={`flex-20 cell buttons-col 
                        ${!isDirect ? "non-direct-btn" : ""}
                        ${counsellorPage ? "cousnellor-buttons" : ""}`
                    }
                >
                    {
                        counsellorPage
                            ? <RequestButton
                                isDeactivated={isDeactivated}
                                buttonHandler={requestToActivateHandler}
                                mutationRequest={requestIsActive}
                                loading={loading}
                            />
                            : <ClientButtons
                                isDeactivated={isDeactivated}
                                isDirect={isDirect}
                                disableSaveBtn={isBtnDisabled}
                                saveHandler={saveHandler}
                                activateHandler={activateHandler}
                                saveLoading={loading}
                                activateLoading={activateLoading}
                                alertLoading={alertLoading}
                                mutationRequest={mutationRequest}
                                client={client}
                                alertHandler={alertHandler}
                            />
                    }

                </span>
            </article>
        </section>
    );
};

export default TableItem;