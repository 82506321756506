/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-25 16:14:45
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-15 20:07:03
 */

import { Checkbox } from "material-ui";
import React from "react";
import styled from "styled-components";
import { generateAppDate } from "utils/utils";

const ListItem = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cdcdcd;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
    background: #c7c7c7 !important;
  }
`;

const StyledP = styled.p`
  display: block;
  text-align: left;
  font-weight: bold;
  margin: 0.3rem;
  margin-left: 20px
`;

const Date = styled(StyledP)`
  flex: 3;
`;

const CounsellorAppointmentItem = ({ clientAppointment, onClick, selectedItems, onChange}) => {
  const checked = !!selectedItems.find(el => el.id === clientAppointment.id)
  
  return (
    <ListItem
      onClick={onClick}
      style={{
        color: 'inherit',
        background:  'inherit',
      }}
    >
      <Checkbox 
        className="export-checkbox"
        checked={checked}
        onChange={(e) => onChange(e, clientAppointment, checked)}
      />
      <Date>
        {generateAppDate(clientAppointment.date)}
      </Date>
    </ListItem>
  );
};

export default CounsellorAppointmentItem;
