/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 19:22:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-09 21:41:02
 */


export const moduleName = "client"

export const pages = {
}

export const endpoints = {
  clients: "/clients",
  icbcClaimNumber: "/icbcClaimNumber"
}