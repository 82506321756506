/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:48 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-14 21:27:47
 */

import { moduleName } from "./constants"

export const search = `${moduleName}.search`
export const select = `${moduleName}.select`
