/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:30:23 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 12:43:20
 */
import { GET_APPOINTMENTS } from "../../service/query"
import { $authHost } from "service/indexApi"
import { requestDate } from "utils/utils"

export default class Service {
  search({loading, search, ...query}) {
    const {firstName, lastName, minDate, maxDate} = query

    const variables = {
      params: {
        firstName: firstName || null,
        lastName: lastName || null,
        maxDate: requestDate(maxDate),
        minDate: requestDate(minDate)
      }
    }
    return $authHost.post('/graphql', {
        query: GET_APPOINTMENTS,
        variables
      }).then(({data: {data: {appointments}}}) => appointments)
  }
}