import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import map from 'lodash/fp/map'
import { getBadFitDate, getIdByDate } from 'utils/utils';

export const badFitGrouped = (items) => {
    const mapElems = map.convert({ 'cap': false })
    
    const badFitGroupedList = flow(
        groupBy((el) => getBadFitDate(el.dateCreated)),
        mapElems((items, date) => ({date, items, id: getIdByDate(date)}))
    )(items)

    return badFitGroupedList.sort((a, b) => b.id - a.id) 
}