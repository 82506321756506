/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 19:11:50 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-07-27 14:30:01
 */


export const moduleName = "core"
export const imageAlt = "No Fear Counselling"

export const apiHost = process.env.REACT_APP_BASE_URL || "http://localhost:8000"
export const timeout = 30000

export const taxRate = process.env.REACT_APP_TAX_RATE || 0.05
export const insurerRate = process.env.REACT_APP_INSURER_RATE || 0.8333333333

export const icbcAccountNumber = process.env.REACT_APP_ICBC_ACCOUNT_NUMBER || '5017518'

export const defaultError = {
  type: "error",
  title: "Unknown Error",
  message: "An unhandled error has occurred, please refresh your page and try again"
}