/*
 * @Author: Razor Sharp Consulting 
 * @Date: 2019-04-09 12:59:34 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-05-17 15:09:37
 */

import React from "react"
import core from '../../core'
import layout from '../../layout'
import * as selectors from '../selectors'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Paper, Button } from "material-ui"
import { faFileImage } from "@fortawesome/fontawesome-free-solid"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { Checkbox } from "material-ui"
import * as actions from "../actions"
import ExcelExport from 'export-xlsx';
import { SETTINGS_FOR_EXPORT } from '../../../service/constants'
import '../invoice.css'

class ExportReport extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedInvoices: []
    }
  }
  calcTotals() {
    const { exportedInvoices } = this.props
    
    return exportedInvoices.reduce((current, { invoice: { totalTax, total } }) => {
      return {
        total: current.total + total,
        totalTax: current.totalTax + totalTax
      }
    }, { total: 0, totalTax: 0 })
  }

  export() {
    const { exportedInvoices } = this.props
    
    const dataForExport = []
    const excelExport = new ExcelExport();
    
    exportedInvoices.forEach(invoiceData => {
      const {invoiceNumber, invoiceDate, therapistName, invoice: {icbcClaimNumber, client, numberOfAppointments, total}} = invoiceData
      
      const invoiceForExport = {
        invoiceNumber: invoiceNumber,
        claimNumber: icbcClaimNumber,
        invoiceDate: invoiceDate,
        therapist: therapistName,
        client: client.fullName,
        numberOfAppointments: numberOfAppointments,
        invoiceTotal: `$${total.toFixed(2)}`
      }

      dataForExport.push(invoiceForExport)
    })
  
    excelExport.downloadExcel(SETTINGS_FOR_EXPORT, [{data: dataForExport}]);
  }

  onInvoiceClick(invoice, invoiceNumber, e) {
    const { onClick } = this.props
    const {totalRate , totalTax} = invoice
    e.preventDefault()

    onClick({...invoice, total: totalRate  + totalTax}, invoiceNumber)
  }

  checkboxHandler(invoiceNumber) {
    if (!this.state.selectedInvoices.includes(invoiceNumber)) {
      this.setState(state => ({
        selectedInvoices: [...state.selectedInvoices, invoiceNumber]
      }))
    } else {
      this.setState(state => ({
        selectedInvoices: state.selectedInvoices.filter(item => item !== invoiceNumber)
      }))
    }
  }
  
  deleteHandler() {
    const { exportedInvoices, dropItemsfromExported } = this.props
    let newExportedInvoices = exportedInvoices
    this.state.selectedInvoices.forEach(invoiceNumber => {
      newExportedInvoices = newExportedInvoices.filter(el => el.invoiceNumber !== invoiceNumber)
    })

    dropItemsfromExported(newExportedInvoices)
    this.setState({selectedInvoices: []})
  }

  renderInvoices() {
    const { exportedInvoices } = this.props
    
    return exportedInvoices
      .sort((a, b) => b.invoiceNumber - a.invoiceNumber)
      .map(({ invoice, invoiceDate, invoiceNumber, therapistName}, i) => (
        <article key={`${i}-${invoiceNumber}-${invoice.client.firstName}`} className="invoice-line-item">
          <Checkbox className="export-checkbox" checked={this.state.selectedInvoices.includes(invoiceNumber)} onChange={() => this.checkboxHandler(invoiceNumber)}/>
          <span className="flex-12-5"><a href="#export" onClick={this.onInvoiceClick.bind(this, invoice, invoiceNumber)}>{invoiceNumber}</a></span>
          <span className={`flex-12-5 ${!invoice.icbcClaimNumber && invoice.paymentSource === "ICBC" && 'empty'}`}>{invoice.icbcClaimNumber}</span>
          <span className="flex-12-5">{invoiceDate}</span>
          <span className="flex-25">{therapistName}</span>
          <span className="flex-25">{invoice.client.fullName}</span>
          <span className="flex-25">{invoice.numberOfAppointments}</span>
          <span className="flex-12-5 text-right">${invoice.total.toFixed(2)}</span>
        </article >
      ))
  }

  render() {
    const { exportedInvoices } = this.props
    return (
      <layout.components.FullWidthRow>
        <layout.components.Container>
          {
            exportedInvoices.length > 0 &&
            <div className="invoice__toolbar">
            <Button 
              onClick={this.export.bind(this)} 
              color="secondary" 
              variant="raised"
            >
              <FontAwesomeIcon icon={faFileImage} />&nbsp; Export
            </Button>
          </div>}
          {
            !exportedInvoices.length &&
            <core.components.NoItems />
          }

          {
            exportedInvoices.length > 0 &&
            <Paper elevation={8} className="invoice__container">
              <div>

                <h2>Exported Invoices</h2>
                <section className="invoice__line-items">
                  <section>
                    <article className="invoice-line-item header">
                      <span className="flex-12-5">Invoice Number</span>
                      <span className="flex-12-5">Claim number</span>
                      <span className="flex-12-5">Invoice Date</span>
                      <span className="flex-25">Therapist</span>
                      <span className="flex-25">Client</span>
                      <span className="flex-25">Number of Appointments</span>
                      <span className="flex-12-5 text-right">Invoice Total</span>
                    </article>
                  </section>
                  <section>
                    {this.renderInvoices()}
                  </section>
                  <Button 
                    onClick={this.deleteHandler.bind(this)} 
                    color="secondary" 
                    variant="raised" 
                    className="delete-button" 
                    disabled={!this.state.selectedInvoices.length}
                  >
                    Delete
                  </Button>
                </section>
              </div>
            </Paper>
          }

        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

const stateToProps = (state) => ({
  exportedInvoices: selectors.getExportedInvoices(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(ExportReport)