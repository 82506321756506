import { Button } from 'material-ui';
import React from 'react';
import './email-message.scss'

const EmailMessage = ({setTab}) => {
    return (
        <div className="email-message">
            <p>
                We've emailed your password to the
                email you entered. You should receive it shortly.<br/>
                If you don't receive an email please check your spam folder.
            </p>
            <Button
                color="secondary" 
                variant="raised" 
                className="submit-button"
                onClick={setTab}
            >
                Next
            </Button>
        </div>
    );
};

export default EmailMessage;