/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 22:49:42 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-08-23 16:37:33
 */
import React, { useState } from "react"
import core from "../../core"
import { updateAppointmentRate } from "../../appointment/actions"
import { addOutstandingItem } from "../outstanding/actions"
import moment from "moment"
import { useDispatch } from "react-redux"

import { NON_DIRECT, OUTSTANDING } from "service/constants"
import PaymentStatus from "../../../ui-components/PaymentStatus"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import { InputAdornment, TextField } from "material-ui"
import { faDollarSign } from "@fortawesome/fontawesome-free-solid"
import model from "../../../model"
import {Checkbox} from 'material-ui'

const LineItem = ({appointment, onChange, deleteAppointment, showClient, showCounsellorRate, counsellor}) => {
  const {date, clientRate, counsellorRate, GST, amountPaid, price, paid } = appointment
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  
  const updateClientRate = (e) => {
    const { external } = counsellor
    let counsellorRate
    const clientRate = +e.target.value
    const GST = clientRate * 0.05
    const share = counsellor.share / 100
    
    if (appointment.sessionLocation.includes("NFC")) {
      counsellorRate = clientRate * share
    } else {
      if (clientRate <= 80) {
        counsellorRate = clientRate * (share + external / 100) 
      } else {
        counsellorRate = clientRate * share + external
      }
    }
    
    dispatch(updateAppointmentRate(appointment.id, clientRate, counsellorRate, GST))
  }

  const addOutstanding = () => {
    dispatch(addOutstandingItem(appointment))
    deleteAppointment(appointment.id)
  }
  
  const linePrice = clientRate + GST
  const isFullPaid = paid === "yes" && (amountPaid >= price || amountPaid === 0)
  const isPartPaid = paid === "yes" && amountPaid < price
  const isNonDirectBill = appointment.paymentSource === NON_DIRECT

  return (
    <section className="invoice-line-item-container">
      {
        showCounsellorRate
          ? <Checkbox 
              checked={false}
              className="counsellor-checkbox"
              onClick={addOutstanding}
              disabled={loading}
            /> 
          : null
      }
      
      <article className="invoice-line-item">
        <span className={`flex-50 ${!showCounsellorRate ? "client-invoice-item" : ""}`}>
          {appointment.type}
          {
            showCounsellorRate && isFullPaid && isNonDirectBill 
            ? <PaymentStatus
                isFullPaid={true}
              />
            : null
          }
          {
            showCounsellorRate && isPartPaid && isNonDirectBill && amountPaid !== 0 
            ? <PaymentStatus
                isFullPaid={false}
              />
            : null
          }
          { showClient && ` (${appointment.firstName} ${appointment.lastName})` }
        </span>
        <span className="flex-12-5">{moment(date).format("MMM DD, YYYY")}</span>
        <span className="flex-12-5 text-right">
          ${clientRate?.toFixed(2)}
          {
            isPartPaid && isNonDirectBill && amountPaid !== 0 && showCounsellorRate &&
              ` ($${(amountPaid / 1.05).toFixed(2)})`
          }
          {
            appointment.paymentSource === "CVAP" &&  amountPaid !== 0 && showCounsellorRate &&
              ` (+$${(amountPaid / 1.05).toFixed(2)})`
          }
          </span>
        { showCounsellorRate && <span className="flex-12-5 text-right">${counsellorRate.toFixed(2)}</span> }
        <span className="flex-12-5 text-right">${GST?.toFixed(2)}</span>
        <span className="flex-12-5 text-right">${linePrice.toFixed(2)}</span>
      </article>
      <div className="hover__menu">
        <h2 className="flex-37-5 max-37-5">{appointment.type} { showClient && ` (${appointment.firstName} ${appointment.lastName})` }</h2>
        <h2>{moment(date).format("MMM DD, YYYY")}</h2>
        <TextField 
          type="number" 
          name="price" 
          value={clientRate || ""} 
          placeholder="Price" 
          onChange={onChange || updateClientRate}
          InputProps={{
            startAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faDollarSign} /> &nbsp;</InputAdornment>
          }}
        />
        <h2><FontAwesomeIcon icon={faDollarSign} /> &nbsp;{GST.toFixed(2)}</h2>
        <h2><FontAwesomeIcon icon={faDollarSign} /> &nbsp;{linePrice.toFixed(2)}</h2>
        <core.components.DeleteButton onClick={() => deleteAppointment(appointment.id)} color="primary"/>
      </div>
    </section>
  )
}

LineItem.propTypes = {
  appointment: model.AppointmentShape,
  deleteAppointment: PropTypes.func,
  onChange: PropTypes.func
}

export default LineItem