/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 19:22:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-14 21:25:31
 */


export const moduleName = "calendar"

export const pages = {
}

export const endpoints = {
  calendars: "/calendars",
}