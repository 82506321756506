import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBadFitItems } from '../redux/actions';
import SectionHead from 'ui-components/SectionHead';
import BadFitTable from './components/bad-fit-table/BadFitTable';
import { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from 'material-ui';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/fontawesome-free-solid"
import Spinner from 'ui-components/Spinner';

import './bad-fit.scss'

const BadFit = () => {
    const dispatch = useDispatch()
    const [badFitLoading, setBadFitLoading] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const badFitItems = useSelector(state => state.admin.badFitItems)

    const expandHandler = async () => {
        if (!expanded && !badFitItems) {
            setBadFitLoading(true)
            await dispatch(fetchBadFitItems())
            setBadFitLoading(false)
        }
        
        setExpanded(!expanded)
    }
    return (
        <div className='bad-fit'>
            <ExpansionPanel 
                    expanded={expanded} 
                    onChange={expandHandler}
                    className="bad-fit-panel"
                >
                    <ExpansionPanelSummary 
                        expandIcon={<FontAwesomeIcon icon={faChevronDown} className="expand-img"/> }
                        className="bad-fit-summary"
                    >
                        <SectionHead head={"Bad Fit"}/>
                        {badFitLoading && <Spinner className={"bad-fit-spinner"}/>}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="deactivated-details">
                        <BadFitTable/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>      
        </div>
    );
};

export default BadFit;