/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 19:15:52 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 13:44:18
 */

import * as actions from "./actions"
import components from "./components"
import { reducer } from "./reducer"
import * as selectors from "./selectors"


export default {
  actions,
  components,
  reducer,
  selectors,
}