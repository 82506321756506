import {ADD_FUTURE_PAID_ITEM, REMOVE_FUTURE_PAID_ITEM, ADD_FUTURE_PAID_ITEMS, FETCH_FUTURE_PAID_ITEMS} from "./actionTypes"

export const addFuturePaidItem = (item) => ({
  type: ADD_FUTURE_PAID_ITEM,
  item
})

export const fetchFuturePaidItems = (items) => ({
  type: FETCH_FUTURE_PAID_ITEMS,
  items
})

export const addFuturePaidItems = (items) => ({
  type: ADD_FUTURE_PAID_ITEMS,
  items
})

export const removeFuturePaidItem = (id) => ({
  type: REMOVE_FUTURE_PAID_ITEM,
  id
})