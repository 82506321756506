export const invalidPhoneMessage = "Please enter a valid phone number"
export const differentPhonesMessage = "Phones do not match"

export const requiredMessage = "This field is required"

export const invalidPasswordMessage = "Your password should contain at least one numeric, one lowercase and one uppercase letter and should be at least 8 characters long"
export const differentPasswordsMessage = "Passwords do not match"

export const invalidEmailMessage = "Please enter a valid email address"

export const notAuthedMessage = "User is not authenticated"

export const phoneSuccessMessage = {
    title: "Phone updated successfully",
    message: "Success",
    type: "success"
}

export const passwordSuccessMessage = {
    title: "Password updated successfully",
    message: "Success",
    type: "success"
}

export const failedMessage = {
    title: "Something wrong",
    message: "Please try again later",
    type: "error"
}

export const emailFailed = {
    message: "This email is not registered in Acuity. Please enter your Acuity login or contact your NFC Admin for more details",
    title: "Email failed",
    type: "error"
}

export const loginSuccessMessage = {
    title: "Login Success",
    message: "Success",
    type: "success",
}

export const loginFailedMessage = {
    title: "Login Failed",
    message: "Login failed, please try again",
    type: "error",
}

export const registrationFailedMessage = {
    title: "Registration Failed",
    message: "Registration failed, please try again",
    type: "error",
}

export const registrationSuccessMessage = {
    title: "Registration Success",
    message: "Success",
    type: "success",
}

export const emailSuccessMessage = {
    title: "Email sended successfully",
    message: "Success",
    type: "success",
}

export const passwordSendMessage = {
    title: "We've emailed your password to the email you entered.",
    message: "Success",
    type: "success",
}

export const zipSuccessMessage = {
    title: "We've emailed zip file to the admin's email.",
    type: "success",
}
