import React from 'react';
import { useSelector } from 'react-redux';
import FooterListItem from './FooterListItem';

const FuturePaidList = ({addFutureMiscItem, futurePaidItems, homepage}) => {
    // const futurePaidList = useSelector(state => state.futurePaidList)
    
    return (
        <div className='future-paid-list'>
            <h1>Future paid list</h1>
            <section className="invoice-line-item-container">
                <article className="invoice-line-item header">
                    <span className="flex-20">Client</span>
                    <span className="flex-20">Appointment type</span>
                    <span className="flex-20" style={{flex: "12% 2"}}>Date</span>
                    <span className="flex-20" style={{flex: "10% 2"}}>Counsellor rate</span>
                    <span className="flex-20" style={{flex: "40% 2"}}>Comment</span>
                </article>
            </section>
           {
               futurePaidItems.map(item => (
                    <FooterListItem 
                        homepage={homepage}
                        item={item}
                        addFutureMiscItem={addFutureMiscItem}
                        key={item.id}
                    />
               ))
           }
        </div>
    );
};

export default FuturePaidList;