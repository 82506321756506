/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:17 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 12:22:33
 */

import core from "../core"
import messages from "../messages"
import model from "../../model"
import Service from "./service"

import * as actionTypes from "./actionTypes"
import * as errorTypes from "./errorTypes"

export const search = (search = model.Search(), authToken = undefined, cancelToken = undefined, service = new Service()) => (dispatch) => {
  return service.search(search, authToken, cancelToken)
    .then(
      (data) => dispatch(onSearchSuccess(data)),
      (error) => {
        if (error) {
          dispatch(messages.actions.showMessage(core.utils.handleError(error, errorTypes.search)))
        }
      }
    )
}

export const select = (calendar = model.Calendar()) => ({
  type: actionTypes.select,
  calendar
})

export const onSearchSuccess = (calendars = model.store()) => ({
  type: actionTypes.search,
  calendars: calendars
})
