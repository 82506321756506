import React from 'react';

import SectionHead from 'ui-components/SectionHead';
import TableItem from './components/table-item/TableItem';
import checkboxImg from 'assets/img/checkbox.svg'

import './client-table.scss';

const ClientsTable = ({tableHead, clientsData, isDirect, isICBC, isDeactivated, counsellorPage, counsellorId}) => {
    const showICBCLegend = isDirect && isICBC && !isDeactivated
    const showNonDirectLegend = !isDirect && !isDeactivated
   
    return (
        <div className={`clients-table ${!isICBC ? "non-icbc": ''} ${!isDirect ? "non-direct" : ""} ${counsellorPage ? "cousnellor-page-table" : ""}`}>
            <div className='table-head-wrapper'>
                {
                    !isDeactivated &&
                        <SectionHead head={tableHead} className={"table-head"}/>
                }
                {
                    showICBCLegend &&
                    <div className='legends'>
                        <span>Legend:</span>
                        <div className='legend-item'>
                            <img src={checkboxImg} alt=""/>
                            <span>Paperwork submitted (date)</span>
                        </div>
                    </div>
                }
                {
                    showNonDirectLegend &&
                    <div className='legends non-direct'>
                        <span>Legend:</span>
                        <div className='legend-item'>
                            <span>(2) number of sessions as partial charges   </span>
                        </div>
                    </div>
                }
            </div>

            <section 
                className={`invoice-line-item-container counsellor-table ${!isICBC ? "non-icbc": ''} ${!isDirect ? "non-direct" : ""}`}
            >
                <article className="invoice-line-item admin-table header">
                    <span className="flex-20" style={{flex: "20% 1"}}>Client Name</span>
                    <span className="flex-20" style={{flex: "14% 1"}}>Payment Source</span>
                    <span className="flex-20" style={{flex: "13% 1"}}>{isDirect ? "Approved Sessions" : "Charged sessions"}</span>
                    <span className="flex-20" style={{flex: "8.5% 1"}}>Used Sessions</span>
                    {
                        isDirect &&
                        <>
                            {   
                                ! counsellorPage &&
                                    <span className="flex-20" style={{flex: "5% 1"}}>Billed</span>
                            }
                            {
                                isICBC &&
                                    <span className="flex-20" style={{flex: "10% 1"}}>Expiry Date</span>
                            }
                        </>
                    }

                    <span 
                        className={`flex-20 buttons-col ${!isDirect ? "non-direct-btn" : ""} ${counsellorPage ? "cousnellor-buttons" : ""}`} 
                    ></span>
                </article>
                {
                    clientsData.map(client => (
                        <TableItem
                            key={client.id}
                            client={client}
                            isDirect={isDirect}
                            isICBC={isICBC}
                            isDeactivated={isDeactivated}
                            counsellorPage={counsellorPage}
                            counsellorId={counsellorId} 
                        />
                    ))
                }
            </section>
        </div>
    );
};

export default ClientsTable;