/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:43:44 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-24 22:52:28
 */

 import React from "react";
import { connect } from "react-redux"


import model from "../../../model"
import * as selectors from "../selectors"

const withAuth = (WrappedComponent) => ((props) => {
  return ( 
    <WrappedComponent {...props} /> 
  )
})

withAuth.propTypes = {
  auth: model.Auth.Shape
}

const stateToProps = (state) => ({
  auth: selectors.getAuth(state)
})

export default connect(stateToProps)(withAuth);