import React from 'react';
import LegendItem from './LegendItem';

import './legends.scss'

const LegendsList = ({alerts}) => {
    return (
        <div className='legends-wrapper'>
            {
                alerts.map(alert => (
                        <LegendItem 
                            type={alert}
                            key={alert}
                        />
                    )
                )
            }
        </div>
    );
};

export default LegendsList;