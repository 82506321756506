/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 19:04:30 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-04-14 00:46:07
 */

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, withRouter } from 'react-router-dom'
import AppBar from "material-ui/AppBar"
import { Toolbar } from "material-ui"
import Typography from "material-ui/Typography"
import Button from "material-ui/Button"
import { useHistory } from "react-router-dom";

import auth from '../../auth'
import Container from "./Container"
import Separator from "./Separator"
import ChangePasswordPopUp from "modules/auth/change-password/ChangePasswordPopUp"
import ChangePhonePopUp from "modules/auth/change-phone/ChangePhonePopUp"
import { CSSTransition } from "react-transition-group"
import { ADMIN_ROUTE, COUNSELLOR_PAGE_ROURE, COUNSELLOR_PAY_ROUTE, INVOICING_MANAGMENT_ROURE, RATES } from "service/constants"

import '../layout.css'

const LinkButton = ({path, name}) => {
  const history = useHistory();
  const location = useLocation()

  const linkHandler = () => {
    history.push(path)
  } 
  
  return (
    <Button 
      className={location.pathname === path ? "active" : null}
      onClick={linkHandler}
      style={{textTransform: "none"}}
    >
      {name}
    </Button>
  )
}

const TopNav = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const authUser = useSelector(state => state.auth.profile)
  const isAdmin = useSelector(state => state.auth.profile?.isAdmin)
  const showPopUp = useSelector(state => state.auth.showChangeEmailPopUp)
  const showChangePhonePopUp = useSelector(state => state.auth.showChangePhonePopUp)
  
  const logout = () => {
    history.push(INVOICING_MANAGMENT_ROURE)
    localStorage.removeItem("token")
    dispatch(auth.actions.logout())
  }

  const redirectToRates = () => history.push(RATES)
  const showPopUpHandler = () => dispatch(auth.actions.showPopUp()) 
  const showPhonePopUpHandler = () => dispatch(auth.actions.showChangePhonePopUp()) 
  
  return (
    <AppBar color={"default"} position={"sticky"} className="layout__toolbar">
      <Toolbar>
        <Container className="flex">
          {
            authUser && !isAdmin &&
              <LinkButton
                name="Counsellor Homepage"
                path={COUNSELLOR_PAGE_ROURE}
              />
          }
          {
            isAdmin &&
              <LinkButton
                name="Admin Homepage"
                path={ADMIN_ROUTE}
              />
          }

          {
            authUser && 
              <LinkButton
                name={isAdmin ? "Invoicing Management" : "Receipt Management"}
                path={INVOICING_MANAGMENT_ROURE}
              />
          }

          {
            isAdmin &&
              <LinkButton
                name="Counsellor Pay"
                path={COUNSELLOR_PAY_ROUTE}
              />
          }
          <Separator />
          {
            authUser &&
            <div className="settings-btns">
              <p className="settings-btn">Settings</p>
              <Button onClick={showPopUpHandler}>Change password</Button>
              { 
                !isAdmin && 
                  <Button onClick={showPhonePopUpHandler}>Change phone</Button>
              }
              { 
                isAdmin && 
                  <Button onClick={redirectToRates}>Appointment rates</Button>
              }
              <Button onClick={logout}>Logout</Button>
            </div>
          }
          
         
          {
            authUser && 
              <Typography
                component={'span'} 
                key={'topNav-1'}
                className="welcome-message"
              >
                Welcome {authUser?.name}
              </Typography>
          }
          
          <CSSTransition
              in={showPopUp}
              timeout={500}
              classNames="alert"
              unmountOnExit
            >
              <ChangePasswordPopUp/>
          </CSSTransition>

          <CSSTransition
            in={showChangePhonePopUp}
            timeout={500}
            classNames="alert"
            unmountOnExit
          >
            <ChangePhonePopUp/>
          </CSSTransition>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(TopNav)