import React from 'react';
import { Checkbox } from 'material-ui';
import { useDispatch } from 'react-redux';
import { removeOutstandingItem } from '../outstanding/actions';
import {addAppointment} from '../../appointment/actions'
import { generateAppDate } from '../../../utils/utils';
import { directBillComment, DIRECT_TYPE, NON_DIRECT_TYPE, notDirectBillComment, NO_APPROVAL_STATUS, NO_PAYMENT_STATUS, NON_DIRECT} from '../../../service/constants'
import circle from 'assets/img/circle.svg'
import polig from 'assets/img/polig.svg'
import { removeFuturePaidItem } from '../future-paid/actions';

const LegendItem = ({itemStatus}) => {
    const status = itemStatus?.toLowerCase()
    if(status === NO_APPROVAL_STATUS.toLowerCase()) {
        return <img className='legend-img' src={circle} alt="no-approval"/>
    } else if (status === NO_PAYMENT_STATUS.toLowerCase()) {
        return <img className='legend-img' src={polig} alt="no-payment"/>
    } else {
        return ""
    }
}

const FooterListItem = ({item, isOutstanding, addFutureMiscItem, homepage}) => {
    const dispatch = useDispatch()
    const {id, firstName, lastName, date, counsellorRate, status, client } = item
  
    const removeItem = () => {
        dispatch(addAppointment(item))
        dispatch(removeOutstandingItem(id))
    }

    const futurePaidCheckboxHandler = () => {
        dispatch(removeFuturePaidItem(id))
        addFutureMiscItem(item)
    }

    const isDirectBill = client.paymentSource.profile !== NON_DIRECT
    return (
        <section className="invoice-line-item-container"> 
        {
            !homepage 
                ? <Checkbox 
                    checked={true}
                    onClick={isOutstanding ? removeItem : futurePaidCheckboxHandler}
                    className="counsellor-checkbox"
                  /> 
                : null
        }
                
            
            <article className="invoice-line-item outstanding">
                <span className="flex-20 outstanding-name">
                    {`${firstName} ${lastName}`}
                    <LegendItem itemStatus={status}/>
                </span>
                <span className="flex-20">{isDirectBill ? DIRECT_TYPE : NON_DIRECT_TYPE}</span>
                <span className="flex-20" style={{flex: "12% 2"}}>{generateAppDate(date)}</span>
                <span className="flex-20" style={{flex: "10% 2"}}>${counsellorRate}</span>
                <span className="flex-20" style={{flex: "40% 2"}}>{isDirectBill ? directBillComment : notDirectBillComment}</span>
            </article>
        </section>
    );
};

export default FooterListItem;