import React from 'react';
import submitedImg from 'assets/img/circle-subm.svg'
import noApprovalImg from 'assets/img/red-circle.svg'
import noPaymentImg from 'assets/img/red.svg'
import partialImg from 'assets/img/orange.svg'
import requestImg from 'assets/img/blue-rectangle.svg'

import './legend.scss'

const Legend = () => {
    return (
        <div className='counsellor-legends'>
            <span>Legend:</span>
            <div className='legend-item'>
                <img src={submitedImg} alt="submited"/>
                <span>Submited</span>
            </div>
            <div className='legend-item'>
                <img src={noApprovalImg} alt="submited"/>
                <span>No approval</span>
            </div>
            <div className='legend-item'>
                <img src={noPaymentImg} alt="submited"/>
                <span>No payment</span>
            </div>
            <div className='legend-item'>
                <img src={partialImg} alt="submited"/>
                <span>Partial charge</span>
            </div>
            <div className='legend-item'>
                <img src={requestImg} alt="submited"/>
                <span>Request to activate/ deactivate</span>
            </div>
        </div>
    );
};

export default Legend;