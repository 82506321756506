/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 16:14:45 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-15 19:25:25
 */

import { Checkbox, Paper } from "material-ui"
import { faBuilding, faCalendar, faClock, faFile, faInbox, faMoneyBillAlt, faPhone, faUserMd } from "@fortawesome/fontawesome-free-solid"

import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import avatar from "../../../assets/img/avatar-patient.png"
import model from "../../../model"

const ListItem = ({data, onChange}) => (
  
  <Paper elevation={4}>
    <article className="appointment__list-item" onClick={() => {onChange({ target: { checked: !data.selected } })}}>
      <Checkbox checked={data.selected} />
      <img src={avatar} alt={"profile"} />
      <h1 className="flex-25 text-left">{data.firstName} {data.lastName}</h1>
      <ul>
        <li><FontAwesomeIcon icon={faUserMd} /> {data.counsellor}</li>
        <li><FontAwesomeIcon icon={faCalendar} /> {data.date}</li>
        <li><FontAwesomeIcon icon={faClock} /> {data.time}</li>
        { 
          data.claimNumber && 
            <li><FontAwesomeIcon icon={faFile} /> {data.claimNumber}</li>
        }
        { 
          data.paymentSource && 
            <li><FontAwesomeIcon icon={faMoneyBillAlt} /> {data.paymentSource}</li>
        }
        <li><FontAwesomeIcon icon={faPhone} /> {data.phone}</li>
        <li><FontAwesomeIcon icon={faInbox} /> {data.email}</li>
        <li><FontAwesomeIcon icon={faBuilding} /> {data.type}</li>
      </ul>
    </article>
  </Paper>
)

ListItem.propTypes = {
  data: model.AppointmentShape,
  onChange: PropTypes.func
}

export default ListItem