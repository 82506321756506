/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:48 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 13:33:06
 */

import { moduleName } from "./constants"

export const generateInvoice = `${moduleName}.generateInvoice`
export const updateAppointmentPrice = `${moduleName}.updateAppointmentPrice`
export const deleteAppointment = `${moduleName}.deleteAppointment`
export const exportInvoices = `${moduleName}.exportInvoices`
export const dropItemsfromExported = `${moduleName}.dropItemsfromExported`
export const setInvoiceNumber = `${moduleName}.setInvoiceNumber`
export const changeClaimNumber = `${moduleName}.changeClaimNumber`