/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:41:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 15:07:05
 */

import _ from "lodash"
import { getIdByDate } from "utils/utils"

import model from "../../model"
import * as actionTypes from "./actionTypes"

export const reducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.generateInvoice: {
      action.invoice.appointments.sort((a, b) => getIdByDate(a.date) - getIdByDate(b.date))
      return action.invoice
    }
     

    case actionTypes.updateAppointmentPrice:
      var idx = _.findIndex(state.appointments, { id: action.data.id })
      var newAppointment = model.Appointment(state.appointments[idx])
      newAppointment.clientRate = action.data.price
      newAppointment.GST = action.data.price * 0.05
      var newAppointments = [
        ...state.appointments.slice(0, idx),
        newAppointment,
        ...state.appointments.slice(idx + 1)
      ]
      return model.ClientAppointment(newAppointments)

    case actionTypes.changeClaimNumber: {
      return {...state, client: {...state.client, claimNumber: action.claimNumber}}
    }

    case actionTypes.deleteAppointment:
      var delIdx = _.findIndex(state.appointments, { id: action.id })
      var delAppointments = [
        ...state.appointments.slice(0, delIdx),
        ...state.appointments.slice(delIdx + 1)
      ]
      return model.ClientAppointment(delAppointments)

    default:
      return state
  }
}

export const exportedInvoices = (state = [], action) => {
  if (!action) {
    return state
  }
  let idx;
  switch (action.type) {
    //Clear the session when the user logs in
    case 'auth.login':
      return []

    case actionTypes.exportInvoices:
      return [...state, ...action.data]
  
    case actionTypes.dropItemsfromExported:  
    return [...action.data]

    default:
      return state
  }
}