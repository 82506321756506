/*
 * @Author: Mike Cottingham 
 * @Date: 2018-05-18 11:27:08 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-18 15:55:41
 */

import React from "react"
import { getIdByDate, isReport as showType} from "../../../utils/utils"

import PaymentStatus from "../../../ui-components/PaymentStatus"
import LineItem from "./LineItem"
import { Button } from "material-ui"
import { NON_DIRECT } from "service/constants"

class CounsellorLineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuVisible: false 
    }
    this.onClick = this.onClick.bind(this)
  }
  
  onClick(e) {
    e.preventDefault()
    this.setState({menuVisible: !this.state.menuVisible})
  }

  render() {
    const { data, deleteAppointment, counsellor } = this.props
    
    const menuItems = this.state.menuVisible ?
      data.appointments.sort((a,b) => getIdByDate(b.date) - getIdByDate(a.date)).map(appointment => {
        return <LineItem 
          appointment={appointment} 
          key={`counsellor-menu-${appointment.id}`} 
          deleteAppointment={deleteAppointment}
          showCounsellorRate={true}
          counsellor={counsellor}
        />
      }) : []


    const style = {
      background: "#efefef",
      padding: "1rem",
      margin: "1rem 0",
      textAlign: "right"
    }

    const hiddenStyle = {
      cursor: "pointer"
    }
   
    const isNonDirectBill = data.paymentSource.profile === NON_DIRECT
    const appointmentType = data.appointments[0]?.type
    const reportType = appointmentType && showType(appointmentType)
    return (
      <section className="invoice-line-item-container" style={this.state.menuVisible ? style : hiddenStyle}>
        {this.state.menuVisible &&  <Button variant="raised" color="secondary" onClick={this.onClick} className="close-button">Close</Button> }
        <article className={`invoice-counsellor__menu ${this.state.menuVisible ? "active" : ""}`}>
          <section>
            <article className="invoice-line-item header">
              <span className="flex-50">Description</span>
              <span className="flex-12-5">Date</span>
              <span className="flex-12-5 text-right">Rate</span>
              <span className="flex-12-5 text-right">Counsellor Rate</span>
              <span className="flex-12-5 text-right">GST</span>
              <span className="flex-12-5 text-right">Total</span>
            </article>
          </section>
          {menuItems}
        </article>
        <article className="invoice-line-item" onClick={this.onClick} style={hiddenStyle}>
          <span className="flex-20">
            {data.client.firstName} {data.client.lastName}
            {
              isNonDirectBill &&
                <div className="payment-statuses-wrapper">
                  { 
                    data.paidAppCount ?
                      <PaymentStatus
                        paidCount={data.paidAppCount}
                        isFullPaid={true}
                      />
                      : null
                  }
                  { 
                    data.partPaidAppCount ?
                      <PaymentStatus
                        paidCount={data.partPaidAppCount}
                        isFullPaid={false}
                      />
                      : null
                  }
                </div>
            }
          </span>
          <span className="flex-7">{data.appointments.length}</span>
          <span className="flex-20">
            ${data.clientRates}
            {
              isNonDirectBill && data.paids && data.partPaidAppCount &&
              ` ($${data.paids})`
            }
            {
              data.paymentSource.name.toLowerCase().includes("cvap") && data.paids &&
              ` (+$${data.paids})`
            }
          </span>
          <span className="flex-20">${data.rates}</span>
          <span className="flex-7">${data.totalRate.toFixed(2)}</span>
          <span className="flex-20 max-20">{reportType ? reportType : data.paymentSource.name}</span>
        </article>        
      </section>
    )
  }
}

export default CounsellorLineItem