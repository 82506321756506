import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAdminCounsellorClients } from 'modules/admin/redux/actions';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { ExpansionPanelSummary, ExpansionPanel, Typography, ExpansionPanelDetails } from 'material-ui';
import { faChevronDown } from "@fortawesome/fontawesome-free-solid"
import styled from 'styled-components'
import Container from 'modules/layout/components/Container';
import CounsellorExpanded from '../counsellor-expanded/CounsellorExpanded';
import LegendsList from './legend/LegendsList';
import Spinner from 'ui-components/Spinner';
import "./counsellor.scss"

const Details = styled(ExpansionPanelDetails)`
  padding: 0 0 1rem 0;
`

const Summary = styled(ExpansionPanelSummary)`
  padding: 0 1rem;
  margin: 0 !important;
`

const CounsellorItem = ({counsellor}) => {
    const [expanded, setExpanded] = useState(false)
    const [loading, setCounsellorLoading] = useState(false)
    const dispatch = useDispatch()

    const {name, alerts, acuityID, clients} = counsellor

    const expandHandler = async () => {
      if (!expanded && !clients) {
          setCounsellorLoading(true)
          await dispatch(fetchAdminCounsellorClients(acuityID))
          setCounsellorLoading(false)
      }
      
      setExpanded(!expanded)
  }

    return (
        <ExpansionPanel 
            expanded={expanded} 
            onChange={expandHandler}
            className="counsellor-item"
        >
            <Summary expandIcon={<FontAwesomeIcon icon={faChevronDown} /> }>
              <Typography className="counsellor-collapsed" component={'span'}>
                {name}
                {loading && <Spinner className={"counsellor-spinner"}/>}
                <LegendsList alerts={alerts}/>
              </Typography>
            </Summary>
            <Details>
              <Container>
                  {
                    clients &&  
                    <CounsellorExpanded
                      clients={clients}
                      counsellorId={acuityID}
                    />
                  }
                  

                
              </Container>
            </Details>
          </ExpansionPanel>
    );
};

export default CounsellorItem;