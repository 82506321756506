/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-04-08 23:50:17
 */

import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Paper } from "material-ui"
import _ from "lodash"

import appointment from "../appointment"
import auth from "../auth"
import calendar from "../calendar"
import core from "../core"
import invoice from "../invoice"
import layout from "../layout"
import components from "./components"
import model from "../../model"
import moment from "moment"
import Logo from "../../assets/img/logo.png"
import { getCounsellorInvoice } from "service/api"


class CounsellorInvoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      ...this.generateState()
    }
    this.onCounsellorClick = this.onCounsellorClick.bind(this)
    this.onChange = core.utils.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
  }
  
  generateState() {
    let today = new Date()
    let minDate, maxDate
    
    if (today.getDate() >= 14) {
      minDate = `${moment(today).subtract(1, 'months').format("YYYY-MM")}-15`
      maxDate = `${moment(today).format("YYYY-MM")}-14`
    } else {
      minDate = `${moment(today).subtract(2, 'months').format("YYYY-MM")}-15`
      maxDate = `${moment(today).subtract(1, 'months').format("YYYY-MM")}-14`
    }
    
    return {
      loading: true,
      max: 150,
      minDate,
      maxDate,
      search: ""
    }
  }

  componentDidMount() {
    const { authToken, search } = this.props
    
    search({...this.state}, authToken)
      .then(
        () => this.setState({ loading: false })
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.minDate !== prevState.minDate || this.state.maxDate !== prevState.maxDate) {
      const { authToken, search } = this.props
      
      this.setState({ loading: true })
      search({...this.state}, authToken)
      .then(
        () => this.setState({ loading: false })
      )
    }
  }

  async onCounsellorClick(counsellor, e) {
    const { select, selectCounsellor } = this.props
    try{
      this.setState({loading: true})

      const {misc, future, default: defaultAppointments, outstanding} = await getCounsellorInvoice(counsellor.acuityID, this.state.maxDate, this.state.minDate)
      
      counsellor.misc = misc
      counsellor.future = future
      counsellor.appointments = defaultAppointments
      counsellor.outstanding = outstanding
      select(counsellor)
    } catch(e) {
      console.log(e)
    } finally {
      this.setState({loading: false})
    }
    
    selectCounsellor([...counsellor.appointments])
  }

  onReset(e) {
    const { search, authToken } = this.props
    this.setState({loading: true})
    
    search({...this.state}, authToken)
      .then(
        () => this.setState({ loading: false })
      )
  }

  render() {
    const { calendars } = this.props
    const search = this.state.search.toLowerCase()
    const displayCalendars = Array.isArray(calendars) && 
            this.state.search !== "" 
              ? calendars.filter(calendar => calendar.name.toLowerCase().includes(search)) 
              : calendars
    return (
      <layout.components.FullWidthRow>
        <layout.components.Container>
          <Paper elevation={4} className="container__search">
            <img src={Logo} alt={core.constants.imageAlt} style={{ maxWidth: '200px' }} />
            {
              Array.isArray(calendars) &&
              <components.CounsellorAppointmentSearch onChange={this.onChange} state={this.state} />
            }
            {
              !this.state.loading ?
                Array.isArray(calendars) ?
                  <calendar.components.List calendars={displayCalendars} onClick={this.onCounsellorClick} minDate={this.state.minDate} maxDate={this.state.maxDate}/>
                  : <invoice.components.CounsellorPreview calendar={calendars} onReset={this.onReset} minDate={this.state.minDate} maxDate={this.state.maxDate} />
                : <core.components.Loading />
            }
          </Paper>
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

CounsellorInvoice.propTypes = {
  authToken: PropTypes.string,
  calendars: PropTypes.oneOfType([PropTypes.arrayOf(model.CalendarShape), model.CalendarShape])
}

const stateToProps = (state, props) => ({
  authToken: auth.selectors.getAuthToken(state),
  calendars: calendar.selectors.getCalendars(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...calendar.actions,
    appointmentSearch: appointment.actions.search,
    selectCounsellor: appointment.actions.onSearchSuccess
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(CounsellorInvoice)