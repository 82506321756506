/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-08-05 16:39:52
 */


import React from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { savePDF, PDFExport } from "@progress/kendo-react-pdf";
import { generateAppDate, generateDate } from "../../../utils/utils"
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { getEmailText, updateEmaiText } from "service/api"
import { addOutstandingItems, removeOutstandingItem } from "../outstanding/actions";
import { fetchFuturePaidItems, addFuturePaidItems } from "../future-paid/actions";
import { showMessage } from "modules/messages/actions";

import { Button, Paper } from "material-ui"
import PropTypes from "prop-types"
import Logo from "../../../assets/img/logo.png"
import core from "../../core"
import layout from "../../layout"
import CounsellorLineItem from "./CounsellorLineItem"
import CounsellorSectionHeader from "./CounsellorSectionHeader"
import CounsellorSectionTotals from "./CounsellorSectionTotals"
import FooterSection from "./FooterSection"
import InvoiceGroup from "./InvoiceGroup"
import Meta from "./Meta"
import MiscLineItem from "./MiscLineItem"
import EmailPopUp from "../email-pop-up/EmailPopUp"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { faBackward, faPlus, faUserMd } from "@fortawesome/fontawesome-free-solid"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import model from "../../../model"
import appointment from "../../appointment"
import auth from "../../auth"
import { failedMessage } from "modules/messages/messages";

class CounsellorPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showMisc: true,
      therapistName: props.calendar.name,
      billTo: "No Fear Counselling",
      GSTNumber: "",
      invoiceDate: "",
      miscItems: [
        { idx: 0, description: "", price: null }
      ],
      showPopUp: false,
      invoicePDF: "",
      isGeneratingPDF: false,
      emailText: '',
      textLoading: false
    }

    this.invoiceRef = React.createRef()
    this.exportRef = React.createRef()
    this.onEditLocal = this.onEditLocal.bind(this)
    this.exportHandler = this.exportHandler.bind(this)
    this.onEditClaimNumber = this.onEditClaimNumber.bind(this)
    this.addMiscItem = this.addMiscItem.bind(this)
    this.onDeleteMiscItem = this.onDeleteMiscItem.bind(this)
    this.onAppointmentChange = this.onAppointmentChange.bind(this)
    this.emailClick = this.emailClick.bind(this)
    this.closeHandler = this.closeHandler.bind(this)
    this.updateEmailMessage = this.updateEmailMessage.bind(this)
    this.addFutureMiscItem = this.addFutureMiscItem.bind(this)
    this.updateMiscItems = this.updateMiscItems.bind(this)
    this.updateOutstandingItems = this.updateOutstandingItems.bind(this)
  }

  async componentDidMount() {
    const { minDate, maxDate, calendar: { future, outstanding, misc }, addOutstandingItems, fetchFuturePaidItems } = this.props
    this.setState({ invoiceDate: `${minDate} to ${maxDate}` })
  
    try {
      const emailText = await getEmailText()

      addOutstandingItems(outstanding)
      fetchFuturePaidItems(future)
      if (misc.length) {
        this.updateMiscItems(misc)
      }

      this.setState({emailText})
    } catch(e) {
      console.log(e)
    }
  }

  async updateEmailMessage(emailText) {
    try {
      this.setState({textLoading: true})
      const text = await updateEmaiText(emailText)
      this.setState({emailText: text})
    } catch(e) {
      this.props.showMessage(failedMessage)
    } finally {
      this.setState({textLoading: false})
    }
  }

  onEditLocal({ target: { name, value } }) {
    this.setState({ [name]: value })
  }

  onAppointmentChange(appointment, { target: { value } }) {
    const { updateAppointmentPrice } = this.props
    updateAppointmentPrice(appointment.id, value)
  }

  onEditClaimNumber({ target: { value } }) {
    const { setIcbcClaimNumber } = this.props
    setIcbcClaimNumber(value)
  }

  async exportHandler(e) {
    const { calendar } = this.props
    const fileName = `${generateDate()} - ${calendar.name}.pdf`
    
    savePDF(this.invoiceRef.current,
      {
        paperSize: "A4",  
        scale: 0.45, 
        fileName
      }
    )

    this.updateMiscItems(null, true)
  }

  async emailClick(e) {
    this.setState({isGeneratingPDF: true})

    const group = await drawDOM(this.invoiceRef.current, {
      paperSize: "A4",  
      scale: 0.45
    })
    const dataUri = await exportPDF(group)
    const invoicePDF = dataUri.split(";base64,")[1]
    
    this.setState({
      invoicePDF, 
      showPopUp: true, 
      isGeneratingPDF: false
    })
  }

  closeHandler(e) {
    this.setState({showPopUp: false})
  }

  generateLineItem(clientInfo, claimCase) {
    const { deleteAppointment, calendar } = this.props
   
    return (
      <CounsellorLineItem
        data={clientInfo}
        key={clientInfo.appointments[0].id}
        deleteAppointment={deleteAppointment}
        onChange={this.onAppointmentChange}
        counsellor={calendar}
      />
    )
  }

  onMiscLineItemChange(index, e) {
    const miscItem = this.state.miscItems[index]
    miscItem[e.target.name] = e.target.value
    const miscItems = [...this.state.miscItems.slice(0, index), miscItem, ...this.state.miscItems.slice(index + 1)]
    this.setState({ miscItems })
  }

  addMiscItem() {
    const miscItems = this.state.miscItems
    miscItems.push({ idx: `miscId-${miscItems.length}`, description: null, price: null })
    this.setState(miscItems)
  }

  addFutureMiscItem(item) {
    const newMiscItems = [...this.state.miscItems]
    const miscItems = this.state.miscItems

    const {firstName, lastName, date, counsellorRate, acuityID} = item
    const description = `${firstName} ${lastName}  ${generateAppDate(date)}`

    const newItem = { 
      idx: `miscId-${newMiscItems.length}`, 
      description, acuityID, 
      price: counsellorRate 
    }

    newMiscItems.push(newItem)

    if (miscItems.length === 1 && miscItems[0].description === "" &&  miscItems[0].price === null) {
      this.setState(state => ({...state, miscItems: [newItem]}))
    } else {
      this.setState(state => ({...state, miscItems: newMiscItems}))
    }
  }

  updateOutstandingItems() {
    const {addFuturePaidItems, outstandingItems, removeOutstandingItem} = this.props

    addFuturePaidItems(outstandingItems)
    outstandingItems.forEach(item => removeOutstandingItem(item.id))
  }

  updateMiscItems(items, refresh = false) {
    if (refresh) {
      this.setState(state => ({...state, miscItems: [{ idx: 0, description: "", price: null }]}))
    } else {
      const miscItems = []
      items.forEach(element => {
        const {firstName, lastName, date, counsellorRate, acuityID} = element
        const description = `${firstName} ${lastName}  ${generateAppDate(date)}`
  
        miscItems.push({ idx: `miscId-${miscItems.length}`, description, acuityID, price: counsellorRate })
      });
  
      if (items.length) {
        this.setState(state => ({...state, miscItems}))
      }
    }
  }

  onDeleteMiscItem(index) {
    const miscItems = [...this.state.miscItems.slice(0, index), ...this.state.miscItems.slice(index + 1)]
    this.setState({ miscItems })
  }

  render() {
    const { calendar: {acuityID}, counsellorAppointments: { appointments, totals }, onReset, calendar, outstandingItems, futurePaidItems } = this.props
    const style = {
      border: "1px solid #cdcdcd",
      padding: "0.5rem",
      marginBottom: "1rem"
    }

    const lineItems = _.map(_.values(appointments), sessions => {
      const clients = _.get(sessions, ["clients", "clients"], {})
      const clientItems = _.map(_.values(clients), client => this.generateLineItem(client, true))
      
      if (!clientItems.length) {
        return null
      }
  
      return (
        <InvoiceGroup heading={`${sessions.location}`} key={sessions.location} styles={style}>
          {clientItems.length > 0 &&
            <>
              <CounsellorSectionHeader />
              {clientItems}
            </>
          }
        </InvoiceGroup>
      )
    })

    let miscTotal = 0
    let miscTax = 0
    const miscItems = this.state.miscItems.map((item, idx) => {
      miscTax += Number(item.price * core.constants.taxRate)
      miscTotal += Number(item.price)

      return <MiscLineItem
        data={item}
        onChange={this.onMiscLineItemChange.bind(this, idx)}
        key={`${idx}-${item.idx}`} onDelete={this.onDeleteMiscItem.bind(this, idx)}
        idx={idx + 1}
      />
    })

    const subTotal = totals.totalRate + miscTotal
    const serviceFee = subTotal * (calendar.fee / 100)
    const totalTax = calendar.GSTEnabled ? subTotal * 0.05 : 0 
    const total = subTotal - serviceFee + totalTax
    const showOtstandingSection = futurePaidItems.length || outstandingItems.length
    return (
      <layout.components.FullWidthRow>
        <layout.components.Container>
          <div className="invoice__toolbar">
            <Button 
              variant="raised" 
              color="primary" 
              onClick={onReset}
            >
              <FontAwesomeIcon icon={faBackward} />
              &nbsp;<FontAwesomeIcon icon={faUserMd} />
              &nbsp; Counsellors
            </Button>
            <Button 
              onClick={this.emailClick} 
              color="secondary" 
              variant="raised" 
              disabled={this.state.isGeneratingPDF}
            >
              {
                this.state.isGeneratingPDF 
                  ? <FontAwesomeIcon icon={faSpinner} spin />
                  : "Email"
              }
            </Button>
          </div>
          <Paper elevation={4} className="invoice__container">
          <PDFExport ref={this.exportRef}>
            <div ref={this.invoiceRef}>
            <div className="invoice__header">
              <img src={Logo} alt={core.constants.imgAlt} className="logo" />
                <h1>Counsellor Invoice</h1>
            </div>
              <section className="invoice__meta-container">
                <Meta title={"Bill To"} data={this.state.billTo} editable name="billTo" placeholder="Bill To" onChange={this.onEditLocal} />
                <Meta title={"Therapist Name"} data={this.state.therapistName} editable name="therapistName" placeholder="Therapist Name" onChange={this.onEditLocal} />
                <Meta title={"Date Range"} data={this.state.invoiceDate} editable name="invoiceDate" placeholder="Invoice Date" onChange={this.onEditLocal} />
              </section>
              <section className="invoice__line-items">
                {lineItems}
              </section>
              {
                this.state.showMisc ?
                  <section className="invoice__line-items">
                    <InvoiceGroup heading="Misc/Resolved" styles={style}>
                      <section>
                        <article className={"invoice-line-item header"}>
                          <span className="flex-25" style={{flex: "0.5% 0.5"}}>Item</span>
                          <span className="flex-50">Description</span>
                          <span className="flex-25" style={{flex: "0.5% 0.5"}}>Total</span>
                        </article>
                      </section>
                      {miscItems}
                      <Button 
                        onClick={this.addMiscItem} 
                        color="secondary" 
                        variant="raised" 
                        className="add-button standard-margin" 
                      >
                        <FontAwesomeIcon icon={faPlus}/>&nbsp; Add
                      </Button>
                    </InvoiceGroup>
                  </section>
                  : null
              }

              <InvoiceGroup heading="Total" styles={style}>
                <CounsellorSectionTotals 
                  totals={{ sessions: totals.sessions, total, totalRate: subTotal, totalTax, serviceFee }} 
                  showGST={calendar.GSTEnabled} 
                  className="total"
                />
              </InvoiceGroup>
              {
                showOtstandingSection ?
                  <InvoiceGroup heading="" styles={style}>
                    <FooterSection 
                      addFutureMiscItem={this.addFutureMiscItem}
                      outstandingItems={outstandingItems}
                      futurePaidItems={futurePaidItems}  
                    />
                  </InvoiceGroup>
                  : null
              }
            
            </div>
            </PDFExport>
            {
              this.state.showPopUp && 
                <EmailPopUp 
                  updateMiscItems={this.updateMiscItems}
                  closeHandler={this.closeHandler}
                  calendar={calendar}
                  file={this.state.invoicePDF}
                  text={this.state.emailText}
                  updateText={this.updateEmailMessage}
                  textLoading={this.state.textLoading}
                  exportHandler={this.exportHandler}
                  updateOutstandingItems={this.updateOutstandingItems}
                />
            }
          </Paper>
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

CounsellorPreview.propTypes = {
  calendar: model.CalendarShape,
  appointments: PropTypes.arrayOf(model.AppointmentShape),
  counsellorAppointments: PropTypes.object.isRequired,
  updateAppointmentPrice: PropTypes.func
}

const stateToProps = (state) => ({
  authToken: auth.selectors.getAuthToken(state),
  appointments: appointment.selectors.getAppointments(state),
  counsellorAppointments: appointment.selectors.counsellorAppointments(state),
  futurePaidItems: state.futurePaidList,
  outstandingItems: state.outstandingItems
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    updateAppointmentPrice: appointment.actions.updateAppointmentPrice,
    deleteAppointment: appointment.actions.deleteAppointment,
    addOutstandingItems,
    addFuturePaidItems,
    removeOutstandingItem,
    fetchFuturePaidItems,
    showMessage
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(CounsellorPreview)