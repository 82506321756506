/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:17 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 12:22:33
 */
import { showMessage } from "modules/messages/actions"
import { badFitGrouped } from "../bad-fit/selectors"
import { updateCounsellorClients } from "service/selectors"
import { getCounsellorClients, getAdminPageCounsellors, getBadFitItems, getTaskList, getCounsellorWithClients, getPaymentSources} from "service/api"

import {UPDATE_COUNSELLOR, DELETE_BAD_FIT_ITEM, UPDATE_BAD_FIT_ITEM, DELETE_TASK,FETCH_ADMIN_DATA, FETCH_BAD_FIT, FETCH_TASK_LIST, FETCH_COUNSELLOR_CLIENTS, FETCH_SOURCES} from "./actionTypes"
import { failedMessage } from "modules/messages/messages"
import { getIdByDate } from "utils/utils"

export const fetchAdminCounsellors = () => async (dispatch) => {
  try {
    const counsellors = await getAdminPageCounsellors()
    return dispatch(onFetchAdminDataSuccess(counsellors))
  } catch {
    return dispatch(showMessage(failedMessage))
  }
}

export const fetchAdminCounsellorClients = (acuityID) => async (dispatch) => {
  try {
    const clients = await getCounsellorClients(acuityID)
    const updatedClients = updateCounsellorClients(clients)

    return dispatch(onFetchCounsellorClientsSucceess(updatedClients, acuityID))
  } catch {
    return dispatch(showMessage(failedMessage))
  }
}

export const fetchUpdatedCounsellor = (id) => async (dispatch) => {
  try {
    const {clients, counsellorAlerts: counsellor} = await getCounsellorWithClients(id)
    const updatedClients = updateCounsellorClients(clients)

    return dispatch(onFetchUpdatedCounsellorSuccess({...counsellor[0], clients: updatedClients}))
  } catch {
    return dispatch(showMessage(failedMessage))
  }
}



export const fetchBadFitItems = () => async (dispatch) => {
  try {
    const badFitItems = await getBadFitItems()
    const badFitGroupedList = badFitGrouped(badFitItems)

    return dispatch(onFetchBadFitSuccess(badFitGroupedList))
  } catch {
    return dispatch(showMessage(failedMessage))
  }
}

export const fetchTaskList = () => async (dispatch) => {
  try {
    const tasks = await getTaskList()
    tasks.sort((a, b) => getIdByDate(b.dateOfCompleteTask) - getIdByDate(a.dateOfCompleteTask))

    return dispatch(onFetchTasksSuccess(tasks))
  } catch {
    return dispatch(showMessage(failedMessage))
  }
}

export const fetchPaymentSources = () => async (dispatch) => {
  try {
    const sources = await getPaymentSources()
    return dispatch(onFetchPaymentSourcesSuccess(sources))
  } catch {
    return dispatch(showMessage(failedMessage))
  }
}

export const onFetchUpdatedCounsellorSuccess = (counsellor) => ({
  type: UPDATE_COUNSELLOR,
  counsellor
})

export const onFetchBadFitSuccess = (badFitItems = []) => ({
  type: FETCH_BAD_FIT,
  badFitItems
})

export const onFetchTasksSuccess = (tasks) => ({
  type: FETCH_TASK_LIST,
  tasks
})

export const deleteBadFitItem = (id, groupId) => ({
  type: DELETE_BAD_FIT_ITEM,
  id,
  groupId
})

export const updateBadItem = (item, groupId) => ({
  type: UPDATE_BAD_FIT_ITEM,
  item,
  groupId
})

export const onFetchAdminDataSuccess = (counsellors) => ({
  type: FETCH_ADMIN_DATA,
  counsellors
})

export const onFetchCounsellorClientsSucceess = (clients, counsellorId) => ({
  type: FETCH_COUNSELLOR_CLIENTS,
  clients,
  counsellorId
})

export const onFetchPaymentSourcesSuccess = (sources) => ({
  type: FETCH_SOURCES,
  sources
})

export const deleteTaskAction = (taskId) => ({
  type: DELETE_TASK,
  taskId
})
