export const GET_APPOINTMENTS = `
    query getAppointment($params: AppointmentParams!){
        appointments(params: $params) {
            sessionLocation
            GST
            amountPaid
            calendar
            canceled
            date
            email
            firstName
            lastName
            id
            paid
            phone
            price
            time
            type
            totalBilled
            clientRate
            acuityID
            listType
            status
            counsellorRate
            client {
                claimNumber
                id
                paymentSource: paymentSourceObject {
                    name
                    profile
                    isActive
                }
            }
        }
    }
`

export const GET_CLIENTS = `
    query getClients($search: String!) {
        clients(search: $search) {
            email
            firstName
            lastName
            notes
            phone
        }
    }
`

export const GET_INVOICE_NUMBER =`
    query {
        invoiceNumber
    }
`

export const GET_TOTAL_BILLED = `
    query getClients($params: AppointmentParams!) {
        appointments(params: $params) {
            totalBilled
        }
    }
`
export const GET_CALENDARS_LIST = `
    query getCalendars($params: Params!) {
        counsellors(params: $params) {
            acuityID
            fee
            share
            external
            name
            GSTEnabled
            email
            id
        }
    }
`

export const GET_INVOICES_NUMBERS = `
    query getInvoicesNumbers($operationId: Int!) {
        extractedInvoiceNumbers(operationId: $operationId) {
            invoiceNumber
            invoiceId
            totalBilled
        }
    }
`

export const GET_EMAIL_TEXT = `
    query getInvoicesNumbers {
        emailText {
            text
        }
    }
`

export const COUNSELLOR_CLIENTS = `
    query getAdminCounsellors ($params: Params!) {
        homePage(params: $params) {
            clients {
                id
                firstName
                alertsEnabled
                isActive
                lastName
                paymentSource {
                    name
                    profile
                    isActive
                }
                phone
                totalBilled
                usedSessions
                expiryDate
                chargedSessions
                approvedSessions
                mutationRequest
                dateOfApprovalCheck
                partiallyPaidIDs
                fullName
            }
        }
    }
`

export const COUSNELLORS_WITH_ALERTS = `
    query getAdminAlerts ($params: Params!) {
        counsellorAlerts(params: $params) {
            id
            name
            acuityID
            alerts
        }
    }
`

export const GET_TASK_LIST = `
    query MyQuery {
        tasks {
            counsellor
            dateOfCompleteTask
            dateOfCreationTask
            appointmentID
            isCompleted
            task
            id
            isDirect
            appointment {
                firstName
                lastName
                date
                counsellor {
                    name
                }
            }

        }
    }  
`

export const GET_BAD_FIT = `
    query MyQuery {
        badFit {
            id
            isGrey
            isGoodFeet
            notes
            dateCreated
            firstName
            lastName
            counsellor
        }
    }
  
`


export const GET_COUNSELLOR_INVOICE = `
    query getCounsellorInvoice($params:  Params!){
        counsellorAppointments(params: $params) {
            future {
                GST
                sessionLocation
                calendar
                date
                email
                firstName
                lastName
                id
                paid
                phone
                price
                time
                type
                clientRate
                counsellorRate
                amountPaid
                acuityID
                listType
                status
                client {
                    claimNumber
                    approvedSessions
                    dateOfApprovalCheck
                    expiryDate
                    id
                    paymentSource {
                        name
                        profile
                        isActive
                    }
                }
            }

            misc {
                GST
                sessionLocation
                calendar
                date
                email
                firstName
                lastName
                id
                paid
                phone
                price
                time
                type
                clientRate
                counsellorRate
                amountPaid
                acuityID
                status
                listType
                client {
                    claimNumber
                    approvedSessions
                    dateOfApprovalCheck
                    expiryDate
                    id
                    paymentSource {
                        name
                        profile
                        isActive
                    }
                }
            }

            outstanding {
                GST
                sessionLocation
                calendar
                date
                email
                firstName
                lastName
                id
                paid
                phone
                price
                time
                type
                clientRate
                counsellorRate
                amountPaid
                acuityID
                listType
                status
                client {
                    claimNumber
                    approvedSessions
                    dateOfApprovalCheck
                    expiryDate
                    id
                    paymentSource {
                        name
                        profile
                        isActive
                    }
                }
            }
            
            default {
                GST
                sessionLocation
                calendar
                date
                email
                firstName
                lastName
                id
                paid
                phone
                price
                time
                type
                clientRate
                counsellorRate
                amountPaid
                status
                acuityID
                listType
                client {
                    claimNumber
                    approvedSessions
                    dateOfApprovalCheck
                    expiryDate
                    id
                    paymentSource {
                        name
                        profile
                        isActive
                    }
                }
            }
        }
    }
`

export const GET_COUNSELLOR_OUTSTANDING = `
    query getCounsellorOutstanding($params: Params!){
        counsellorAppointments(params: $params) {
            future {
                GST
                sessionLocation
                calendar
                date
                email
                firstName
                lastName
                id
                paid
                phone
                price
                time
                type
                clientRate
                counsellorRate
                amountPaid
                acuityID
                listType
                status
                client {
                    claimNumber
                    approvedSessions
                    dateOfApprovalCheck
                    expiryDate
                    id
                    paymentSource {
                        name
                        profile
                        isActive
                    }
                }
            }

            outstanding {
                GST
                sessionLocation
                calendar
                date
                email
                firstName
                lastName
                id
                paid
                phone
                price
                time
                type
                clientRate
                counsellorRate
                amountPaid
                acuityID
                listType
                status
                client {
                    claimNumber
                    approvedSessions
                    dateOfApprovalCheck
                    expiryDate
                    id
                    paymentSource {
                        name
                        profile
                        isActive
                    }
                }
            }
        }
    }
`

export const GET_COUSNELLOR_WITH_CLIENTS = `
    query getCounsellorWithClients ($params: Params!) {
        counsellorAlerts(params: $params) {
            id
            name
            acuityID
            alerts
        }

        homePage(params: $params) {
            clients {
                id
                firstName
                alertsEnabled
                isActive
                lastName
                paymentSource {
                    name
                    profile
                    isActive
                }
                phone
                totalBilled
                usedSessions
                expiryDate
                chargedSessions
                approvedSessions
                mutationRequest
                dateOfApprovalCheck
                partiallyPaidIDs
                fullName
            }
        }
    }
`

export const GET_CLIENT_RATES = `
    query getClientRates {
        clientRates {
            id
            paymentSource {
                id
                name
                profile
                isActive
                orderNumber
                hasCouple
                noChangeFlag
            }
            value
            valueForCouples
        }
    }
`