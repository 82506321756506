/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:17 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-22 21:29:54
 */

import * as actionTypes from "./actionTypes"
import * as errorTypes from "./errorTypes"

import Service from "./service"
import core from "../core"
import messages from "../messages"
import model from "../../model"
import { showClienAppointment } from "utils/utils"

export const search = (search = model.Search(), authToken = undefined, service = new Service()) => (dispatch) => {
  return service.search(search, authToken)
    .then(
      (data) => {
        const updatedData = data.filter(el => showClienAppointment(el))

        return dispatch(onSearchSuccess(updatedData))
      },
      (error) => {
        if (error) {
          dispatch(messages.actions.showMessage(core.utils.handleError(error, errorTypes.search)))
        }
      }
    )
}

export const changeSource = (updatedAppointments = [], source = "") => ({
  type: actionTypes.changeSource,
  updatedAppointments,
  source
})

export const toggleSelection = (id) => ({
  type: actionTypes.toggleSelection,
  id
})

export const deleteAppointment = (id) => ({
  type: actionTypes.deleteAppointment,
  id
})

export const updateAppointmentPrice = (id, price) => ({
  type: actionTypes.updateAppointmentPrice,
  data: {id, price}
})

export const onSearchSuccess = (appointments = model.store()) => ({
  type: actionTypes.search,
  appointments: appointments.map(model.Appointment)
})

export const updateAppointmentRate = (id, rate, counsellorRate, GST) => ({
  type: actionTypes.updateAppointmentRate,
  data: {id, rate, counsellorRate, GST}
})

export const updateCounsellorRate = (id, counsellorRate, clientRate, GST) => ({
  type: actionTypes.updateCounsellorRate,
  data: {id, counsellorRate, clientRate, GST}
})

export const addAppointment = (appointment) => ({
  type: actionTypes.addAppointment,
  appointment
})

export const expand = (id) => ({
  type: actionTypes.expand,
  meta: { id },
})

export const collapse = (id) => ({
  type: actionTypes.collapse,
  meta: { id },
})
