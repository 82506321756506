/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:39:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-22 21:33:53
 */

import _ from "lodash"
import core from "../core"
import model from "../../model"
import {moduleName} from "./constants"
import { isClinicalRecords, isInitialReport, isProgressReport, isReport } from "../../utils/utils"

export const getAppointments = (state) => (state[moduleName])
export const getAppointment = (state, id) => (
  _.find(getAppointments(state), a => a.id === id)
)
export const getSelectedAppointments = (state) => (
  _.filter(getAppointments(state), a => a.selected)
)
export const getTotalTax = (state) => {
  return getAppointments(state)
    .reduce((last, current) => (last + (Number(current.price) * core.constants.taxRate)), 0)
}
export const getTotalRate = (state) => (
  getAppointments(state)
    .reduce((last, current) => (last + Number(current.price)), 0)
)
export const getTotal = (state) => (
  getTotalRate(state) + getTotalTax(state)
)
export const clientAppointmentsByName = (state) => (
  _.mapValues(_.groupBy(getAppointments(state), a => _.camelCase(`${a.firstName} ${a.lastName}`)), c => {
    return model.ClientAppointment(c)
  })
)
export const counsellorAppointments = (state) => {
  var total = 0
  var totalRate = 0
  var totalTax = 0
  var sessions = 0
  
  return {
    appointments: 
      _.mapValues(_.groupBy(getAppointments(state), a => _.camelCase(`${a.sessionLocation}`)), (allAppointments) => {
      //By Type
      //Filter private appointments
      const nonPrivate = _.filter(allAppointments, (appointment) => appointment.firstName.toLowerCase().indexOf("private") < 0)
      const type = _.get(_.first(nonPrivate), "sessionLocation", "")

      const claimCase = _.mapValues(_.groupBy(nonPrivate, a => _.camelCase('clients')), appointments => {
        //By icbc/non-icbc
        const clients = _.mapValues(_.groupBy(appointments, a => _.camelCase(`${a.firstName}${a.lastName}`)), clientAppointments => model.CounsellorAppointment(clientAppointments))
        const sectionTotal = _.reduce(clients, (curr, next) => curr+next.total, 0)
        const sectionTotalRate = _.reduce(clients, (curr, next) => curr+next.totalRate, 0)
        const sectionTotalTax = _.reduce(clients, (curr, next) => curr+next.totalTax, 0)
        const sectionSessionCount = _.reduce(clients, (curr, next) => curr+next.appointments.length, 0)
        
        total += sectionTotal
        totalRate += sectionTotalRate
        totalTax += sectionTotalTax
        sessions += sectionSessionCount
        
        const calcPaid = (appointments) => {
          let paidAppCount = 0
          let partPaidAppCount = 0

          appointments.forEach(element => {
            const {amountPaid, price, paid} = element

            if (paid === "yes" && (price <= amountPaid || amountPaid === 0)) {
              paidAppCount += 1
            } else if(paid === "yes" && price > amountPaid && amountPaid !== 0) {
              partPaidAppCount += 1
            }
          });

          return {paidAppCount, partPaidAppCount}
        }

        const getAmountPaids = (appointments) => {
          const filteredApp = appointments.filter(el => {
            return el.amountPaid !== 0 
              && (el.paymentSource !== "CVAP" 
                ? el.price > el.amountPaid 
                : el.price >= el.amountPaid)
          })
          
          const paidCount = _.uniq(filteredApp.map(o => (o.amountPaid / 1.05).toFixed(2))).join(", $")

          return paidCount
        }
        //logic for separating initial, progress, clinical report...
        for (let client in clients) {
          const clientNewData = {...clients[client]}
          const clientData = clients[client]
          const {paidAppCount, partPaidAppCount} = calcPaid(clientData.appointments)
          const paids = getAmountPaids(clientData.appointments)
          const clientRates = _.uniq(clientData.appointments.map(o => o.clientRate?.toFixed(2))).join(", $")
    
          clientData.clientRates = clientRates
          clientData.paids = paids
          clientData.paidAppCount = paidAppCount
          clientData.partPaidAppCount = partPaidAppCount

          const reportAppointments =  clientData.appointments.filter(a => isReport(a.type))

          const progressReportApps = clientData.appointments.filter(a => isProgressReport(a.type))
          const clinicalRecordsApps = clientData.appointments.filter(a => isClinicalRecords(a.type))
          const initialReportApps = clientData.appointments.filter(a => isInitialReport(a.type))

          if (reportAppointments.length) {
            const notReportAppointments = clientData.appointments.filter(a => !isReport(a.type))

            const notReportRates = _.uniq(notReportAppointments.map(o => o.counsellorRate.toFixed(2))).join(", $")
            const notReportClientRates = _.uniq(notReportAppointments.map(o => o.clientRate.toFixed(2))).join(", $")
            const notReportTotal = notReportAppointments.reduce((sum, appointment) => sum + appointment.counsellorRate, 0)

            if (!notReportAppointments.length) {
              delete clients[client]
            } 
          
            if (clients[client])  {
              const {paidAppCount, partPaidAppCount} = calcPaid(notReportAppointments)
              const paids = getAmountPaids(notReportAppointments)

              clients[client].paids = paids
              clients[client].partPaidAppCount = partPaidAppCount
              clients[client].paidAppCount = paidAppCount

              clients[client].appointments = notReportAppointments
              clients[client].rates = notReportRates
              clients[client].clientRates = notReportClientRates
              clients[client].totalRate = notReportTotal
            }
          }

          if (progressReportApps.length) {
            const clientInfo = clientData.client
            const {paidAppCount, partPaidAppCount} = calcPaid(progressReportApps)
            const paids = getAmountPaids(progressReportApps)

            const progressReportRates = _.uniq(progressReportApps.map(o => o.counsellorRate.toFixed(2))).join(", $")
            const progressReportClientRates = _.uniq(progressReportApps.map(o => o.clientRate.toFixed(2))).join(", $")
            const progressReportTotal = progressReportApps.reduce((sum, appointment) => sum + appointment.counsellorRate, 0)

            clientNewData.paids = paids
            clientNewData.partPaidAppCount = partPaidAppCount
            clientNewData.paidAppCount = paidAppCount
            clientNewData.appointments = progressReportApps
            clientNewData.rates = progressReportRates
            clientNewData.clientRates = progressReportClientRates
            clientNewData.totalRate = progressReportTotal
            
            clients[`${clientInfo.firstName.toLowerCase()}${clientInfo.lastName}Progress`] = {...clientNewData} 
          }

          if (clinicalRecordsApps.length) {
            const clientInfo = clientData.client
            const {paidAppCount, partPaidAppCount} = calcPaid(clinicalRecordsApps)
            const paids = getAmountPaids(clinicalRecordsApps)

            const clinicalRecordsRates = _.uniq(clinicalRecordsApps.map(o => o.counsellorRate.toFixed(2))).join(", $")
            const clinicalRecordsClientRates = _.uniq(clinicalRecordsApps.map(o => o.clientRate.toFixed(2))).join(", $")
            const clinicalRecordsTotal = clinicalRecordsApps.reduce((sum, appointment) => sum + appointment.counsellorRate, 0)

            clientNewData.paids = paids
            clientNewData.partPaidAppCount = partPaidAppCount
            clientNewData.paidAppCount = paidAppCount
            clientNewData.appointments = clinicalRecordsApps
            clientNewData.rates = clinicalRecordsRates
            clientNewData.clientRates = clinicalRecordsClientRates
            clientNewData.totalRate = clinicalRecordsTotal
            
            clients[`${clientInfo.firstName.toLowerCase()}${clientInfo.lastName}Clinical`] = {...clientNewData} 
          }

          if (initialReportApps.length) {
            const clientInfo = clientData.client
            const {paidAppCount, partPaidAppCount} = calcPaid(initialReportApps)
            const paids = getAmountPaids(initialReportApps)

            const initialReportRates = _.uniq(initialReportApps.map(o => o.counsellorRate.toFixed(2))).join(", $")
            const initialReportClientRates = _.uniq(initialReportApps.map(o => o.clientRate.toFixed(2))).join(", $")
            const initialReportTotal = initialReportApps.reduce((sum, appointment) => sum + appointment.counsellorRate, 0)

            clientNewData.paids = paids
            clientNewData.partPaidAppCount = partPaidAppCount
            clientNewData.paidAppCount = paidAppCount
            clientNewData.appointments = initialReportApps
            clientNewData.rates = initialReportRates
            clientNewData.clientRates = initialReportClientRates
            clientNewData.totalRate = initialReportTotal
            
            clients[`${clientInfo.firstName.toLowerCase()}${clientInfo.lastName}Initial`] = {...clientNewData} 
          }
        }
        
       
        return {
          clients,
          totals: {
            sessions: sectionSessionCount,
            total: sectionTotal,
            totalRate: sectionTotalRate,
            totalTax: sectionTotalTax
          }
        }
      })
      return {
        location: type,
        totals: {
          sessions,
          total,
          totalRate,
          totalTax
        },
        ...claimCase,
      }
    }),
    totals: {
      sessions,
      total,
      totalRate,
      totalTax
    }
  }
}

export const getExpansionPanel = (state, id) => state.expansionPanel[id]