/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 18:36:35 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 23:58:20
 */

import './layout.css'
import CallToAction from './CallToAction'
import components from './components'
import Services from './Services'

export default {
  CallToAction,
  components,
  Services,
}