/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:30:23 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 12:59:58
 */

import model from "../../model"
import * as constants from "./constants"

export default class Service{
  login(auth = model.Login()) {
    return super.post(constants.endpoints.login, auth)
  }
  verifyToken(authToken) {
    return super.get(constants.endpoints.verifyToken, null, authToken)
  }
}