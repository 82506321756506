/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:41:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 15:17:48
 */

import model from "../../model"
import * as actionTypes from "./actionTypes"

export const reducer = (state = model.store(), action) => {
  switch(action.type) {

  case actionTypes.select:
    return action.calendar

  case actionTypes.search:
    return action.calendars

  default:
    return state
  }
}