/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-24 22:51:25
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-14 21:15:46
 */

import React from "react";
import {useDispatch, useSelector } from "react-redux";

import core from "../../core";
import Login from "./Login";
import * as actions from "../actions";
import * as actionTypes from "../actionTypes";

import { useEffect } from "react";
import jwt_decode from "jwt-decode";

const AuthContainer = ({ children, ...rest }) => {
  const authedUser = useSelector(state => state.auth.profile);

  return authedUser !== null ? (
    <section>{core.utils.childrenComponents(children, rest)}</section>
  ) : (
    <Login />
  );
};

export default AuthContainer;
