import { IconButton } from 'material-ui';
import React from 'react';
import upImg from 'assets/img/up.svg'

const CollapseAlertButton = ({onClick}) => {
    return (
        <IconButton 
            className='collaple-list-btn'
            onClick={onClick}
        >
            <img src={upImg} alt="up"/>
        </IconButton>
    );
};

export default CollapseAlertButton;