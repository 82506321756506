/*
 * @Author: Sergey Moshko 
 * @Date: 2022-04-24 20:41:41 
 */
import {camelCase} from 'lodash'
import { getIdByDate } from 'utils/utils'
import { UPDATE_COUNSELLOR, DELETE_BAD_FIT_ITEM, UPDATE_BAD_FIT_ITEM, DELETE_TASK , FETCH_ADMIN_DATA, FETCH_BAD_FIT, FETCH_TASK_LIST, FETCH_COUNSELLOR_CLIENTS, FETCH_SOURCES } from "./actionTypes"

export const reducer = (state = {}, action) => {
  switch(action.type) {
  case FETCH_ADMIN_DATA: {
    return {...state, counsellors: action.counsellors}
  }

  case FETCH_BAD_FIT: {
    return {...state, badFitItems: [...action.badFitItems]}
  }

  case FETCH_TASK_LIST: {
    const tasks = action.tasks

    return {...state, tasks}
  }

  case UPDATE_COUNSELLOR: {
    const counsellor = action.counsellor
    const updatedCounsellors = state.counsellors.map((el) => el.id === counsellor.id ? counsellor : el )

    return {...state, counsellors: updatedCounsellors}
  }
  
  case DELETE_BAD_FIT_ITEM: {
    const {id, groupId} = action

    const updatedBadFitList = state.badFitItems.map(group => {
      if (group.id === groupId) {
        group.items = group.items.filter(el => el.id !== id)
      } 

      return group
    })

    return {...state, badFitItems: updatedBadFitList}
  }

  case UPDATE_BAD_FIT_ITEM: {
    const {item, groupId} = action

    const updatedBadFitList = state.badFitItems.map(group => {
      if (group.id === groupId) {
        const items = group.items.map((el) => el.id === item.id ? item : el )
        group.items = items
      } 

      return group
    })

    return {...state, badFitItems: updatedBadFitList}
  }

  case DELETE_TASK: {
    const taskId = action.taskId
    const updatedTask = state.tasks.filter(el => el.id !== taskId)
    
    return {...state, tasks: updatedTask}
  }

  case FETCH_COUNSELLOR_CLIENTS: {
    const { counsellorId, clients } = action
    const updatedCounsellors = state.counsellors.map((el) => el.acuityID === counsellorId ? {...el, clients} : el)
    
    return {...state, counsellors: updatedCounsellors}
  }

  case FETCH_SOURCES: {    
    return {...state, sources: action.sources}
  }

  default:
    return state
  }
}