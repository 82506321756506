import { showMessage } from "modules/messages/actions"
import { failedMessage } from "modules/messages/messages"
import { getCounsellorOutstanding, getCounsellorWithClients } from "service/api"
import { FETCH_COUNSELLOR } from "./actionsTypes"
import { counsellorClients } from "../selectors"

export const fetchCounsellor = () => async (dispatch) => {
  try {
    const {clients, counsellorAlerts: counsellor} = await getCounsellorWithClients()
    const {future, outstanding} = await getCounsellorOutstanding(counsellor[0].acuityID)
    const updatedClients = counsellorClients(clients)
    
    return dispatch(onFetchCounsellorSuccess({ 
      ...counsellor[0], 
      ...updatedClients,
      future, 
      outstanding 
    }))
  } catch(e) {
    console.log(e)
    return dispatch(showMessage(failedMessage))
  }
}

export const onFetchCounsellorSuccess = (counsellor) => ({
    type: FETCH_COUNSELLOR,
    counsellor
})
