import React from 'react';
import {useState } from 'react'
import {useDispatch} from "react-redux"
import { useForm } from "react-hook-form";
import { updateCounsellorPassword } from 'service/api';
import { showMessage } from 'modules/messages/actions';
import { showPopUp } from '../actions';
import { isEmpty } from 'utils/utils';

import { invalidPasswordMessage, passwordSuccessMessage, failedMessage } from 'modules/messages/messages';
import { passwordRegex } from 'service/constants';
import close from 'assets/img/close.svg'
import FormInput from 'ui-components/FormInput';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import {Button} from 'material-ui'

import './change-password.scss'


const ChangePasswordPopUp = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
  
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        reset
    } = useForm({
        mode: "onSubmit",
        reValidateMode: 'onChange'
    });

    const password = watch("newPassword", "");

    const closeHandler = () => dispatch(showPopUp())

    const onSubmit = async (data) => {
        const {currentPassword, newPassword} = data

        try {
            setLoading(true)
            await updateCounsellorPassword(newPassword, currentPassword)
            dispatch(showMessage(passwordSuccessMessage))
            reset()
            setTimeout(() => closeHandler(), 100) 
        } catch(e) {
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
    }

    const {currentPassword, newPassword, passwordReEntered} = watch()
    const isBtnDisabled = !currentPassword || !newPassword || !passwordReEntered || loading || !isEmpty(errors)
    return (
        <div className='change-password'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <button 
                    className="icon-button close-button"
                    onClick={closeHandler}
                    type="button"
                >
                    <img className="close-button" src={close} alt="close-button"/>
                </button>
                <div className="form-wrapper">
                    <h2 className="form-head">Change Password</h2>
                    <FormInput
                        title={"Current password"}
                        name={"currentPassword"}
                        register={register}
                        errors={errors}
                        isPassword={true}
                        pattern={null}
                        invalidMessage={invalidPasswordMessage}
                    />
                    <FormInput
                        title={"New password"}
                        name="newPassword"
                        register={register}
                        errors={errors}
                        isPassword={true}
                        pattern={passwordRegex}
                        invalidMessage={invalidPasswordMessage}
                    />
                    <FormInput
                        title={"Re-enter new password"}
                        name={"passwordReEntered"}
                        register={register}
                        errors={errors}
                        isPassword={true}
                        pattern={passwordRegex}
                        invalidMessage={invalidPasswordMessage}
                        password={password}
                    />
                    <Button
                        color="secondary" 
                        variant="raised" 
                        className="submit-button"
                        type="submit"
                        disabled={isBtnDisabled}
                    >
                        { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
                    </Button>
                        
                </div>
            </form>
        </div>
    );
};

export default ChangePasswordPopUp;