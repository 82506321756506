/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:41:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-09 17:20:15
 */

import { combineReducers } from "redux"
import * as actionTypes from "./actionTypes"


export const profile = (state = null, action) => {
  switch(action.type) {
  case actionTypes.login: {
    const {isAdmin, name} = action.payload
    return {isAdmin, name}
  }

  default:
    return state
  }
}

export const showChangeEmailPopUp = (state = false, action) => {
  switch(action.type) {
  case actionTypes.showPopUp:
    return !state
  default:
    return state
  }
}

export const showChangePhonePopUp = (state = false, action) => {
  switch(action.type) {
  case actionTypes.showChangePhonePopUp:
    return !state
  default:
    return state
  }
}

export const showRegistrationPopUp = (state = false, action) => {
  switch(action.type) {
  case actionTypes.showRegistrationPopUp:
    return !state
  default:
    return state
  }
}

export const showForgorpasswordPopUp = (state = false, action) => {
  switch(action.type) {
  case actionTypes.showForgorpasswordPopUp:
    return !state
  default:
    return state
  }
}

export const reducer = combineReducers({
  profile,
  showChangeEmailPopUp,
  showChangePhonePopUp,
  showRegistrationPopUp,
  showForgorpasswordPopUp
})