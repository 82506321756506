/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 11:12:37 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 13:43:00
 */

import * as _ from 'lodash'
import * as constants from './constants'

import React from 'react'
import moment from 'moment'
import randomColor from 'randomcolor'

export const onChange = function (e, callback) {
  const newState = {}
  e.preventDefault()
  newState[e.target.name] = e.target.value  
  this.setState(newState)
  // this.validate(e)

  if(callback) {
    callback.call(this)
  }
}

export const validate = function (formField) {
  // If there's no validator configured, make it valid
  formField.dataset["valid"] = true
}

/**
 * Pass properties onto children components, skips a child if it's not a component
 * @param {*} children
 * @param {*} props
 * @returns {object}
 */
export const childrenComponents = (children, props) => (
  React.Children.map(children, child => (
    typeof child.type === 'function' ?
      React.cloneElement(child, { ...props }) :
      React.cloneElement(child)
  ))
)

export const handleError = (error, errorTypes) => {
  return (error?.response && errorTypes[error.response.status]) ?
    errorTypes[error.response.status] : error?.status ? 
      errorTypes[error.status] : constants.defaultError
}

export const updateCollection = (collection, predicate, newObj) => {
  const idx = _.findIndex(collection, predicate)
  const newCollection = [
    ...collection.slice(0, idx),
    newObj,
    ...collection.slice(idx + 1)
  ]
  return newCollection;
}

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const zeroPad = (number, digits) => {
  let num = number+"";
  while(num.length < digits){
    num='0'+num;
  }
  return num;
}

export const pluralize = (map, value) => {
  if(!value) return `${map.none}`
  else if(value === 1) return `${value} ${map.one}`
  else if(value > 1) return `${value} ${map.many}`
  else return `${value}`
} 

export const readIncrementLocalStorage = (name) => {
  const value = window.localStorage.getItem(name)
  const nextValue = Number(value) + 1
  window.localStorage.setItem(name, nextValue)
  return nextValue
}

export const readLocalStorage = (name) => {
  return window.localStorage.getItem(name)
}

export const setLocalStorage = (name, value) => {
  window.localStorage.setItem(name, value)
}

export const memoizeClientColor = _.memoize(randomColor, (config, client) => {
  return `${client.firstName}${client.lastName}`
})

export const memoizeColorById = _.memoize(randomColor, (config, obj) => {
  return obj.id
})