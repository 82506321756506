/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-24 20:26:17
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-09 17:19:57
 */

import core from "../core";
import messages from "../messages";
import model from "../../model";

import * as actionTypes from "./actionTypes";
import { confirmRegistration, signIn, verifyToken } from "service/api";
import jwt_decode from "jwt-decode";
import { loginSuccessMessage, registrationSuccessMessage } from "modules/messages/messages";
 

export const login = (creds = model.Login(), isRegistration, loginRouterHandler, phone="") => async (dispatch) => {
  try {
    const token = isRegistration 
      ? await confirmRegistration(creds.username, creds.password, phone)
      : await signIn(creds.username, creds.password)
      
    localStorage.setItem("token", token)
    const user = jwt_decode(token);
    dispatch(onSuccess(actionTypes.login, user));
    dispatch(
      messages.actions.showMessage(
        isRegistration 
          ? registrationSuccessMessage
          : loginSuccessMessage
      )
    );    

    loginRouterHandler(user.isAdmin)
  } catch (error) {
    dispatch(
      messages.actions.showMessage({
        title: error.response?.data.message,
        message: "Please try again",
        type: "error",
      })
    );
  }
};


export const onSuccess = (type = actionTypes.login, payload = {}) => ({
  type,
  payload,
});

export const logout = () => ({
  type: actionTypes.logout
});

export const showPopUp = () => ({
  type: actionTypes.showPopUp,
})

export const showChangePhonePopUp = () => ({
  type: actionTypes.showChangePhonePopUp,
})

export const showRegistrationPopUp = () => ({
  type: actionTypes.showRegistrationPopUp,
})

export const showForgorpasswordPopUp = () => ({
  type: actionTypes.showForgorpasswordPopUp,
})

export const verifyTokenAction = (setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    const {isAdmin, name} = await verifyToken()
    dispatch(onSuccess(actionTypes.login, {isAdmin, name}));
  } catch {
    localStorage.removeItem("token")
    dispatch(
      messages.actions.showMessage({
        title: "Auth Failed",
        message: "Auth failed, please login",
        type: "error",
      })
    );
  } finally {
    setTimeout(() => setLoading(false), 200)
  }
}

