import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteBadFitItemRequest, updateBadFitItem } from 'service/api';
import { deleteBadFitItem } from 'modules/admin/redux/actions';
import { showMessage } from 'modules/messages/actions';

import { Button } from 'material-ui';
import { failedMessage } from 'modules/messages/messages';
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import './table-buttons.scss'

const TableButtons = ({badFitId, groupId, saveDisabled, saveHandler}) => {
    const dispatch = useDispatch()
    const [goodFitLoad, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [saving, setSaving] = useState(false)

    const goodFitHandler = async () => await requestHandler(setLoading, {goodForMonth: true})

    const deleteHandler = async () => {
        try {
            setDeleting(true) 
            await deleteBadFitItemRequest(badFitId)
            dispatch(deleteBadFitItem(badFitId, groupId))
        } catch {
            dispatch(showMessage(failedMessage))
        } finally {
            setDeleting(false)
        }
    }

    const requestHandler = async (setState, params) => {
        try {
            setState(true) 
            await updateBadFitItem(badFitId, params)
            dispatch(deleteBadFitItem(badFitId, groupId))
        } catch {
            dispatch(showMessage(failedMessage))
        } finally {
            setState(false)
        }
    }

    const inSaveDisabled = saveDisabled || saving
    return (
        <div className='table-buttons'>
            <Button 
                color="primary" 
                variant="raised" 
                className="save-btn"
                onClick={() => saveHandler(setSaving)}
                disabled={inSaveDisabled}
            >
                {
                    saving 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : "save"
                }      
            </Button>
            <Button 
                color="primary" 
                variant="raised" 
                className="good-fit-btn"
                onClick={goodFitHandler}
                disabled={goodFitLoad}
            >
                {
                    goodFitLoad 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : "good fit"
                }      
            </Button>
            <Button 
                color="secondary" 
                variant="raised" 
                onClick={deleteHandler}
                disabled={deleting}
            >
                {
                    deleting 
                        ? <FontAwesomeIcon icon={faSpinner} spin />
                        : "delete"
                }       
            </Button>
        </div>
    );
};

export default TableButtons;