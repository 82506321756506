import React from 'react';

import { Provider } from 'react-redux'

import RootRouter from './RootRouter'
import { store } from './storeConfig'

const Main = () => {
  
  return (
    <Provider store={store}>
      <RootRouter />
    </Provider>
  )
}

export default Main