import React from 'react';
import { getBadFitDate } from 'utils/utils';
import BadFitItem from '../bad-fit-item/BadFitItem';

import './grouped-items.scss'

const GroupedItems = ({date, items, groupId}) => {
    return (
        <>
            <article className="invoice-line-item header bad-fit-head">
                <span className="group-date">{getBadFitDate(date)}</span>
            </article>
            {
                items.map(item => (
                    <BadFitItem
                        groupId={groupId}
                        key={item.id}
                        id={item.id}
                        isGrey={item.isGrey}
                        clientName={`${item.firstName} ${item.lastName}`}
                        counsellorName={item.counsellor}
                        notes={item.notes}
                    />
                ))
            }
        </>
    );
};

export default GroupedItems;