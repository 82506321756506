import { getDateDifference } from "utils/utils";
import { alertTypes, ICBC, NON_DIRECT } from "./constants";

export const setDirectAlerts = (client, approvalPush = null) => {
    const {usedSessions, approvedSessions, expiryDate, dateOfApprovalCheck, alertsEnabled, paymentSource, isActive} = client
    
    const unUsedSessions = approvedSessions - usedSessions
    const dateDiff = expiryDate ? getDateDifference(expiryDate) : undefined 

    if (unUsedSessions === 2 || (dateDiff <= 14 && dateDiff > 7)) {
        client.alert = alertTypes.grey
        client.priority = 4
    } 
    
    if (unUsedSessions === 1 || (dateDiff <= 7 && dateDiff > 0)) {
        client.alert = alertTypes.yellow
        client.priority = 3
    } 
    
    if (unUsedSessions <= 0 || dateDiff < 1) {
        client.alert = alertTypes.red
        client.priority = 1
        
        if(approvalPush && !dateOfApprovalCheck && isActive) {
            approvalPush(client)
        }
    
    }

    if (dateOfApprovalCheck && paymentSource.profile !== NON_DIRECT) {
        client.alert = alertTypes.pink
        client.priority = 2
    }

    if (!alertsEnabled) {
        client.priority = 6
    }

    if (!client.priority) {
        client.priority = 5
    }
}

export const updateCounsellorClients = (clients) => {
    const updatedClients = {
        direct : {
            activeICBC: [],
            deactivatedICBC: [],
            activeOther: [],
            deactivatedOther: [],
        },
        nonDirect: {
            active: [],
            deactivated: []
        }
    }

    clients.forEach(client => {
        const {paymentSource, isActive, usedSessions, chargedSessions,  mutationRequest, alertsEnabled} = client
        
        if (mutationRequest) {
            client.alert = alertTypes.blue
        }
        
        if (paymentSource.profile !== NON_DIRECT) {
            setDirectAlerts(client)

            if (paymentSource.name.toLowerCase() === ICBC) {
                if (isActive) {
                    updatedClients.direct.activeICBC.push({...client})
                } else {
                    updatedClients.direct.deactivatedICBC.push({...client})
                }
            } else {
                if (isActive) {
                    updatedClients.direct.activeOther.push({...client})
                } else {
                    updatedClients.direct.deactivatedOther.push({...client})
                }
            } 
        } else {
            if (usedSessions > chargedSessions) {
                client.priority = 1
                client.alert = alertTypes.red
            }

            
            if (!alertsEnabled) {
                client.priority = 6
            }

            if (!client.priority) {
                client.priority = 2
            }

            if (isActive) {
                updatedClients.nonDirect.active.push({...client})
            } else {
                updatedClients.nonDirect.deactivated.push({...client})
            }
        }
    })

    return updatedClients
}