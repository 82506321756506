/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:39:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 14:54:53
 */

import {moduleName} from "./constants"
import * as _ from 'lodash'
import { FUTURE_PAID, MISC, OUTSTANDING } from "service/constants"

export const getInvoice = (state) => (state[moduleName])
export const getExportedInvoices = (state) => _.orderBy((state['exportedInvoices']), 'invoiceNumber')

export const outstandingItems = (appointments) => {
    const outstandingItems = []
    const futurePaidItems = []
    const miscItems = []

    appointments.forEach(appointment => {
        const listType = appointment.listType

        if (listType === OUTSTANDING) {
            outstandingItems.push(appointment)
        } else if (listType === FUTURE_PAID) {
            futurePaidItems.push(appointment)
        } else if (listType === MISC) {
            miscItems.push(appointment) 
        }
    });
    
    return {outstandingItems, futurePaidItems, miscItems}
}