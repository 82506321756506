/*
 * @Author: Mike Cottingham 
 * @Date: 2018-05-18 17:20:42 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 09:56:25
 */


import React, { Component } from "react"
import PropTypes from "prop-types"
import {InputAdornment,  TextField } from "material-ui"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import {faDollarSign} from "@fortawesome/fontawesome-free-solid"
import checkedImg from 'assets/img/checked.svg'

import core from "../../core"

class MiscLineItem extends Component {
  render() {
    const { data, onChange, onDelete, idx } = this.props
    return (
      <section className="invoice-line-item-container">
        <article className="invoice-line-item">
          <span className="flex-25" style={{flex: "0.5% 0.5", position: "relative"}}>
            {idx}
            <img src={checkedImg} className="checked-img"/>
          </span>
          <span className="flex-50">{data.description}</span>
          <span className="flex-25" style={{flex: "0.5% 0.5"}}>${Number(data.price).toFixed(2)}</span>
        </article>
        <div className="hover__menu">
          <TextField 
            type="text" 
            name="description" 
            defaultValue={data.description} 
            placeholder="Description" 
            onChange={onChange}
            fullWidth={true}
          />
          &nbsp;
          <TextField 
            type="number" 
            name="price" 
            defaultValue={data.price} 
            placeholder="Price" 
            onChange={onChange}
            InputProps={{
              startAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faDollarSign} /> &nbsp;</InputAdornment>
            }}
            fullWidth={true}
          />
          <core.components.DeleteButton onClick={onDelete} color="primary"/>
        </div>
      </section>
    )
  }
}

MiscLineItem.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  idx: PropTypes.number
}

export default MiscLineItem