export const nonDirectDillClients = ['low cost', 'private', 'no source']

export const directBillComment = "These sessions will show up on your future invoice once paperwork is caught up."
export const notDirectBillComment = "These sessions will show up on your future invoice once sessions have been charged."
export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const phoneRegex = /^(\+1)(\d{2})(\d{3})(\d{2})(\d{3})$/
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const decimalRegex = /^(-?\d+(.\d{1,2})?)$/

export const alertTypes = {
  red: "red",
  blue: "blue",
  yellow: "yellow",
  grey: "grey",
  pink: "pink"
} 
export const legendTypes = {
  submited: "Submitted",
  noApproval: "No approval",
  noPayment: "No payment",
  partialCharge: "Partial charge",
  request: "Request to activate/ deactivate"
}

export const CLINICAL_RECORDS = "Clinical Records"
export const PROGRESS_REPORT = "Progress Report"
export const INITIAL_REPORT = "Initial Report"

export const PAST = "past"

export const FUTURE_PAID = "Future"
export const DEFAULT = "Default"
export const MISC = "Misc"
export const OUTSTANDING = "Outstanding"

export const DIRECT_TYPE = "Direct bill client"
export const NON_DIRECT_TYPE = "Non direct bill client"

export const NO_PAYMENT_STATUS = "No payment"
export const NO_APPROVAL_STATUS = "No approval"

export const MAX_DATE = "2100-01-01"
export const MIN_DATE = "1900-01-01"

export const SETTINGS_FOR_EXPORT = {
    fileName: 'Exported Invoices',
    workSheets: [
      {
        sheetName: 'Exported invoices',
        startingRowNumber: 1,
        tableSettings: {
          data: {
            headerDefinition: [
              {
                type: "text",
                name: 'Invoice Number',
                key: 'invoiceNumber',
                width: 18
              },
              {
                name: 'Claim Number',
                key: 'claimNumber',
                width: 18
              },
              {
                name: 'Invoice Date',
                key: 'invoiceDate',
                width: 20
              },
              {
                name: 'Client',
                key: 'client',
                width: 30,
              },
              {
                name: 'Therapist',
                key: 'therapist',
                width: 30,
              },
              {
                name: 'Number of Appointments',
                key: 'numberOfAppointments',
                width: 25,
              },
              {
                name: 'Invoice Total',
                key: 'invoiceTotal',
                width: 18,
                alignment: 'left',
                caption: 'text',
                type: "string",
              },
            ],
          },
        },
      },
    ],
  };

export const PHONE_INPUT_LABEL = "The phone number needs to be a cell phone that can receive text messages"

export const TASKS_TO_SHOW = 8
export const SET_TASKS = 8
export const COMPLETED_TASKS_TO_SHOW = 4
export const SET_COMLETED = 16
export const BAD_ITEMS_TO_SHOW = 1

export const SCROLL_TO = 120
export const TASK_SCROLL_TO = 150

export const ITEMS_TO_EMAIL_ZIP = 200

//routers
export const ADMIN_ROUTE = "/admin"
export const COUNSELLOR_PAGE_ROURE = "/counsellor-page"
export const COUNSELLOR_PAY_ROUTE = "/counsellors"
export const INVOICING_MANAGMENT_ROURE = "/"
export const RATES = "/rates"

export const APPROVAL = "Approval"
export const NOT_PAID = "Not paid"
export const PARTIALLY_PAID = "Partially paid"

export const ALERT_CLIENTS_COUNT = 8

export const MAX_PERCENT = 100
export const MIN_PERCENT = 0

export const ICBC = "icbc"
export const LAWYER = "lawyer"
export const DTP = "direction to pay"
export const CVAP = "cvap"
export const WALMSLEY = "Walmsley"
export const FNHA = "fnha"
export const PRIVATE_EXTENDED = "private/extended"
export const LOW_COST = "low cost"
export const CFSEU = "cfseu"

export const COUNSELLOR_TYPE_FOR_EXCLUDE = "ma cand"

export const RECEIPT = "Receipt"
export const INVOICE = "Invoice"

export const PAYMENT_SOURCES = [
  "Private/Extended Health", 
  "Low Cost (practicum/intern rate)", 
  "ICBC", 
  "Lawyer", 
  "Direction to Pay (DTP)", 
  "CFSEU", 
  "Autism", 
  "YMCA", 
  "Work BC", 
  "FNHA", 
  "Walmsley", 
  "CVAP", 
  "Other"
]

export const REQUEST_DATE_FORMAT = "YYYY-MM-DD"
export const BAD_FIT_DATE_FORMAT = "MMM YYYY"
export const APP_DATE_FORMAT = "MMM DD, YYYY"
export const RECEIPT_DATE_FORMAT = "MMM DD YY"

export const NO_CHANGE = "no-change"
export const SET_RATE = "set-rate"

export const NON_DIRECT = "Non-direct"
export const DIRECT = "Direct"
export const DIRECT_WITH_TOP_UP = "Direct with top-up"