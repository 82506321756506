/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:56:37 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-24 02:29:46
 */

import React from 'react';
import components from './components'


const CallToAction = (props) => {
  return (
    <components.FullWidthRow className='background__default text-light'>
      <components.Container>
        <section className='layout__call-to-action'>
          {props.children}
        </section>
      </components.Container>
    </components.FullWidthRow>
  )
}

export default CallToAction