import DirectClients from 'modules/admin/counsellors/counsellor-expanded/direct-cients/DirectClients';
import NonDirectClients from 'modules/admin/counsellors/counsellor-expanded/non-direct-clients/NonDirectClients';
import React from 'react';
import { useSelector } from 'react-redux';
import SectionHead from 'ui-components/SectionHead';
import { showSection } from 'utils/utils';

import './clients.scss'

const Clients = () => {
    const clients = useSelector(state => state.counsellor.clients)
    const {direct, nonDirect} = clients
    
    return (
        <div className="counsellor-clients">
            <SectionHead head={"Clients"}/>
            {
                showSection(direct)  
                    ? <DirectClients 
                        clients={direct}
                        counsellorPage={true}    
                        />
                    : null
            }
            
            {
                showSection(nonDirect)  
                    ? <NonDirectClients 
                        clients={nonDirect}
                        counsellorPage={true}   
                      />
                    : null
            }
        </div>
    );
};

export default Clients;