/*
 * @Author: Mike Cottingham
 * @Date: 2018-04-25 16:14:45
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-15 20:07:03
 */
import * as actions from '../../invoice/actions'
import {useState} from 'react'
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { Checkbox, TextField } from "material-ui";
import PropTypes from "prop-types";
import React from "react";
import model from "../../../model";
import styled from "styled-components";
import {Button  } from "material-ui";
import { CLINICAL_RECORDS, NON_DIRECT, PROGRESS_REPORT } from '../../../service/constants.js'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { changeSource } from '../actions';
import { updateClientInfo } from 'service/api';
import { showMessage } from 'modules/messages/actions';
import { failedMessage } from 'modules/messages/messages';

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions
  }, dispatch)
)

const stateToProps = (state, props) => {
  return {}
}

const ListItem = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cdcdcd;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
    background: #c7c7c7 !important;
  }
`;

const StyledP = styled.div`
  display: block;
  text-align: left;
  font-weight: bold;
  margin: 0.3rem;
`;

const Count = styled(StyledP)`
  flex: 0.3;
`;

const Name = styled(StyledP)`
  flex: 1.8;
`;

const PaymentIndicator = styled(StyledP)`
  flex: 0.5
`; 

const AppointmentType = styled(StyledP)`
  flex: 4.5
`; 

const PaymentSource = styled(StyledP)`
  flex: 3
`;
const ClientAppointmentListItem = ({ clientAppointment, onClick, onChange, selectedItems, singleExport }) => {
  const fullyPaid = clientAppointment.totalPaid === clientAppointment.appointments.length
  const isDirectBill = clientAppointment.paymentSource.profile !== NON_DIRECT
  const isAdmin = useSelector(state => state.auth.profile.isAdmin)
  const paymentSources = useSelector(state => state.admin.sources)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [paymentSource, setSource] = useState(clientAppointment.paymentSource.name)
  const [saveLoading, setSaveLoading] = useState(false)

  const exportHandler = async (e) => {
    e.stopPropagation()
    setLoading(true)
    await singleExport(clientAppointment)
    setLoading(false)
  }

  const fieldClick = (e) => e.stopPropagation()
  const selectHandler = (e) => setSource(e.target.value)

  const saveHandler = async (e) => {
    fieldClick(e)
    
    try {
      setSaveLoading(true)
      await updateClientInfo({id: clientAppointment.client.clientId, paymentSource: paymentSource.trim()})
      dispatch(changeSource([...clientAppointment.appointments], paymentSource.trim()))
    } catch {
      dispatch(showMessage(failedMessage))
    } finally {
      setSaveLoading(false)
    }
  }

  const showExportElements = isAdmin && (isDirectBill || clientAppointment.isReport)
  const checked = !!selectedItems.find(el => el.id === clientAppointment.id)

  const trimSource = paymentSource.trim()
  const saveDisabled = saveLoading 
    || trimSource === clientAppointment.paymentSource.name.trim() 
    || trimSource.toLowerCase() === PROGRESS_REPORT.toLowerCase() 
    || trimSource.toLowerCase() ===  CLINICAL_RECORDS.toLowerCase()

  const availablePaymentSources = paymentSources.filter(el => el.paymentSource.isActive || clientAppointment.paymentSource.name === el.name)
  return (
    <ListItem
      onClick={onClick}
      style={{
        color: !isDirectBill && fullyPaid > 0 ? "white" : 'inherit',
        background: !isDirectBill && fullyPaid ? "#7eaf62" : 'inherit',
      }}
    >
      {
        showExportElements && !clientAppointment.isReport &&
        <Checkbox 
        className="export-checkbox"
        onClick={(e) => onChange(e, clientAppointment, checked)}
        checked={checked}
      />
      }
      <Count>({clientAppointment.appointments.length})</Count>
      {
        !isDirectBill && !clientAppointment.isReport && <PaymentIndicator>{clientAppointment.totalPaid !== 0 && `${clientAppointment.totalPaid} Paid`}</PaymentIndicator>
      } 
      <AppointmentType>
        {clientAppointment.appointments[0].type}
      </AppointmentType>
      <Name>
        {clientAppointment.client.firstName} {clientAppointment.client.lastName}
      </Name>
      
      <PaymentSource>
        {
          !clientAppointment.isReport &&
            <TextField
              select
              className="payment-source-select"
              value={paymentSource}
              onChange={selectHandler}
              onClick={fieldClick}
              SelectProps={{
                  native: true,
              }}
              margin="normal"
            >
              {
                  availablePaymentSources.map(source => (
                      <option key={source.paymentSource.name} value={source.paymentSource.name}>
                          {source.paymentSource.name}
                      </option>
                  ))
              }

            </TextField>
       } 
      
        {isDirectBill} 
      </PaymentSource>
      {
        !clientAppointment.isReport &&
          <Button  
            color="primary" 
            variant="raised" 
            className="single-export-btn"
            onClick={saveHandler}
            disabled={saveDisabled}
          >
            {
              loading 
                ? <FontAwesomeIcon icon={faSpinner} spin />
                : "Save"
                
            }
          </Button >
      }
      
      {
        showExportElements && 
          <Button  
            color="secondary" 
            variant="raised" 
            className="single-export-btn" 
            onClick={exportHandler}
            disabled={loading}
          >
            {
              loading 
                ? <FontAwesomeIcon icon={faSpinner} spin />
                : "Export"
                
            }
          
          </Button >
      } 
    </ListItem>
  );
};

ClientAppointmentListItem.propTypes = {
  data: model.AppointmentShape,
  onClick: PropTypes.func,
};

export default connect(stateToProps,dispatchToProps)(ClientAppointmentListItem);
