import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { generateDate, getEmailText, onEmailChange } from '../../../utils/utils';
import { useDispatch } from 'react-redux';
import { showMessage } from 'modules/messages/actions';
import { sendEmailInvoice } from 'service/api';

import {Button} from 'material-ui'
import trash from '../../../assets/img/trash.svg'
import fileImg from '../../../assets/img/file.svg'
import close from '../../../assets/img/close.svg'
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { emailSuccessMessage, failedMessage } from 'modules/messages/messages';

import "./pop-up.scss"

const EmailPopUp = ({calendar, updateOutstandingItems, exportHandler, closeHandler, file, text, updateText, textLoading, updateMiscItems}) => {
    const dispatch = useDispatch()
    const {name, email, outstanding, misc, appointments } = calendar
    const date = generateDate()
    const fileName = `${date} - ${name.split(" ")[0]}.pdf`
    
    const [loading, setLoading] = useState(false)
    const [emailText, setText] = useState(text)
    const [emailChange, setEmail] = useState(email)
    const [title, setTitle] = useState(`Invoice ${date}`)

    const textareRef = useRef(null)

    useEffect(() => {
        resizeTextarea()
    }, [])
    
    const refreshEmail = (e) => {
        e.preventDefault()
        setEmail('')
    }

    const onChange = (e, setState) => {
        setState(e.target.value)
    }

    const updateEmailHandler = () => {
        updateText(emailText.trim())
    }

    const emailHandler = async () => {
        try {
            setLoading(true)
            const appointmentIDs = [...outstanding, ...misc, ...appointments].map((item) => item.acuityID)
            const fullText = getEmailText(name, date, emailText)
            await sendEmailInvoice({email: emailChange, text: fullText, title, file, fileName, invoice_app_ids : appointmentIDs})
            await exportHandler()
            updateMiscItems(null, true)
            updateOutstandingItems()
            closeHandler()
            dispatch(showMessage(emailSuccessMessage))
        } catch(e) {
            console.log(e)
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
    }

    const resizeTextarea = () => textareRef.current.style.height = textareRef.current.scrollHeight  + "px"

    const isBtnDisabled = !emailChange || !file || loading

    return (
        <div className='email-pop-up'>
            <form>
                <button 
                    className="icon-button close-button"
                    onClick={closeHandler}
                >
                    <img className="close-button" src={close} alt="close-button"/>
                </button>
                
                <div className='form-wrapper'>
                    <div className="input-wrapper">
                        <span className="input-head">Email</span>
                        <input 
                            value={emailChange} 
                            onChange={e => onEmailChange(e.target.value, setEmail)}
                        />
                        <button 
                            className="icon-button delete-btn"
                            onClick={refreshEmail}
                        >
                            <img src={trash} alt=""/>
                        </button>
                    </div>
                    <div className="input-wrapper">
                        <span className="input-head">Title</span>
                        <input 
                            value={title}
                            onChange={e => onChange(e, setTitle)}
                        />
                    </div>
                    <div className="input-wrapper">
                        <div className="textarea-wrapper">
                            Hi {name},<br/><br/>
                            Your {date} invoice is now available. 
                            <br/>
                            <textarea
                                ref={textareRef}
                            // rows={emailText.length /33}
                                value={emailText}
                                onChange={e => onChange(e, setText)}
                                onInput={resizeTextarea}
                            />
                        </div>
                        <Button 
                            color="secondary" 
                            variant="raised" 
                            className="save-btn"
                            disabled={text === emailText.trim() || textLoading}
                            onClick={updateEmailHandler}
                        >
                            { textLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save"}
                        </Button>
                    </div>
                    <div className="file-name">
                        <span className="file-head">FILE</span>
                        <div className="file">
                            <img src={fileImg} alt=""/>
                            <span>{fileName}</span>
                        </div>
                    </div>
                </div>
                <Button 
                    color="secondary" 
                    variant="raised" 
                    className="send-btn"
                    disabled={isBtnDisabled }
                    onClick={emailHandler}
                >
                    { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Send"}
                </Button>
            </form>
        </div>
    );
};

export default EmailPopUp;