import NoItems from 'modules/core/components/NoItems';
import Container from 'modules/layout/components/Container';
import FullWidthRow from 'modules/layout/components/FullWidthRow';
import React from 'react';
import CounsellorFundingPanel from './CounsellorFundingPanel';

const CounsellorAppointmentsList = ({clientAppointments, onClick}) => {
    const clints = Object.keys(clientAppointments).map(key => {
        const client = clientAppointments[key]

        return (
            <CounsellorFundingPanel 
              appointments={client.appointments}
              key={client.client.id} 
              title={client.client.fullName} 
              id={key} 
              onClick={onClick}
            />
      
          )
    })
  
      return (
        <FullWidthRow>
          <Container>
            {clints.length ? clints : <NoItems/>}
          </Container>
        </FullWidthRow>
      )
};

export default CounsellorAppointmentsList;