import React from 'react';
import FullWidthRow from 'modules/layout/components/FullWidthRow'
import Container from 'modules/layout/components/Container';
import CounsellorItem from '../counsellor-item/CounsellorItem';

import './counsellor-list.scss'
import { useSelector } from 'react-redux';

const CounsellorList = () => {
    const counsellors = useSelector(state => state.admin.counsellors)

    return (
        <FullWidthRow>
            <Container className="flex wrap counsellor-list admin">
                {
                    counsellors?.map((counsellor, i) => (
                        <CounsellorItem
                            key={counsellor.id}
                            counsellor={counsellor}
                        />
                    ))
                }
            </Container>
        </FullWidthRow>
    );
};

export default CounsellorList;

