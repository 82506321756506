import React from 'react';
import { Paper } from 'material-ui'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'

import layout from "../../layout"


const Loading = () => {
  return (
    <layout.components.FullWidthRow>
        <layout.components.Container>
          <Paper elevation={4} className="alert__info">
            <h1>
              <FontAwesomeIcon icon={faSpinner} spin />
            </h1>
            <p>Loading...</p>
          </Paper>
        </layout.components.Container>
    </layout.components.FullWidthRow>
  )
}

export default Loading