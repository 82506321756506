/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:06:31 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 13:20:30
 */

export const moduleName = "pages"

export const searchLabel = "Search appointments"

export const tabs = {
  clientAppointments: "Client Appointments",
  preview: "Preview",
  exportReport: "Exported Invoices",
}

export const pages = {
  clientInvoice: "",
  counsellorInvoice: "/counsellors",
  login: "/login",
  adminHomePage: '/admin',
  counsellorPage: '/admin/counsellors/:id',
  appointmentRates: '/rates'
}