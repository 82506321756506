/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 22:56:04 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-23 22:57:20
 */
import React from 'react'

const Separator = () => {
  return (
    <div className={'nav__separator'}>
    </div>
  )
}

export default Separator