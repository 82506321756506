/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-08-05 17:13:16
 */

import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Paper } from 'material-ui'
import Tabs, { Tab } from 'material-ui/Tabs'


import appointment from "../appointment"
import auth from "../auth"
import core from "../core"
import invoice from "../invoice"
import layout from "../layout"
import components from "./components"
import * as constants from "./constants"

import Logo from "../../assets/img/logo.png"
import CounsellorAppointmentsList from "modules/appointment/components/counsellor/CounsellorAppointmentsList"
import ClientAppointmentList from "modules/appointment/components/ClientAppointmentList"
import { getClientCounsellors } from "modules/appointment/components/counsellor/selectors"
import { calcTotals } from "utils/utils"
import { fetchPaymentSources } from "modules/admin/redux/actions"


class ClientInvoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      selectedTab: constants.tabs.clientAppointments,
      scrollPosition: 0,
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onReset = this.onReset.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.onClientAppointmentClick = this.onClientAppointmentClick.bind(this)
    this.onCounsellorClientClick = this.onCounsellorClientClick.bind(this)
  }

  async onSubmit(search) {
    this.setState({ loading: true });
    await this.props.fetchPaymentSources()
    //TODO: Split the search into first/last name if desired
    this.props.search(search, this.props.authToken)
      .then(
        () => this.setState({ selectedTab: constants.tabs.clientAppointments, loading: false })
      )
  }

  onReset() {
    this.setState({ selectedTab: constants.tabs.clientAppointments, scrollPosition: 0 })
  }

  changeTab(event, selectedTab) {
    this.setState({ selectedTab });
    if(selectedTab === constants.tabs.clientAppointments && this.state.scrollPosition !== 0) {
      window.setTimeout(() => { window.scrollTo(0, this.state.scrollPosition) })
    }
  }

  onClientAppointmentClick(clientAppointment, invoiceNumber) {
    const { generateInvoice, nextInvoiceNumber } = this.props
    if (invoiceNumber) {
      core.utils.setLocalStorage('invoiceNumber', invoiceNumber)
    } else {
      core.utils.setLocalStorage('invoiceNumber', nextInvoiceNumber)
    }

    generateInvoice(clientAppointment)
    this.setState({ selectedTab: constants.tabs.preview, scrollPosition: window.scrollY })
  }

  onCounsellorClientClick(e, selectedAppointments) {
    e.stopPropagation()

    const {email, firstName, lastName, claimNumber, client,  paymentSource, phone, id} = selectedAppointments[0]
    const invoice = {
      client: {
        claimNumber,
        clientId: client.id,
        email,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        paymentSource,
        phone
      },
      appointments: selectedAppointments,
      counsellors: getClientCounsellors(selectedAppointments),
      icbcClaimNumber: claimNumber,
      id,
      paymentSource
    }

    calcTotals(invoice, selectedAppointments)
    
    this.props.generateInvoice(invoice)
    this.setState({ selectedTab: constants.tabs.preview, scrollPosition: window.scrollY })
  }

  render() {
    const { appointments, clientAppointments, invoice: propInvoice, exportedInvoices, isAdmin } = this.props
    
    return (
      <layout.components.FullWidthRow>
        <layout.components.Container>
          <Paper elevation={4} className="container__search">
            <img src={Logo} alt={core.constants.imageAlt} style={{maxWidth: '200px'}}/>
            <h1>Search Appointments</h1>
            <components.ClientAppointmentSearch onSubmit={this.onSubmit} onReset={this.onReset} loading={this.state.loading}/>
            <br />
            {
              !this.state.loading ? (
                <section>
                  <Tabs value={this.state.selectedTab} onChange={this.changeTab} textColor={'secondary'} centered={true}>
                    {appointments.length && <Tab label={`${constants.tabs.clientAppointments} (${appointments.length})`} value={constants.tabs.clientAppointments} />}
                    {appointments.length && propInvoice && <Tab label={constants.tabs.preview} value={constants.tabs.preview} />}
                    {isAdmin && <Tab value={constants.tabs.exportReport} label={`${constants.tabs.exportReport} (${exportedInvoices.length})`} />}
                  </Tabs>
                  {
                    this.state.selectedTab === constants.tabs.clientAppointments ?
                      isAdmin 
                        ? <ClientAppointmentList clientAppointments={clientAppointments} onClick={this.onClientAppointmentClick} />
                        : <CounsellorAppointmentsList clientAppointments={clientAppointments} onClick={this.onCounsellorClientClick} />
                      : null
                  }
                  {
                    (this.state.selectedTab === constants.tabs.preview) && <invoice.components.ClientPreview invoice={propInvoice} />
                  }

                  {
                    (this.state.selectedTab === constants.tabs.exportReport && isAdmin) && <invoice.components.ExportReport onClick={this.onClientAppointmentClick} />
                  }
                </section>
              )
                : <core.components.Loading />
            }
          </Paper>
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

ClientInvoice.propTypes = {
  authToken: PropTypes.string,
  search: PropTypes.func,
}

const stateToProps = (state) => {
  const exportedInvoices = invoice.selectors.getExportedInvoices(state)
  const highestInvoice = Math.max(...invoice.selectors.getExportedInvoices(state).map(i => Number(i.invoiceNumber)))
  return {
    authToken: auth.selectors.getAuthToken(state),
    appointments: appointment.selectors.getAppointments(state),
    clientAppointments: appointment.selectors.clientAppointmentsByName(state),
    invoice: invoice.selectors.getInvoice(state),
    exportedInvoices,
    nextInvoiceNumber: highestInvoice !== -Infinity ? highestInvoice + 1 : core.utils.readLocalStorage('invoiceNumber'),
    isAdmin: state.auth?.profile?.isAdmin
  }
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...invoice.actions,
    ...appointment.actions,
    fetchPaymentSources: fetchPaymentSources
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(ClientInvoice)