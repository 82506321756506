/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 22:49:42 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-04-09 13:54:23
 */

import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import { TextField } from "material-ui"
import { faEdit } from "@fortawesome/fontawesome-free-solid"
import { Button } from "material-ui"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'

const Meta = ({title, data, editable, name, placeholder, onChange, onClick, dataForEdit, loading}) => {
  const ableForSave = title === "Total Billed" || title === "Invoice Number"

  return (
    <article className="invoice-meta">
      <b>{title}</b><br />
      <span>{data}</span>
      {
        editable && 
        <div className="hover__menu">
          <b><FontAwesomeIcon icon={faEdit} /> {title}</b>
          <ul>
            <li>
              <TextField 
                className="hover__menu-input"
                placeholder={placeholder} 
                name={name} 
                value={dataForEdit} 
                onChange={onChange}
                type={ableForSave ? "number" : "text"} 
                min={0}
              />
            </li>
            {
              ableForSave && 
                <Button
                  className="save-button"
                  color="primary" 
                  variant="raised" 
                  onClick={onClick}
                  disabled={loading || data == dataForEdit}
                >
                  { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save"}
                </Button>
            }
          </ul>
        </div>
      }
    </article>
  )
}

Meta.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Meta