/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-05-02 11:38:32
 */

import ClientAppointmentListItem from "./ClientAppointmentListItem"
import FundingExpansionPanel from "./FundingExpansionPanel"
import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import core from "../../core"
import layout from "../../layout"
import model from "../../../model"
import { moduleName } from "../constants"
import { CLINICAL_RECORDS, NON_DIRECT, PROGRESS_REPORT } from '../../../service/constants.js'
import "../appointment.css"
import { calcTotals, includes } from "utils/utils"

class ClientAppointmentList extends React.Component {
  constructor(props) {
    super(props)
  }

  onClick(clientAppointment, e) {
    e.preventDefault()
    this.props.onClick(clientAppointment)
  }

  render() {
    const { clientAppointments } = this.props
    const funders = _.map(_.uniqBy(_.map(clientAppointments), a => a.paymentSource.name), ca => ca.paymentSource.name).sort()
    funders.push(PROGRESS_REPORT,  CLINICAL_RECORDS)
    
    const groups = funders.map((source, i) => {
      
      let appointments = []

      for(let key in clientAppointments) {
        const client = {...clientAppointments[key]}

        if(source === PROGRESS_REPORT || source === CLINICAL_RECORDS) { 
          const newAppointments = client.appointments.filter(el => el.type.toLowerCase().includes(source.toLowerCase()))
          
          if (newAppointments.length) {
            calcTotals(client, newAppointments)
            client.appointments = newAppointments
            client.isReport = true
            appointments.push(client)
          }
        } else {
            if (client.paymentSource.name === source) {
            const newAppointments = client.appointments.filter(el => !includes(el.type, CLINICAL_RECORDS, PROGRESS_REPORT))
            
            if (newAppointments.length) {
              calcTotals(client, newAppointments)
              client.appointments = newAppointments
              appointments.push(client)
            }
          }
        }
      }

      appointments.sort((a, b) => a.client.firstName.localeCompare(b.client.firstName))
      const listItems = _.map(appointments, (clientAppointment, i) => (
        <ClientAppointmentListItem 
          clientAppointment={clientAppointment} 
          key={`${moduleName}-${i}`} 
          onClick={this.onClick.bind(this, clientAppointment)}
        />
      ))
      
      return (
        appointments.length ?
          <FundingExpansionPanel 
            appointments={appointments}
            key={`${source}-${i}`} 
            title={`${source || "No Funding Source"} (${listItems.length})`} 
            source={source}
            items={listItems} 
            isActive={appointments[0].paymentSource.isActive}
            id={source} 
            isDirectBill={source.profile !== NON_DIRECT}
            onClick={(appointment) => this.onClick.bind(this, appointment)}
          />
          : null
      )
    })
    groups.sort((a, b) => b?.props.isActive - a?.props.isActive)
    const groupsToShow = groups.filter(el => el !== null)
    return (
      <layout.components.FullWidthRow>
        <layout.components.Container>
          {groupsToShow.length ? groupsToShow : <core.components.NoItems />}
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

ClientAppointmentList.propTypes = {
  clientAppointments: PropTypes.objectOf(model.ClientAppointmentShape),
  onClick: PropTypes.func
}

export default ClientAppointmentList