/*
 * @Author: Mike Cottingham 
 * @Date: 2018-05-07 13:06:00 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-10-05 14:49:09
 */

import PropTypes from 'prop-types'
import { TextField, Paper } from "material-ui"
import { InputAdornment } from "material-ui/Input"
import React, { Component } from "react"
import {connect} from "react-redux"
import { bindActionCreators } from "redux"

import auth from "../../auth"
import client from "../../client"
import core from "../../core"
import model from "../../../model"

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckSquare } from '@fortawesome/fontawesome-free-solid';

class ClientSearch extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      visible: false,
      pendingRequest: null
    }
    this.onClick = this.onClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange({target: {value}}) {
    const { authToken, onChange, search, reset } = this.props
    
    if(this.state.pendingRequest) {
      this.state.pendingRequest.cancel()
    }
    
    if(value.length > 1) {  
      this.setState({pendingRequest: core.generateCancelToken()}, () => {
        search({search: value}, authToken, this.state.pendingRequest.token)
        .then(
          () => this.setState({pendingRequest: null}),
          () => this.setState({pendingRequest: null})
        )
      })
    } else {
      reset()
    }
    onChange(value);
  }

  onClick(client) {
    const { select, onChange, value } = this.props
    select(client)
    onChange(value, client)
  }

  onFocus(e) {
    this.setState({visible: true})
  }

  onBlur(e) {
    window.setTimeout(() => this.setState({visible: false}), 200)
  }
  
  render() {
    const { clients, className, selected, value } = this.props
  
    return (
      <div className={className}>
        <TextField
          className="flex-87-5 standard-margin"
          id="search"
          label={"Client Search"}
          type="text"
          name="search"
          margin="normal"
          InputProps={{
            endAdornment: this.state.pendingRequest !== null && <InputAdornment position="end"><FontAwesomeIcon icon={faSpinner} spin /></InputAdornment>
          }}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          value={value}
        />
        {
          (Array.isArray(clients) && clients.length > 0) ?
            <Paper extension={4} className="form__autocomplete" style={{ opacity: this.state.visible ? '1' : '0', display: this.state.visible ? 'inherit' : 'none'}}>
              <client.components.List clients={clients} onClick={this.onClick} />
            </Paper> : null
        }
      </div>
    )
  }
}

ClientSearch.propTypes = {
  authToken: PropTypes.string,
  clients: PropTypes.oneOfType([PropTypes.arrayOf(model.ClientShape), model.ClientShape]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  selected: PropTypes.bool
}

const stateToProps = (state) => ({
  authToken: auth.selectors.getAuthToken(state),
  clients: client.selectors.getClients(state)
})

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...client.actions
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(ClientSearch)