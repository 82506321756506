import React from 'react';
import { useSelector } from 'react-redux';
import { APPROVAL, NOT_PAID, PARTIALLY_PAID } from 'service/constants';
import Alert from '../alert/Alert';
import noApprovalImg from "assets/img/circle.svg"
import noPaidImg from "assets/img/red.svg"
import partPaidImg from "assets/img/orange.svg"
import './alert-list.scss'

const AlertList = ({}) => {
    const alerts = useSelector(state => state.counsellor.dashboard)
    const {approval, notPaid, partPaid} = alerts
    
    return (
        <div className='alert-list'> 
            <Alert 
                head={APPROVAL} 
                clients={approval} 
                headImg={noApprovalImg}
                key={APPROVAL}
            />
            <Alert 
                head={NOT_PAID} 
                clients={notPaid} 
                headImg={noPaidImg}
                key={NOT_PAID}
            />
            <Alert 
                head={PARTIALLY_PAID} 
                clients={partPaid} 
                headImg={partPaidImg}
                key={PARTIALLY_PAID}
            />
        </div>
    );
};

export default AlertList; 