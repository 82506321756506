import React from 'react';

const SectionHead = ({head, className}) => {
    return (
        <h3 className={`section-head ${className || ''}`}>
            {head} 
        </h3>
    );
};

export default SectionHead;