/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 13:46:38 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 14:04:18
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { ToastMessageAnimated } from 'react-toastr'

import * as actions from '../actions'
import * as selectors from '../selectors'

class Container extends Component {
  render() {
    const { data, hideMessage } = this.props
    return (
      <div id="toast-container" className='toast-top-right'>
      {
        data ?
        <ToastMessageAnimated 
          message={data.message} 
          title={data.title} 
          type={data.type}
          onRemove={hideMessage}
          onCloseClick={hideMessage}
          onClick={hideMessage}
        /> : 
        null
      }
      </div>
    )
  }
}

Container.propTypes = {
  data: PropTypes.object
}

const mapStateToProps = (state) => ({
  data: selectors.getMessage(state)
})
const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions
  }, dispatch)
)

export default connect(mapStateToProps, dispatchToProps)(Container)