/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-18 10:18:30
 */


import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import _ from "lodash"

import core from "../../core"
import layout from "../../layout"
import model from "../../../model"

import ListItem from "./ListItem"
import { moduleName } from "../constants"
import { COUNSELLOR_TYPE_FOR_EXCLUDE } from "service/constants"

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.onClick = this.onClick.bind(this)
  }

  onClick(calendar) {
    const { onClick } = this.props
    onClick(calendar)
  }

  render() {
    const { calendars, minDate, maxDate } = this.props
    const filteredCalendars = calendars.filter(calendar => !calendar.name.toLowerCase().includes(COUNSELLOR_TYPE_FOR_EXCLUDE))
    const listItems = filteredCalendars.map(calendar => (
      <ListItem 
        minDate={minDate}
        maxDate={maxDate}
        calendar={calendar} 
        key={`${moduleName}-${calendar.acuityID}`} 
        onClick={this.onClick.bind(this, calendar)} 
      />
    ))

    return(
      <layout.components.FullWidthRow>
        <layout.components.Container className="flex wrap counsellor-list">
          {listItems.length ? listItems : <core.components.NoItems />}
        </layout.components.Container>
      </layout.components.FullWidthRow>
    )
  }
}

List.propTypes = {
  calendars: PropTypes.arrayOf(model.Calendar),
  onClick: PropTypes.func
}

const dispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
)

export default connect(null, dispatchToProps)(List)