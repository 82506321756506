/*
 * @Author: Mike Cottingham 
 * @Date: 2019-01-22 21:15:10 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-22 21:41:27
 */

import React from "react"
import * as appActions from '../../actions'
import * as actions from '../../../invoice/actions'

import _ from "lodash"

import { useState } from 'react'
import { Typography } from "material-ui"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {getIdByDate} from 'utils/utils'

import { faChevronDown } from "@fortawesome/fontawesome-free-solid"
import styled from 'styled-components'
import layout from "../../../layout"
import { Checkbox, ExpansionPanel, Button } from "material-ui"
import { ExpansionPanelDetails } from "material-ui"
import { ExpansionPanelSummary } from "material-ui"
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import CounsellorAppointmentItem from "./CounsellorAppointmnetItem"

const dispatchToProps = (dispatch) => (
  bindActionCreators({
    ...actions,
    ...appActions
  }, dispatch)
)

const stateToProps = (state, props) => {
  return {
    expanded: state.expansionPanel[props.id] || false
  }
}

const Details = styled(ExpansionPanelDetails)`
  padding: 0 0 1rem 0;
`

const Summary = styled(ExpansionPanelSummary)`
  padding: 0 1rem;
  margin: 0 !important;
`

const FundingExpansionPanel = ({collapse, expand, id, title, onClick, isDirectBill, appointments, exportInvoices, expanded, ...props}) => {
  const [selected, setSelected] = useState([])
  
  const checkboxHandler = (e) => {
    e.stopPropagation()
    expand(id)
    if (selected.length === listItems.length) {
      setSelected([])
    } else {
      setSelected([...appointments])
    }
    
  }

  const onChange = (e, appointment, checked) => {
    e.stopPropagation()
    
    if (checked) {
      const newSelected = selected.filter(el => el.id !== appointment.id)
      setSelected([...newSelected])
    } else {
      setSelected([...selected, appointment])
    }
    
  }

  const expandHandler = (e, exp) => {
    exp ? expand(id) : collapse(id)
  }

  const listItems = appointments.sort((a, b) => getIdByDate(b.date) - getIdByDate(a.date)).map((clientAppointment, i) => (
    <CounsellorAppointmentItem 
      clientAppointment={clientAppointment} 
      selectedItems={selected}
      key={`${clientAppointment.id}-${i}`} 
      onChange={onChange}
    />
  ))
  
  return (
    <ExpansionPanel expanded={expanded} onChange={expandHandler}
    classes={{
      expanded: {
        margin: 0,
        minHeight: 'auto !important',
      }
    }}>
      <Summary expandIcon={<FontAwesomeIcon icon={faChevronDown} /> }>
        <Typography component={'span'}>
            <Checkbox 
                className="select-all-checkbox"
                onClick={checkboxHandler}
                checked={selected.length === listItems.length}
            />
          {title} ({listItems.length})
        </Typography>
            <Button 
              color="secondary" 
              variant="raised" 
              className="export-all-btn" 
              onClick={(e) => onClick(e, selected)}
              disabled={!selected.length}
            >
              Generate receipt
            </Button>
      </Summary>
      <Details>
        <layout.components.Container>
          {listItems}
        </layout.components.Container>
      </Details>
    </ExpansionPanel>
  )
}

export default connect(stateToProps,dispatchToProps)(FundingExpansionPanel)