import React, { useState } from 'react';
import { generateAppDate, getInitials, onEmailChange } from 'utils/utils';
import { sendEmailReceipt } from 'service/api';
import { useDispatch } from 'react-redux';
import { showMessage } from 'modules/messages/actions';

import { Button } from 'material-ui';
import fileImg from '../../../assets/img/file.svg'
import close from '../../../assets/img/close.svg'
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import trash from '../../../assets/img/trash.svg'
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
import { emailRegex, RECEIPT } from 'service/constants';
import { failedMessage } from 'modules/messages/messages';

import "./receipt-email.scss"

const ReceiptEmailPopUp = ({popUpHandler, client, appointments, therapists, nameForPrint, billTo, date}) => {
    const {firstName, lastName, email: clientEmail} = client
    const [email, setEmail] = useState(clientEmail.split(",")[0])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    
    const closeHandler = (e) => {
        e.preventDefault()
        popUpHandler()
    }

    const refreshEmail = (e) => {
        e.preventDefault()
        setEmail("")
    }

    const emailHandler = async () => {
        let totalRate = 0
        let totalTax = 0
        let total = 0
        let initialreportId = undefined
        const exportAppointments = []

        appointments.forEach((appointment, i) => {
            const {paymentSource, type, GST, date, clientRate} = appointment

            if (paymentSource === "ICBC" && type.toLowerCase().includes('initial report')) {
                initialreportId = i
            } else if (!(i === (initialreportId + 1))) {
                const price = Number(clientRate) || 0
                const tax = GST
                const linePrice = price + tax
                
                const appointmentsForExport = {
                    description: type,
                    date: generateAppDate(date),
                    rate: price,
                    GST: +tax.toFixed(2),
                    total: +linePrice.toFixed(2)
                }

                exportAppointments.push(appointmentsForExport)
                
                total += linePrice
                totalTax += tax
                totalRate += clientRate
            }
        });

        const dataForExport = [
            {
                id: +appointments[0].id,
                bill_to: billTo,
                therapist_name: therapists,
                date: date,
                name_in_print: nameForPrint,
                first_name: firstName,
                last_name: lastName,
                appointments: [...exportAppointments],
                result_rates: +totalRate.toFixed(2),
                result_GST: +totalTax.toFixed(2),
                total_result: +total.toFixed(2),
                type: RECEIPT,
            }
        ]

        try {
            setLoading(true)
            await sendEmailReceipt(dataForExport, email)
            popUpHandler()
        } catch(e) {
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
        
    }

    const fileName = `Receipt ${getInitials(firstName, lastName, true)}.pdf`
    return (
        <div className='receipt-email-pop-up'>
            <form>
                <button 
                    className="icon-button close-button"
                    onClick={closeHandler}
                >
                    <img className="close-button" src={close} alt="close-button"/>
                </button>
                
                <div className='form-wrapper'>
                    <div className="input-wrapper">
                        <span className="input-head">Email</span>
                        <input 
                            value={email} 
                            onChange={e => onEmailChange(e.target.value, setEmail)}
                        />
                        <button 
                            className="icon-button delete-btn"
                            onClick={refreshEmail}
                        >
                            <img src={trash} alt=""/>
                        </button>
                    </div>
                    <div className="input-wrapper">
                        <span className="input-head">Title</span>
                        <input 
                            value={"No Fear Counselling Receipt"}
                            disabled
                        />
                    </div>
                    <div className="file-name">
                        <span className="file-head">FILE</span>
                        <div className="file">
                            <img src={fileImg} alt=""/>
                            <span>{fileName}</span>
                        </div>
                    </div>
                </div>
                <Button 
                    color="secondary" 
                    variant="raised" 
                    className="send-btn"
                    onClick={emailHandler}
                >
                    { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Send"}
                </Button>
            </form>
        </div>
    );
};

export default ReceiptEmailPopUp;