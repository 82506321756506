import { Button, MenuItem, TableRow, TextField } from 'material-ui'
import React from 'react'
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/fontawesome-free-solid';
import { useState } from 'react';
import { TableCell } from 'material-ui';
import { Select } from 'material-ui';
import { InputAdornment } from 'material-ui';
import {decimalRegex, DIRECT, DIRECT_WITH_TOP_UP, NO_CHANGE, NON_DIRECT, SET_RATE} from 'service/constants';
import { updatePaymentSource } from 'service/api';
import './rate-item.scss'
import { Switch } from 'material-ui';
import { FormControlLabel } from 'material-ui';
import { useDispatch } from 'react-redux';
import { fetchPaymentSources } from 'modules/admin/redux/actions';

const RateItem = ({paymentSource, value, valueForCouples}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showRateInput, toggleInput] = useState(paymentSource.noChangeFlag ? NO_CHANGE : SET_RATE)
  const [rate, setRate] = useState(value || "")
  const [rateForCouple, setRateForCouple] = useState(valueForCouples || "")
  const [profile, setProfile] = useState(paymentSource.profile)
  const [hasCouple, setHasCouple] = useState(paymentSource.hasCouple)

  const setRateSelectHandler = (event) => {
    toggleInput(event.target.value)
  }

  const profileSelectHandler = (event) => {
    const profile = event.target.value
    if (profile === NON_DIRECT) {
      setHasCouple(false)
      toggleInput(NO_CHANGE)
    }

    if (profile === DIRECT_WITH_TOP_UP) {
      toggleInput(SET_RATE)
      setHasCouple(false)
    }

    setProfile(profile)
  }

  const inputHandler = (event, setState) => {
    const value = event.target.value
    const currentSymbol = event.nativeEvent.data

    
    if(!isNaN(value)
        && +value < 1000
        && (currentSymbol === "." || currentSymbol === null || value.match(decimalRegex))
    ) {
      setState(value)
    }
  }

  const isSaveDisabled = () => {
    const flag = showRateInput !== SET_RATE
   
    return (!flag && !(+rate)) 
    || (hasCouple && !(+rateForCouple))
    || (
      (paymentSource.noChangeFlag === flag || paymentSource.noChangeFlag === true)
      && profile === paymentSource.profile
      && (flag || hasCouple === paymentSource.hasCouple)
      && (flag || +rate === +value) 
      && (flag || +rateForCouple === +valueForCouples)
      )
  }

  const saveHandler = async () => {
    setLoading(true)
    const noChangeFlag = showRateInput !== SET_RATE
    const updatedRate = (rate !== "" && !noChangeFlag) ? +rate : null
    const updatedRateForCouple = (rateForCouple !== "" && !noChangeFlag) ? +rateForCouple : null
    const updatedHasCouple = !noChangeFlag && hasCouple
    
    await updatePaymentSource(updatedRate, paymentSource.name, updatedRateForCouple, profile, paymentSource.isActive, updatedHasCouple, noChangeFlag)
    dispatch(fetchPaymentSources())
    setLoading(false)
  }

  const deactivateHandler = async () => {
    const updatedValue = value !== null ? +value : null
    const updatedValueForCouples = valueForCouples !== null ? +valueForCouples : null

    await updatePaymentSource(updatedValue, paymentSource.name, updatedValueForCouples, paymentSource.profile, !paymentSource.isActive, undefined, undefined)
    dispatch(fetchPaymentSources())
  }

  return (
    <TableRow
      className={`rate-row ${!paymentSource.isActive && "inactive"}`}
      key={paymentSource}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: "red" }}
    >
      <TableCell component="th" scope="row" className='cell source-cell'>
        {paymentSource.name}
      </TableCell>
      <TableCell className='cell profile-cell'>
      <Select
        className={`rate-select`}
        value={profile}
        onChange={profileSelectHandler}
      >
        <MenuItem value={NON_DIRECT}>Non direct</MenuItem>
        <MenuItem value={DIRECT}>Direct</MenuItem>
        <MenuItem value={DIRECT_WITH_TOP_UP}>Direct with top-up</MenuItem>
      </Select>
      </TableCell>
      <TableCell className='cell rate-cell'>
      <Select
        className={`rate-select ${(profile === NON_DIRECT || profile === DIRECT_WITH_TOP_UP) ? "non-direct" : ""}`}
        value={showRateInput}
        onChange={setRateSelectHandler}
        disabled={profile === NON_DIRECT || profile === DIRECT_WITH_TOP_UP}
      >
        <MenuItem value={NO_CHANGE}>No change</MenuItem>
        <MenuItem value={SET_RATE}>Set rate</MenuItem>
      </Select>
      {
        showRateInput === SET_RATE &&
          <TextField
            className='rate-input'
            label={hasCouple ? "Ind." : null}
            variant="standard"
            value={rate}
            onChange={(e) => inputHandler(e, setRate)}
            InputProps ={{
              startAdornment: (<InputAdornment position="start">$</InputAdornment>)
            }}
          />
      }

      {
        hasCouple && showRateInput === SET_RATE &&
          <TextField
            className='rate-input'
            label="Coup."
            variant="standard"
            value={rateForCouple}
            onChange={(e) => inputHandler(e, setRateForCouple)}
            InputProps ={{
              startAdornment: (<InputAdornment position="start">$</InputAdornment>)
            }}
          />
      }
      </TableCell>
      <TableCell align="right" className="cell action-cell">
        {

          profile === DIRECT && showRateInput === SET_RATE && 
            <FormControlLabel
              control={
                <Switch  
                  size="small" 
                  color="primary" 
                  checked={hasCouple}
                  onChange={() => setHasCouple(!hasCouple)}
                />
              }
              label="Coup."
            />
        }
        <Button  
            color="primary" 
            variant="raised" 
            className="save-button"
            onClick={saveHandler}
            disabled={isSaveDisabled()}
          >
            {
              loading 
                ? <FontAwesomeIcon icon={faSpinner} spin />
                : "Save"
                
            }
          </Button >
          <Button  
            color="default" 
            variant="raised" 
            className={`deactivate-button ${!paymentSource.isActive ? "activate" : ""}`}
            onClick={deactivateHandler}
          >
            {
              paymentSource.isActive ? "deactivate" : "activate"
                
            }
          </Button >
      </TableCell>
    </TableRow>
  )
}

export default RateItem