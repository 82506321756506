/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 23:36:31 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-18 18:37:44
 */

import { TextField } from "material-ui"
import PropTypes from "prop-types"
import React from "react"

const CounsellorAppointmentSearch = ({onSubmit, onChange, state}) => {
  return (
    <section>
      <h1>Select Counsellor</h1>
      <form className="flex" onSubmit={onSubmit}>
        <TextField
          className="flex-20 standard-margin"
          id="search"
          label={"Counsellor Name"}
          type="text"
          name="search"
          margin="normal"
          onChange={onChange}
          value={state.search}
        />
        <TextField
          className="flex-20 standard-margin"
          id="minDate"
          label={"Minimum Date"}
          type="date"
          name="minDate"
          margin="normal"
          onChange={onChange}
          value={state.minDate}
          InputProps={{inputProps: { max: state.maxDate}}}
        />
        <TextField
          className="flex-20 standard-margin"
          id="maxDate"
          label={"Maximum Date"}
          type="date"
          name="maxDate"
          margin="normal"
          onChange={onChange}
          value={state.maxDate}
          InputProps={{inputProps: { min: state.minDate}}}
        />
      </form>
    </section>
  )
}

CounsellorAppointmentSearch.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  state: PropTypes.object
}

export default CounsellorAppointmentSearch