export const UPDATE_INVOICE_NUMBER = `
    mutation updateInvoiceNumber($invoiceNumber: Int!) {
        invoiceNumber(invoiceNumber: $invoiceNumber) {
            invoiceNumber
        }
    }
`

export const UPDATE_TOTAL_BILLED = `
    mutation updateTotalBilled($mutateParams: ClientAdminsMutation!) {
        clientAdminsRole(mutateParams: $mutateParams) {
            totalBilled
        }
    }
`

export const UPDATE_CALENDAR = `
    mutation updateCalendar($mutateParams: CounsellorMutationInput!, $requestParams: Params!) {
        counsellor(mutateParams: $mutateParams, requestParams: $requestParams) {
            acuityID
            fee
            share
            external
            name
            GSTEnabled
            email
            id
        }
    }
`

export const UPDATE_CLIENT_INFO = `
    mutation updateClientInfo($mutateParams:  ClientAdminsMutation!) {
        clientAdminsRole(mutateParams: $mutateParams) {
            approvedSessions
            expiryDate
            email
            firstName
            id
            isActive
            lastName
            mutationRequest
            notes
            paymentSource
            phone
            totalBilled
            alertsEnabled
            dateOfApprovalCheck
        }
    }
`

export const UPDATE_EMAIL_TEXT = `
    mutation updateEmailText($text: String!) {
        emailText(text: $text) {
            text
        }
    }
`

export const UPDTATE_COUNSELLOR_PHONE = `
    mutation updateCounsellorPhone($mutateParams: CounsellorBaseMutationInput!) {
        counsellorBaseRole(mutateParams: $mutateParams, requestParams: {}) {
            phone
        }
    }
`

export const UPDATE_BAD_FIT_ITEM = `
    mutation updateBadFitItem($badFitId: Int!, $params:  BadFitMutateParams!) {
        badFit(badFitId: $badFitId, params: $params) {
            id
            isGrey
            isGoodFeet
            notes
            dateCreated
            firstName
            lastName
            counsellor
        }
    }
`

export const UPDATE_LIST_TYPE = `
    mutation updateTypeList($appointmentId: Int!, $listType: String!) {
        listType(appointmentId: $appointmentId, listType: $listType) {
            firstName
        }
    }
`

export const REQUEST_TO_ACTIVATE = `
    mutation requestToActivate($mutateParams: ClientCounsellorMutation!) {
        clientBaseRole(mutateParams: $mutateParams) {
            mutationRequest
        }
    }
`

export const DELETE_BAD_FIT = `
    mutation deleteBadFit($badFitId: Int!) {
        deleteBadFit(badFitId: $badFitId) {
            id
        }
    }
`

export const DELETE_TASK = `
    mutation deleteTask($taskId: Int!) {
        deleteTask(taskId: $taskId) {
            id
        }
    }
`

export const UPDATE_CLIENT_RATE = `
    mutation batchProductUpdates(
        $rateParams: ClientRateMutateParams!
        $sourceParams: PaymentSourceMutateParams!
    ) {
        paymentSource(params: $sourceParams) {
            id
            name
            profile
            isActive
            orderNumber
            hasCouple
            noChangeFlag
        }
        
        clientRate(params: $rateParams) {
            id
            value
            valueForCouples
        }
    
    }
`