import React, { useState } from 'react';
import { getTaskBody } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { deleteTaskAction } from 'modules/admin/redux/actions';
import { deleteTask } from 'service/api';
import { showMessage } from 'modules/messages/actions';

import { failedMessage } from 'modules/messages/messages';
import { Checkbox } from 'material-ui';

import './task-list-item.scss'

const TaskListItem = ({task}) => {
    const { id, isDirect } = task
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const checkboxHandler = async () => {
        try {
            setLoading(true)
            await deleteTask(id)
            dispatch(deleteTaskAction(id))
        } catch {
            dispatch(showMessage(failedMessage))
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={`task-list-item ${isDirect ? "direct" : ""}`} >
            {getTaskBody(task)}
            <Checkbox 
                color='default'
                className="task-checkbox"
                onClick={checkboxHandler}
                checked={false}
                disabled={loading}
            />
        </div>
    );
};

export default TaskListItem;