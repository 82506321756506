/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 18:54:51 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-05-02 11:16:07
 */

import PropTypes from "prop-types"

const generate = ({
  email = undefined,
  firstName = undefined,
  claimNumber = "",
  lastName = undefined,
  notes = undefined,
  phone = undefined,
  clientId = undefined,
  client = undefined
} = {}) => ({
  email,
  firstName,
  claimNumber,
  lastName,
  notes,
  phone,
  paymentSource: client.paymentSource,
  fullName: `${firstName} ${lastName}`,
  clientId
})

export const Shape = PropTypes.shape({
  email: PropTypes.string,
  firstName: PropTypes.string,
  icbcClaimNumber: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  notes: PropTypes.string,
  fullName: PropTypes.string,
})

export default generate