import layout from "../layout"
import { Paper, Table, TableBody, TableRow } from 'material-ui'
import Logo from "../../assets/img/logo.png"
import { useSelector } from 'react-redux'
import RateItem from "../rates/RateItem"
import { TableCell, TableContainer } from '@material-ui/core'
import { TableHead } from 'material-ui'
import './pages.css'

const AppoinmentRates = () => {
  const sources = useSelector(state => state.admin.sources)

  return (
    <layout.components.FullWidthRow>
        <layout.components.Container>
          <Paper elevation={4} className="container__rates">
            <img src={Logo} alt={"logo"} style={{maxWidth: '200px'}}/>
            <h1>Appointment rates</h1>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className='cell source-cell'>Payment source</TableCell>
                    <TableCell  className='cell profile-cell'>Profile</TableCell>
                    <TableCell  className='cell rate-cell'>Appointment rate</TableCell>
                    <TableCell className="cell action-cell"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sources?.map((source) => (
                    <RateItem
                      key={source.id}
                      paymentSource={source.paymentSource}
                      noChangeFlag={source.noChangeFlag}
                      value={source.value}
                      valueForCouples={source.valueForCouples}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </layout.components.Container>
      </layout.components.FullWidthRow>
  )
}

export default AppoinmentRates