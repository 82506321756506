/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:48 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-09 17:20:40
 */

import { moduleName } from "./constants"

export const login = `${moduleName}.login`
export const logout = `${moduleName}.USER_LOGGED_OUT`
export const setUser = `${moduleName}.setUser`
export const getUserProfile = `${moduleName}.getUserProfile`
export const getUserGroups = `${moduleName}.getUserGroups`
export const verifyToken = `${moduleName}.verifyToken`
export const showPopUp = `${moduleName}.showPopUp`
export const showChangePhonePopUp = `${moduleName}.showChangePhonePopUp`
export const showRegistrationPopUp = `${moduleName}.showRegistrationPopUp`
export const showForgorpasswordPopUp = `${moduleName}.showForgorpasswordPopUp`
