/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:30:23 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-14 21:25:40
 */

import {GET_CALENDARS_LIST} from '../../service/query'
import { $authHost } from "service/indexApi"

export default class Service{
  search(query) {
    const { maxDate, minDate} = query
    const variables = {
      params: {
        maxDate,
        minDate,
        canceled: false
      }
    }
    return $authHost.post('/graphql', {
      query: GET_CALENDARS_LIST,
      variables
    }).then(({data:{ data: { counsellors }}}) => counsellors)
  }
}