import { FETCH_COUNSELLOR, FETCH_OUTSTANDING } from "./actionsTypes"

export const reducer = (state = {}, action) => {
    switch(action.type) {
      case FETCH_COUNSELLOR: {
        const {dashboard, clients, future, outstanding} = action.counsellor
       
        return {...state, dashboard, clients, outstandingItems: outstanding, futurePaidItems: future}
      }

    default:
      return state
    }
}