/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:41:59 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-15 19:27:48
 */

import PropTypes from "prop-types"

export default ({
  calendar = undefined,
  counsellor = undefined,
  counsellorRate = undefined,
  canceled = undefined,
  clientRate = undefined,
  date = undefined,
  email = undefined,
  firstName = undefined,
  icbcClaimNumber = undefined,
  acuityID = undefined,
  id = undefined,
  lastName = undefined,
  phone = undefined,
  price = undefined,
  selected = false,
  paymentSource = undefined,
  totalBilled = undefined,
  time = undefined,
  type = undefined,
  paid = undefined,
  sessionLocation = undefined,
  GST = undefined,
  amountPaid = undefined,
  client= undefined
} = {}) => ({
  calendar,
  counsellor,
  counsellorRate,
  canceled,
  date,
  email,
  firstName,
  icbcClaimNumber,
  acuityID,
  id,
  lastName,
  phone,
  price,
  selected,
  paymentSource: client.paymentSource,
  totalBilled,
  time,
  type,
  paid,
  sessionLocation,
  clientRate,
  GST,
  amountPaid,
  claimNumber: client.claimNumber,
  client,
  clientId: client.id
})

export const Shape = PropTypes.shape({
  calendar: PropTypes.string,
  counsellor: PropTypes.string,
  date: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  icbcClaimNumber: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  selected: PropTypes.bool,
  paymentSource: PropTypes.object,
  time: PropTypes.string,
  type: PropTypes.string,
  paid: PropTypes.string
})