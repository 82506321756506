import { alertTypes, ICBC, NON_DIRECT } from "service/constants";
import { setDirectAlerts } from "service/selectors";

export const counsellorClients = (clients) => {
    const dashboard = {
        approval: [],
        notPaid: [],
        partPaid: []
    }

    const updatedClients = {
        direct : {
            activeICBC: [],
            deactivatedICBC: [],
            activeOther: [],
            deactivatedOther: [],
        },
        nonDirect: {
            active: [],
            deactivated: []
        }
    }

    const approvalPush = (client) => dashboard.approval.push(client)

    clients.forEach(client => {
        const {paymentSource, isActive, usedSessions, chargedSessions, alertsEnabled} = client
        
        if (paymentSource.profile !== NON_DIRECT) {
            setDirectAlerts(client, approvalPush)

            if (paymentSource.name.toLowerCase() === ICBC) {
                if (isActive) {
                    updatedClients.direct.activeICBC.push({...client})
                } else {
                    updatedClients.direct.deactivatedICBC.push({...client})
                }
            } else {
                if (isActive) {
                    updatedClients.direct.activeOther.push({...client})
                } else {
                    updatedClients.direct.deactivatedOther.push({...client})
                }
            } 
        } else {            
            if (client.partiallyPaidIDs?.length) {
                dashboard.partPaid.push(client)
            }

            if (usedSessions > chargedSessions) {
                client.priority = alertsEnabled ? 1 : 3
                client.alert = alertTypes.red
                
                dashboard.notPaid.push(client) 
            }

            if (!client.priority) {
                client.priority = 2
            }

            if (isActive) {
                updatedClients.nonDirect.active.push({...client})
            } else {
                updatedClients.nonDirect.deactivated.push({...client})
            }
        }
    });
    
    return { dashboard, clients: updatedClients}
}