/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:12:29 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2019-01-15 20:03:27
 */

import * as _ from 'lodash'
import * as actions from '../actions'

import ListItem from './ListItem'
import PropTypes from "prop-types"
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import core from "../../core"
import layout from "../../layout"
import model from '../../../model'
import { moduleName } from '../constants'

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  onChange(appointment, checked, e) {
    const { toggleSelection } = this.props
    toggleSelection(appointment.id)
  }

  render() {
    const { appointments } = this.props
    
    const listItems = appointments.map((appointment, i) => (
      <ListItem 
        data={appointment} 
        key={`${moduleName}-${appointment.id}`} 
        onChange={this.onChange.bind(this, appointment)} 
      />
    ))

    return(
      <layout.components.FullWidthRow>
        <layout.components.Container>
          {listItems.length ? listItems : <core.components.NoItems />}
        </layout.components.Container>
      </layout.components.FullWidthRow>
    );
  }
}

List.propTypes = {
  appointments: PropTypes.arrayOf(model.AppointmentShape)
}

const stateToProps = (state) => ({
})

const dispatchToProps = (dispatch) => (
    bindActionCreators({
    ...actions,
  }, dispatch)
)

export default connect(stateToProps, dispatchToProps)(List)