/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:41:59 
 * @Last Modified by:   Mike Cottingham 
 * @Last Modified time: 2018-04-24 21:41:59 
 */

import PropTypes from "prop-types"

export default ({
  username = undefined,
  jwt = undefined
} = {}) => ({
  username,
  jwt
})

export const Shape = PropTypes.shape({
  username: PropTypes.string,
  jwt: PropTypes.string
})