/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 16:14:45 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-18 16:55:50
 */


import { Paper } from "material-ui"
import PropTypes from "prop-types"
import React from "react"

import core from "../../core"
import model from "../../../model"

const ListItem = ({client, onClick}) => (
  <Paper elevation={4}>
    <article className="client__list-item" onClick={onClick}>
      <h1 className="avatar" style={{backgroundColor: core.utils.memoizeClientColor({luminosity: "dark"}, client)}}>{client.firstName[0]}{client.lastName[0]}</h1>
      <h1 className="flex-25 text-left">{client.firstName} {client.lastName}</h1>
    </article>
  </Paper>
)

ListItem.propTypes = {
  client: model.ClientShape,
  onChange: PropTypes.func
}

export default ListItem