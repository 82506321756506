import React from 'react';
import './ui-components.scss'

const PaymentStatus = ({paidCount = null, isFullPaid}) => {
    return (
        <div className={`payment-status ${isFullPaid && "full-paid"}`}>
            <span>
                <span style={{fontSize: 10, fontFamily: "monospace"}}>{paidCount}</span>
                {isFullPaid ? " Paid" : " Partial"}
            </span>
        </div>
    );
};

export default PaymentStatus;