/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-25 11:41:26 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-22 12:23:21
 */

export default ({
  firstName = undefined,
  lastName = undefined,
} = {}) => ({
  firstName,
  lastName,
})