import React from 'react';

const AlertButton = ({onClick, disabled }) => {
    return (
        <button 
            onClick={onClick}
            disabled={disabled}
            className="show-more-alert"
        >
            Show more
        </button>
    );
};

export default AlertButton;