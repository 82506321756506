
import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { login } from '../../../actions';
import { updateCounsellorPhone } from '../../../../../service/api';
import { isEmpty } from 'utils/utils';
import { useHistory } from 'react-router-dom';

import FormInput from 'ui-components/FormInput';
import { Button } from 'material-ui';
import { phoneRegex, emailRegex, PHONE_INPUT_LABEL, ADMIN_ROUTE, COUNSELLOR_PAGE_ROURE } from 'service/constants';
import { invalidPasswordMessage, invalidPhoneMessage, invalidEmailMessage,  } from 'modules/messages/messages';
import FontAwesomeIcon from "@fortawesome/react-fontawesome"
import { faSpinner } from '@fortawesome/fontawesome-free-solid'
 
import './register-form.scss'

const RegisterForm = ({closeHandler, email}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const loginRouterHandler = (isAdmin) => {
        if (isAdmin) {
            history.push(ADMIN_ROUTE)
        } else {
            history.push(COUNSELLOR_PAGE_ROURE)
        }
    }

    const {
        register,
        formState: { errors },
        handleSubmit,
        watch
    } = useForm({
        mode: "onSubmit",
        reValidateMode: 'onChange',
        defaultValues: {
            username: email,
            phone: "+1"
        }
    });
    
    const onSubmit = async (data) => {
        const {username, password, phone} = data 

        try {
            setLoading(true)
            await dispatch(login({username, password}, true, loginRouterHandler, phone))
            // await updateCounsellorPhone(phone)
            closeHandler()
        } catch(error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const {password, phone, username} = watch()
    const isBtnDisabled = !password || !phone || !username || loading || !isEmpty(errors)
    return (
        <form 
            className="register-form"
            onSubmit={handleSubmit(onSubmit)}
        >
           <FormInput
                title={"Email"}
                register={register}
                errors={errors}
                name={"username"}
                pattern={emailRegex}
                invalidMessage={invalidEmailMessage}
                defaultValue={email}

            />
           <FormInput
                title={"Password"}
                register={register}
                errors={errors}
                name={"password"}
                pattern={null}
                invalidMessage={invalidPasswordMessage}
                isPassword={true}
            />
            <FormInput
                title={"Phone"}
                register={register}
                errors={errors}
                name={"phone"}
                pattern={phoneRegex}
                invalidMessage={invalidPhoneMessage}
                isPhone={true}
                label={PHONE_INPUT_LABEL}
            />
            <Button
                color="secondary" 
                variant="raised" 
                className="submit-button"
                type="submit"
                disabled={isBtnDisabled}
            >
                { loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
            </Button>
        </form>
    );
};

export default RegisterForm;
