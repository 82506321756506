/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:28:16 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-07 15:45:47
 */
import axios from "axios"

import * as constants from "./constants"

const compileAuthorizationHeaders = (token) => {
  const headers = {}

  if (token) {
    headers["authorization"] = `Bearer ${token}`
  }

  return headers
}

class Service {
  constructor(host, timeout) {
    this._instance = axios.create({
      baseURL: host || constants.apiHost,
      timeout: timeout || constants.timeout
    })
  }

  get(endpoint, query, token, cancelToken) {
    return this._instance.get(endpoint, {
      cancelToken,
      params: query,
      headers: compileAuthorizationHeaders(token)
    })
  }

  isCancelled(result) {
    return axios.isCancel(result)
  }

  post(endpoint, data, token) {
    return this._instance.post(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  put(endpoint, data, token) {
    return this._instance.put(endpoint, data, {
      headers: compileAuthorizationHeaders(token)
    })
  }

  delete(endpoint, token) {
    return this._instance.delete(endpoint, {
      headers: compileAuthorizationHeaders(token)
    })
  }
}

export const generateCancelToken = axios.CancelToken.source

export default Service