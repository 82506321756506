import React from 'react';
import { IconButton } from 'material-ui';
import upImg from 'assets/img/up.svg'
import './ui-components.scss'

const UpButton = ({onClick}) => {
    return (
        <IconButton 
            className='up-button'
            onClick={onClick}
        >
            <img src={upImg} alt="up"/>
        </IconButton>
    );
};

export default UpButton;