/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:41:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-05-16 15:17:57
 */

import model from "../../model"
import * as actionTypes from "./actionTypes"

export const reducer = (state = model.store(), action) => {
  switch(action.type) {

  case actionTypes.select:
    return action.client

  case actionTypes.search:
    return action.clients

  case actionTypes.reset:
    return model.store()

  case actionTypes.setIcbcClaimNumber:
    return model.Client({...state, сlaimNumber: action.claimNumber})
    
  default:
    return state
  }
}