/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:41:41 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 13:43:31
 */

import * as actionTypes from "./actionTypes"

export const reducer = (state = null, action) => {
  switch(action.type) {
  case actionTypes.showMessage:
    return action.data
  case actionTypes.hideMessage:
    return null
  default:
    return state
  }
}