/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 22:11:56 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-24 21:46:44
 */

import { generate } from "./model"

export const reducer = (state = generate(), action) => {
  switch(action.type) {
  default:
    return state
  }
}