import React from 'react';
import invalidFieldImg from 'assets/img/emptyField.svg'
import './ui-components.scss'

const InvalidMessage = ({isSmallMessage, message}) => {
    return (
        <>
            <img src={invalidFieldImg} alt="invalid"/>
            <span className={`invalid-message ${isSmallMessage ? "small" : ""}`}>
                {message}
            </span>
        </>
    );
};

export default InvalidMessage;