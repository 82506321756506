/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 20:26:17 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 13:57:28
 */

import * as actionTypes from "./actionTypes"

export const hideMessage = () => ({
  type: actionTypes.hideMessage
})

export const showMessage = ({message, title, type}) => ({
  type: actionTypes.showMessage,
  data: {
    message, title, type
  }
})
