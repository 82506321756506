import React from "react"

const CounsellorSectionHeader = () => {
  return (
    <section className="invoice-line-item-container">
      <article className="invoice-line-item header">
        <span className="flex-20">Client</span>
        <span className="flex-7">Sessions</span>
        <span className="flex-20">Rate</span>
        <span className="flex-20">Counsellor rate</span>
        <span className="flex-7">Total</span>
        <span className="flex-20">Category</span>
      </article>
    </section>
  )
}

export default CounsellorSectionHeader