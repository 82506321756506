import React from 'react';
import { Button } from 'material-ui';
import './ui-components.scss'

const ShowMoreButton = ({onClick, disabled}) => {
    return (
        <Button 
            color="secondary" 
            variant="raised" 
            className="show-more-button"
            onClick={onClick}
            disabled={disabled}
        >
            Show  more
        </Button>
    );
};

export default ShowMoreButton;