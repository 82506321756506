import React, {useEffect, useState} from 'react';
import { updateBadItem } from 'modules/admin/redux/actions';
import { useDispatch } from 'react-redux';
import { showMessage } from 'modules/messages/actions';

import TableButtons from '../table-buttons/TableButtons';
import { Checkbox, TextField } from 'material-ui';
import { updateBadFitItem } from 'service/api';
import { failedMessage } from 'modules/messages/messages';

import './bad-fit-item.scss'

const BadFitItem = ({isGrey, clientName, counsellorName, notes, id, groupId}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [changedNotes, setNotes] = useState(notes || "")
    
    const requestHandler = async (setState, params) => {
        try {
            setState(true) 
            const badFitItem = await updateBadFitItem(id, params)
            dispatch(updateBadItem(badFitItem, groupId))
        } catch {
            dispatch(showMessage(failedMessage))
        } finally {
            setState(false)
        }
    }

    const checkboxHandler = async () => await requestHandler(setLoading, {isGrey: !isGrey})

    const saveHandler = async (setLoading) => await requestHandler(setLoading, {notes: changedNotes.trim()})

    const onChange = (e) => setNotes(e.target.value)

    const saveDisabled = notes === changedNotes || changedNotes === "" || changedNotes === null
    return (
        <section className="invoice-line-item-container">
            <article className={`invoice-line-item bad-fit-container ${isGrey ? "grey" : ""}`}>
                <Checkbox 
                    color="default"
                    className="bad-fit-checkbox"
                    checked={isGrey}
                    disabled={loading}
                    onClick={checkboxHandler}
                />
                <span className="flex-20" style={{flex: "10% 0.5"}}>{clientName}</span>
                <span className="flex-20" style={{flex: "10% 0.5"}}>{counsellorName}</span>
                <span className="flex-20" style={{flex: "12% 2"}}>
                    <TextField
                        className='notes-input'
                        onChange={onChange}
                        value={changedNotes}
                    />
                    <TableButtons
                        badFitId={id}
                        groupId={groupId}
                        saveHandler={saveHandler}
                        saveDisabled={saveDisabled}
                    />
                </span>
            </article>
        </section>
    );
};

export default BadFitItem;