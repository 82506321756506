/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-23 23:01:10 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2020-04-15 12:47:56
 */


import React from "react"
import { Switch, Route } from "react-router-dom"
import ClientInvoice from "./ClientInvoice"
import CounsellorInvoice from "./CounsellorInvoice"
import {useSelector } from 'react-redux'
import CounsellorHomePage from "./CounsellorHomePage"
import AdminHomepage from "./AdminHomepage"
import { ADMIN_ROUTE, COUNSELLOR_PAGE_ROURE, COUNSELLOR_PAY_ROUTE, INVOICING_MANAGMENT_ROURE, RATES } from "service/constants"
import AppoinmentRates from "./AppointmentRates"

const Routes = () => {
  const isAdmin = useSelector(state => state.auth.profile.isAdmin)
  
  return (
    <Switch>
      {
        isAdmin &&
        <Route path={COUNSELLOR_PAY_ROUTE} component={CounsellorInvoice} isExact />
      }
      {
        isAdmin &&
        <Route path={ADMIN_ROUTE} component={AdminHomepage} isExact />
      }
      {
        isAdmin &&
        <Route path={RATES} component={AppoinmentRates} isExact />
      }
      {
        !isAdmin &&
        <Route path={COUNSELLOR_PAGE_ROURE} component={CounsellorHomePage} isExact />
      }
      <Route path={INVOICING_MANAGMENT_ROURE} component={ClientInvoice} isExact />
    </Switch>
  )
}

export default Routes