import React from 'react';
import TaskList from '../task-list/TaskList';
import smileImg from 'assets/img/smile.svg'
import Spinner from 'ui-components/Spinner';

import './task-group.scss'

const TaskGroup = ({type, items, loading}) => {
    return (
        <div className="task-group wide-group">
            <div className='task-group-wrapper'>
                <h4>{type}</h4>
                {
                    !loading 
                        ?   <>
                                {  items.length ?
                                    <>
                                        <div className='group-wrapper'>
                                            <TaskList 
                                                tasks={items}
                                            />
                                        </div>
                                    </>
                                    :
                                    <img className="empty-smile" src={smileImg}/>
                                }
                            </>
                        :   <Spinner/> 
                }
                
            </div>
        </div>
    );
};

export default TaskGroup;