/*
 * @Author: Mike Cottingham 
 * @Date: 2018-04-24 21:39:43 
 * @Last Modified by: Mike Cottingham
 * @Last Modified time: 2018-04-25 12:04:22
 */

import {moduleName} from "./constants"

export const getAuth = (state) => (state[moduleName]).cognito
export const getUserProfile = (state) => (state[moduleName]).profile
export const isUserInGroup = (state, group) => (state[moduleName]).groups?.indexOf(group) > -1
export const isAdmin = (state) => isUserInGroup(state, "Admin")
export const getAuthToken = (state) => null