import React from 'react';
import { legendTypes } from 'service/constants';
import submitedImg from 'assets/img/circle-subm.svg'
import noApprovalImg from 'assets/img/red-circle.svg'
import noPaymentImg from 'assets/img/red.svg'
import partialImg from 'assets/img/orange.svg'
import requestImg from 'assets/img/blue-rectangle.svg'

const LegendItem = ({type}) => {
    switch (type) {
        case legendTypes.submited: {
            return <img src={submitedImg} alt="submited"/>
        }
        
        case legendTypes.noApproval: {
            return <img src={noApprovalImg} alt="no-approval"/>
        }

        case legendTypes.noPayment: {
            return <img src={noPaymentImg} alt="no-payment"/>
        }

        case legendTypes.partialCharge: {
            return <img src={partialImg} alt="partial-charge"/>
        }

        case legendTypes.request: {
            return <img src={requestImg} alt="request"/>
        }
        
        default: 
            return <></>
    }
};

export default LegendItem;