import {ADD_FUTURE_PAID_ITEM, REMOVE_FUTURE_PAID_ITEM, ADD_FUTURE_PAID_ITEMS, FETCH_FUTURE_PAID_ITEMS} from "./actionTypes"

export const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_FUTURE_PAID_ITEM: {
      return [...state, action.item]
    }
        
    case REMOVE_FUTURE_PAID_ITEM: {
      return state.filter(item => item.id !== action.id)
    } 
      
    case FETCH_FUTURE_PAID_ITEMS: {
      return [...action.items]
    }

    case ADD_FUTURE_PAID_ITEMS: {
      return [...state ,...action.items]
    }

    default:
      return state
  }
}