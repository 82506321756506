import {ADD_OUTSTANDING_ITEM, REMOVE_OUTSTANDING_ITEM, ADD_OUTSTANDING_ITEMS} from "./actionTypes"

export const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_OUTSTANDING_ITEM: {
      return [...state, action.item]
    }
        
    case REMOVE_OUTSTANDING_ITEM: {
      return state.filter(item => item.id !== action.id)
    } 
      
    case ADD_OUTSTANDING_ITEMS: {
      return [...action.items]
    }

    default:
      return state
  }
}