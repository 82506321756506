import React from 'react';
import { generateAppDate } from 'utils/utils';
import TaskListItem from '../task-list-item/TaskListItem'

import './task-list.scss'

const TaskList = ({tasks}) => {
    let currDate

    return (
        <div className='task-list'>
            {
                tasks.map((task, i) => {
                    const {dateOfCreationTask} = task
                    const taskDate = generateAppDate(dateOfCreationTask)
                    const isCurrDate = currDate === taskDate

                    if (!isCurrDate) {
                        currDate =  taskDate
                    }
                    return (
                        <React.Fragment key={task.id}>
                        {
                            !isCurrDate && 
                                <h3 className='task-date' key={i}>{taskDate}</h3>
                        }
                            
                            <TaskListItem 
                                key={task.id}
                                task={task}
                            />
                        </React.Fragment>
                        
                    )
                })
            }
        </div>
    );
};

export default TaskList;